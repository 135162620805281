var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm._l(_vm.numberStars || 0, function (n) {
        return _c("star-icon", {
          key: n,
          staticClass: "text-ratings",
          attrs: { title: "" },
        })
      }),
      _vm._l([1, 2, 3, 4, 5].slice(_vm.numberStars || 0), function (n) {
        return _c("star-outline-icon", {
          key: `empty-star-${n}`,
          staticClass: "text-ratings",
          attrs: { title: "" },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }