/**
 * The UrlGenerator Mixin handles all formatting of internal and external links.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Constructs Walmart URL's
     *
     * @param {Object} error Error object
     */
    constructWalmartUrl(seller) {
      return `https://www.walmart.com/reviews/seller/${seller}`;
    },
    /**
     * Constructs Amazon URL's
     *
     * @param {Object} error Error object
     */
    constructAmazonUrl(seller, sellerId, source) {
      switch (seller) {
        case 'amazon':
        case 'Amazon.com':
          return 'http://www.amazon.com';
        case 'amazon.uk':
        case 'Amazon.uk':
          return 'http://www.amazon.co.uk';
        case 'amazon.de':
        case 'Amazon.de':
          return 'http://www.amazon.de';
        case 'amazon.ca':
        case 'Amazon.ca':
          return 'http://www.amazon.ca';
        case 'amazon.fr':
        case 'Amazon.fr':
          return 'http://www.amazon.fr';
        case 'amazon.es':
        case 'Amazon.es':
          return 'http://www.amazon.es';
        case 'amazon.it':
        case 'Amazon.it':
          return 'http://www.amazon.it';
        case 'amazon.mx':
        case 'Amazon.mx':
          return 'http://www.amazon.mx';
        default:
          // console.log('unknown seller', seller, sellerId, source);
          break;
      }

      switch (source) {
        case 'amazon':
        case 'Amazon.com':
          return `http://www.amazon.com/sp?seller=${sellerId}`;
        case 'amazon.uk':
        case 'Amazon.uk':
          return `http://www.amazon.co.uk/sp?seller=${sellerId}`;
        case 'amazon.de':
        case 'Amazon.de':
          return `http://www.amazon.de/sp?seller=${sellerId}`;
        case 'amazon.ca':
        case 'Amazon.ca':
          return `http://www.amazon.ca/sp?seller=${sellerId}`;
        case 'amazon.fr':
        case 'Amazon.fr':
          return `http://www.amazon.fr/sp?seller=${sellerId}`;
        case 'amazon.es':
        case 'Amazon.es':
          return `http://www.amazon.es/sp?seller=${sellerId}`;
        case 'amazon.it':
        case 'Amazon.it':
          return `http://www.amazon.it/sp?seller=${sellerId}`;
        case 'amazon.mx':
        case 'Amazon.mx':
          return `http://www.amazon.mx/sp?seller=${sellerId}`;
        default:
          console.log('unknown merchant', seller, sellerId, source);
          break;
      }

      return null;
    },
  },
};
