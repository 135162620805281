import BaseHttp from './BaseHttp';
import cloneDeep from 'lodash/cloneDeep';

const originalColumns = [
  {
    headerName: 'Catalog',
    groupId: 'general',
    children: [
      {
        headerName: 'Show',
        field: 'checkbox',
      },
      {
        headerName: 'Source',
        field: 'retailer_source',
      },
      {
        headerName: 'ID',
        field: 'retailer_id',
      },
      {
        headerName: 'Parent',
        field: 'parent',
      },
      {
        headerName: 'Name',
        field: 'name',
      },
    ],
  },
  {
    headerName: 'Reviews',
    groupId: 'reviews',
    children: [
      {
        headerName: 'Written Date',
        field: 'ts',
      },
      {
        headerName: 'Captured Date',
        field: 'cts',
      },
      {
        headerName: 'Published Date',
        field: 'lts',
      },
      {
        headerName: 'Author',
        field: 'author',
      },
      {
        headerName: 'Stars',
        field: 'stars',
      },
      {
        headerName: 'Title',
        field: 'title',
      },
      {
        headerName: 'Review',
        field: 'text',
      },
      {
        headerName: 'Image',
        field: 'image',
      },
      {
        headerName: 'Video',
        field: 'video',
      },
      {
        headerName: 'Verified',
        field: 'verified',
      },
      {
        headerName: 'Review ID',
        field: 'id',
      },
      {
        headerName: 'Vine',
        field: 'vine',
      },
    ],
  },
  {
    headerName: 'Response',
    groupId: 'response',
    children: [
      {
        headerName: 'Reply',
        field: 'reply',
      },
      {
        headerName: 'Status',
        field: 'status',
      },
      {
        headerName: 'Sentiment',
        field: 'sentiment',
      },
      {
        headerName: 'Topic',
        field: 'topic',
      },
      {
        headerName: 'Reason Codes',
        field: 'topic_names'
      },
      {
        headerName: 'Custom',
        field: 'customCol',
      },
      {
        headerName: 'Notes',
        field: 'comment',
      },
      {
        headerName: 'Updates',
        field: 'updatetypes',
      },
      {
        headerName: 'Last Update',
        field: 'latestupdatets',
      },
    ],
  },
];

/**
 * Class to handle all Reviews related http requests.
 */
class ReviewsHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'Reviews';

    // Default reviews columns
    this.defaultColumns = [];

    // Columns that should be formatted as numbers
    this.numericColumns = ['stars'];
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   *
   * @todo
   * - Do we need to call the filters, preferences, and custom fields on every call? We could just
   *   these custom settings on the first call.
   */
  async getGridData(params = {}) {
    const [
      reviewsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getReviews(params.reviews || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    /**
     * Check if a user has bazaarvoice, if so add the appropriate columns
     * @todo Can this be done on the backend so we don't have to manipulate things on the frontend?
     */
    if (params && params.hasBazaarvoiceIntegration) {
      this.addBazaarvoiceToDefaultColumns();
    } else {
      this.defaultColumns = cloneDeep(originalColumns);
    }

    return this.constructGrid(
      reviewsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  async getReviews(params = {}) {
    // Fetch Reviews
    return this.get(`${this.PrependRoutes.reviewbox}/reviews`, params, true)
      .then((response) => {
        const { data } = response;

        // Throw an error if no reviews are returned
        if (
          !Object.prototype.hasOwnProperty.call(data, 'reviews')
          || data.reviews === null
        ) {
          throw new Error('Could not fetch reviews');
        }

        // Throw an error if a warning is returned
        if (Object.prototype.hasOwnProperty.call(data, 'warning')) {
          throw new Error(data.warning);
        }

        /**
         * @todo Fix this hack, please. We have to insert field values AND product name
         * for reviews if we are fetching on a single listing. That is
         * because when we fetch for a listing's reviews, we do not
         * return custom field values as part of the data.reviews array.
         * The custom field values are returned as a separate field "data.fields".
         * This was because it's one listing, so all fields will just get applied
         * to this...however, doesn't make sense to change the structure of returned
         * JSON when passing queries. The structure should stay the same, the data
         * should just reflect the new queries. Otherwise we have to make workarounds
         * like this on the front-end.
         */
        const hasProductName = Object.prototype.hasOwnProperty.call(data, 'name');

        if (Object.prototype.hasOwnProperty.call(data, 'fields')) {
          data.reviews.forEach((review) => {
            Object.assign(review, data.fields);

            // Add product name
            if (hasProductName) {
              review.name = data.name;
            }
          });
        }

        // Return reviews
        return data.reviews;
      });
  }

  /**
   * Async function to fetch reviewbox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Reviews' });
  }

  /**
   * Async function to fetch reviewbox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Function to create ag-grid row data.
   *
   * @param {Array} reviewsData Array of product reviews data
   * @param {Array} columnDefs Array of column data
   */
  static constructRowData(reviewsData, columnDefs) {
    const rowData = [];

    // If no data, just return empty array
    if (!reviewsData || !reviewsData.length) {
      return rowData;
    }
    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    reviewsData.forEach((review) => {
      const row = {};

      columnTypes.forEach((column) => {
        if (Object.prototype.hasOwnProperty.call(review, column)) {
          if (['video', 'image', 'verified', 'vine'].includes(column)) {
            row[column] = review[column] ? 'Yes' : 'No';
          } else if (['ts', 'lts', 'cts', 'latestupdatets'].includes(column)) {
            row[column] = review[column] === null ? null : new Date(review[column] * 1000);
          } else if (column === 'name') {
            row[column] = review.name;
          } else {
            row[column] = review[column];
          }
        } else if (column === 'name') {
          row[column] = review[column];
          // Information is a custom field
        } else if (
          Object.prototype.hasOwnProperty.call(review, 'fields')
          && review.fields !== null
          && Object.prototype.hasOwnProperty.call(review.fields, column)
        ) {
          row[column] = review.fields[column];
        } else {
          row[column] = '';
        }

        if (row[column] === null) {
          row[column] = '';
        }
      });

      /**
       * @todo Why do we randomly reassign custom_field to customCol?
       * Prob want to fix this later
       */
      row.customCol = review.custom_field;

      // Make sure to add in the listing & source even though we don't
      // directly display this information in the grid.
      row.listing = review.listing;
      row.source = review.source;

      if (review.source === 'bazaarvoice') {
        // This is a Bazaarvoice review. So instead of showing the
        // normal Bazaarvoice info, we should display the retailer
        // specific information.
        row.retailer_id = Object.prototype.hasOwnProperty.call(review, 'product_id')
          ? review.product_id
          : null;

        if (row.retailer_id && row.retailer_id.includes(review.product_retailer)) {
          const [splitId] = row.retailer_id.split(`-${review.product_retailer}`);
          row.retailer_id = splitId;
        }

        row.retailer_source = review.product_retailer;
        row.retailer_url = review.product_url;
      } else {
        row.retailer_id = review.listing;
        row.retailer_source = review.source;
        row.retailer_url = null;
      }

      row.reply = 'Leave a Reply';
      row.url = review.url;
      row.id = review.id;
      row.captured_ts = review.lts;
      row.written_ts = review.ts;
      row.rurl = '/viewreview?listing=';
      row.rurl += encodeURIComponent(review.listing);
      row.rurl += '&source=';
      row.rurl += encodeURIComponent(review.source);
      row.rurl += '&review=';
      row.rurl += encodeURIComponent(review.id);
      row.rurl += '&url=';
      row.rurl += encodeURIComponent(review.product_url);

      // Add row data
      rowData.push(row);
    });

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }))
      .sort((a, b) => a.agGridRowNumber - b.agGridRowNumber);
  }

  /**
   * Fetches a single review
   *
   * @param {Object} params HTTP request params object
   */
  async getReview(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/review`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches NLP info for some reviews.
   *
   * @param {Object} params HTTP request params object
   */
  async getNLP(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/nlp`, params)
      .then((response) => response.data);
  }

  /**
   * Post to activate a single review's response tracking status.
   *
   * @param {Object} params HTTP request params object
   */
  async updateReviewResponseTracking(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/responsetracking`, params)
      .then((response) => response.data);
  }

  /**
   * Post to initiate a review action (vote, etc.).
   *
   * @param {Object} params HTTP request params object
   */
  async postAction(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/socialaction`, params)
      .then((response) => response.data);
  }

  /**
   * Patch method to update a review's or questions metadata.
   *
   * @param {Object} params HTTP request params object
   */
  async updateMetadata(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/review`, params)
      .then((response) => response.data);
  }

  /**
   * Patch method to update a topic custom values.
   *
   * @param {Object} params HTTP request params object
   */
  async patchCustomTopics(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customtopics`, params)
      .then((response) => response.data);
  }

  /**
   * Post method to update a customCol values.
   *
   * @param {Object} params HTTP request params object
   */
  async postCustomColFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customcolfields`, params)
      .then((response) => response.data);
  }

  /**
   * Get method to fetch response integrations for reviews.
   */
  async getResponseIntegrations(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/responseintegrations`, params)
      .then((response) => {
        const { responses } = response.data;
        const responseIntegrations = {};

        responses.forEach((responseItem) => {
          if (!Object.prototype.hasOwnProperty.call(responseIntegrations, responseItem.type)) {
            responseIntegrations[responseItem.type] = [];
          }

          responseIntegrations[responseItem.type].push(responseItem);
        });

        return responseIntegrations;
      });
  }

  /**
   * Post method for responding to listing reviews on bazaarvoice or amazon.
   *
   * @param {Object} params Post HTTP params for review response
   */
  async postReviewResponse(params = {}) {
    return this.post(`${this.PrependRoutes.reviewbox}/response`, params)
      .then((response) => response.data);
  }

  addBazaarvoiceToDefaultColumns() {
    const defaultColumns = cloneDeep(originalColumns);

    // Only show this if users have BV data
    defaultColumns.push({
      headerName: 'Bazaarvoice',
      groupId: 'bazaarvoice',
      children: [
        {
          headerName: 'Moderation Status',
          field: 'bazaarvoice_moderation_status',
          filter: 'agSetColumnFilter',
        },
      ],
    });

    this.defaultColumns = defaultColumns;
  }
}

export default new ReviewsHttp();
