import PriceboxBaseHttp from './PriceboxBaseHttp';

/**
 * Class to handle all pricebox related http requests
 */
class PriceboxHttp extends PriceboxBaseHttp {
  constructor() {
    super();

    this.service = 'Pricebox';

    // Default pricebox columns
    this.defaultColumns = [
      // Catalog Group
      {
        headerName: 'Catalog',
        groupId: 'catalog',
        children: [
          {
            headerName: '',
            field: 'checkbox',
          },
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'ID',
            field: 'listing',
          },
          {
            headerName: 'Name',
            field: 'listing_name',
          },
          {
            headerName: 'Redirect',
            field: 'redirect',
          },
        ],
      },
      // Summarized Pricing Group
      {
        headerName: 'Pricing',
        groupId: 'pricing',
        children: [
          // Number of sellers selling the product in the time range
          {
            headerName: 'Number Sellers',
            field: 'num_sellers',
          },
          // Number of sellers selling the product in the time range
          {
            headerName: 'Avg. Price',
            field: 'avg_price',
          },
          // Overall minimum price the product sold for in the time range
          {
            headerName: 'Min Price',
            field: 'min_price',
          },
          // Overall maximum price the product sold for in the time range
          {
            headerName: 'Max Price',
            field: 'max_price',
          },
          // Overall maximum shipping price for the given time range
          {
            headerName: 'Max Shipping',
            field: 'max_shipping',
          },
        ],
      },
      {
        headerName: 'MAP',
        groupId: 'map',
        children: [
          // Number of sellers that violated the pricing guidelines in the time range
          {
            headerName: 'Sellers with Violations',
            field: 'num_sellers_had_violations',
          },
          // Customer's lowest pricing guidelines
          {
            headerName: 'MAP Low',
            field: 'guideline_low',
          },
          // Customer's highest pricing guidelines
          {
            headerName: 'MAP High',
            field: 'guideline_high',
          },
        ],
      },
      // Buybox Group
      {
        headerName: 'Buy Box',
        groupId: 'buybox',
        children: [
          {
            headerName: 'Buy Box Present',
            field: 'bb_total_time_occupied',
          },
          {
            headerName: 'Avg. Price',
            field: 'bb_avg_price',
          },
          {
            headerName: 'Min Price',
            field: 'bb_min_price',
          },
          {
            headerName: 'Max Price',
            field: 'bb_max_price',
          },
          {
            headerName: 'Number of Buy Box Sellers',
            field: 'bb_num_sellers',
          },
          {
            headerName: 'Last Buy Box Merchant',
            field: 'bb_last_merchant',
          },
          {
            headerName: 'Majority Buy Box Merchant',
            field: 'bb_dominant_merchant',
          },
          {
            headerName: 'Top Merchant Buy Box Owned',
            field: 'bb_dominant_merchant_occupied',
          },
          {
            headerName: 'Add-on',
            field: 'bb_dominant_addon',
          },
          {
            headerName: 'Fresh',
            field: 'bb_dominant_fresh',
          },
          {
            headerName: 'Pantry',
            field: 'bb_dominant_pantry',
          },
          {
            headerName: 'Prime',
            field: 'bb_dominant_prime',
          },
          {
            headerName: 'Stock',
            field: 'bb_dominant_stock',
          },
          {
            headerName: 'SNS',
            field: 'bb_dominant_subscribe',
          },
          {
            headerName: 'Used',
            field: 'bb_dominant_used',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'bb_dominant_merchant_occupied',
      'bb_total_time_occupied',
      'bb_avg_price',
      'bb_min_price',
      'bb_max_price',
      'bb_num_sellers',
      'avg_price',
      'buybox_price',
      'max_price',
      'min_price',
      'guideline_low',
      'guideline_high',
      'num_sellers',
      'num_sellers_in_violation',
      'current_highest_rank',
      'current_lowest_rank',
      'current_max_price',
      'current_max_shipping',
      'current_min_price',
      'current_min_shipping',
      'highest_rank',
      'lowest_rank',
      'max_shipping',
      'min_shipping',
      'num_sellers_had_violations',
      'num_prime',
      'price',
    ];
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [listingData, savedGridFiltersData] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedFilters(),
    ]);

    const { data, columns, grid_settings } = listingData;

    // Get user custom fields from listing data
    let newCustomFields = [];

    if (columns && columns.length) {
      newCustomFields = columns.filter((c) => c.is_custom).map((c) => c.name);
    }

    return this.constructGrid(
      data,
      grid_settings,
      savedGridFiltersData,
      newCustomFields
    );
  }

  async getListings(params = {}) {
    return this.getListingData(this.service, params);
  }

  /**
   * Async function to fetch pricebox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: this.service });
  }

  /**
   * Async function to fetch pricebox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Async function to fetch buyprices for given listings.
   *
   * @param {Object} params HTTP get params
   */
  async getBuyprices(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/betabuyprices`, params)
      .then((response) => response.data)
      .then((response) => response);
  }

  /**
   * Async function to fetch prices for given listings.
   *
   * @param {Object} params HTTP get params
   */
  async getPriceHistory(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/betapricehistory`, params)
      .then((response) => response.data)
      .then((response) => response);
  }

  /**
   * Async function to fetch buyprices for given listings.
   *
   * @param {Object} params HTTP get params
   */
  async getMapViolateHistory(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/betamaphistory`, params)
      .then((response) => response.data)
      .then((response) => response);
  }

  /**
   * Async function to update custom field metadata for pricebox products.
   *
   * @param {Object} params HTTP Post params object
   */
  async updatePriceboxCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customfields`, params);
  }

  /**
   * Async function to update custom field metadata for searchbox terms.
   *
   * @param {Object} params HTTP Post params object
   */
  async updatePriceboxMapPricing(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/pricealert`, params);
  }

  static constructRowData(listingsData, columnDefs) {
    const rowData = [];

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)),
      []
    );

    if (listingsData && listingsData.length) {
      listingsData.forEach((listing) => {
        const row = {};

        columnTypes.forEach((column) => {
          if (Object.prototype.hasOwnProperty.call(listing, column)) {
            if (column === 'num_sellers_in_violation') {
              if (listing.num_sellers_in_violation) {
                row[
                  column
                ] = `${listing.num_sellers_in_violation} in Violation`;
              } else {
                row[column] = 'No Violation';
              }
            } else if (
              [
                'buybox_price',
                'shipping',
                'max_price',
                'min_price',
                'guideline_low',
                'guideline_high',
                'min_shipping',
                'max_shipping',
              ].includes(column)
            ) {
              if (listing[column]) {
                row[column] = parseFloat(listing[column]).toFixed(2);
              }
            } else if (
              [
                'buybox_prime',
                'buybox_pantry',
                'buybox_subscribe',
                'buybox_fresh',
                'buybox_addon',
                'buybox_used',
                'prime',
              ].includes(column)
            ) {
              row[column] = listing[column] ? 'Yes' : 'No';
            } else if (['buybox_merchant'].includes(column)) {
              if (listing[column] === null) {
                row[column] = ['', ''];
              } else {
                row[column] = [
                  listing.buybox_merchant,
                  listing.buybox_merchant_id,
                ];
              }
            } else if (column === 'buybox_stock' && listing[column] === null) {
              row[column] = 'unknown';
            } else {
              row[column] = listing[column];
            }

            if (listing.notfound) {
              if (column === 'name') {
                row[column] = ` (Not Live) ${row[column]}`;
              } else if (column === 'buybox_stock') {
                row[column] = 'unavailable';
              } else if (
                ['buybox_price', 'max_price', 'min_price'].includes(column)
              ) {
                row[column] = '';
              } else if (column === 'buybox_merchant') {
                row[column] = ['', ''];
              }
            }
          } else if (
            Object.prototype.hasOwnProperty.call(listing, 'fields') &&
            listing.fields !== null &&
            Object.prototype.hasOwnProperty.call(listing.fields, column)
          ) {
            row[column] = listing.fields[column];
          } else {
            // These fields were added to the db later so this column doesn't
            // exist for some fields - handle them here
            row[column] = 'Not Available';
          }

          if (row[column] == null) {
            row[column] = '';
          }
        });

        // Add a products url to amazon
        row.producturl = listing.listing_url;
        row.redirect_url = listing.redirect_url;
        rowData.push(row);
      });
    }

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default new PriceboxHttp();
