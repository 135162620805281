import { DownloadsHttp } from '@/services/api';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import isToday from 'date-fns/isToday';
import isNil from 'lodash/isNil';

function statusIcon(status) {
  switch (status) {
    case 'completed':
      return {
        type: 'success',
        icon: 'check-icon',
      };
    case 'submitted':
    case 'pending':
      return {
        type: 'warning',
        icon: 'timer-sand-icon',
      };
    case 'failed':
    case 'failedupload':
    default:
      return {
        type: 'danger',
        icon: 'close-icon',
      };
  }
}

export default {
  /**
   * Action to fetch downloads from back-end.
   *
   * @dispatch {Function} Function to call other actions in downloads store
   * @returns {Promise} Promise object that returns http response object
   */
  async getDownloads({ dispatch }) {
    return DownloadsHttp.getDownloads({ limit: 100 })
      .then((response) => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'exports')) {
          const downloads = response.data.exports.map((download) => {
            const temp = { ...download };
            const start_ts = new Date(download.start_ts * 1000);
            const end_ts = new Date(download.end_ts * 1000);
            const start = format(addMinutes(start_ts, start_ts.getTimezoneOffset()), 'MM-dd-yyyy');
            const end = format(addMinutes(end_ts, end_ts.getTimezoneOffset()), 'MM-dd-yyyy');

            temp.status = statusIcon(download.status);
            temp.exportDate = format(new Date(download.export_ts * 1000), 'MM-dd-yyyy');
            temp.exportDateFriendly = format(new Date(download.export_ts * 1000), 'MM-dd-yyyy');

            // Flatten the options object to the root object
            if (download.options && download.options.length) {
              temp.options = download.options[0].options;
            }

            // Check if the date range is all
            if (temp.options && temp.options.exportDateRange && temp.options.exportDateRange === 'all') {
              temp.reportRange = 'All';
            } else if (isNil(download.start_ts) && isNil(download.end_ts)) {
              temp.reportRange = 'All';
            } else {
              temp.reportRange = `${start} to ${end}`;
            }

            // Create user-friendly timestamps for downloads created
            // 'Today' and 'Yesterday'
            const exportDate = new Date(download.export_ts * 1000);
            if (isToday(exportDate)) {
              temp.exportDateFriendly = 'Today';
            }

            return temp;
          });

          dispatch('setDownloads', downloads);
        } else {
          throw Error('No downloads available');
        }
      });
  },
  /**
   * Action to call mutation to set downloads in state.
   *
   * @param {Function} commit Commit calls mutation functions
   * @param {Array} downloads Array of downloads
   */
  setDownloads({ commit }, downloads) {
    commit('setDownloads', downloads);
  },
};
