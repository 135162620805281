import CopyboxHttp from './CopyboxHttp';

/**
 * CopyboxReference Class manages fetching for the copybox reference data.
 */
class CopyboxReferenceHttp extends CopyboxHttp {
  constructor() {
    super();

    this.service = 'content';
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    return this.constructGrid(
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  /**
   * Async method to fetch listing data for CopyboxReference
   *
   * @param {Object} params HTTP params object
   */
  async getListings(params = {}) {
    return this.getListingData(this.service, params);
  }

  /**
   * Async function to fetch copybox saved filters.
   *
   * @todo Make CopyboxReference have its own saved filters.
   * Right now the saved filters are shared with CopyboxReference.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Copybox' });
  }

  /**
   * Async function to fetch copybox saved grid preferences.
   *
   * @todo Make CopyboxReference have is own saved grid preferences.
   * Right now the grid preferences are shared with CopyboxReference.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: 'Copybox' });
  }

  /**
   * Async function to upload listings to services.
   *
   * @param {Object} params HTTP get params
   */
  async uploadReferenceContent(params) {
    return this.postFile(`${this.PrependRoutes.reviewbox}/bulk-content`, params);
  }

  /**
   * Async to get content guidelines data
   */
  async getContentGuidelines() {
    return this.get(`${this.PrependRoutes.reviewbox}/contentguide`)
      .then((response) => response.data.guidelines || []);
  }

  /**
   * Async to post content guidelines data
   */
  async uploadContentGuidelines(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/contentguide`, params);
  }

  /**
   * Async to post reference content
   *
   * @param {Object} params HTTP get parameters
   */
  async uploadListingReferenceContent(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/content`, params);
  }

  /**
   * Async to post reference content
   *
   * @param {Object} params HTTP get parameters
   */
  async deleteReferenceContent(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/content`, params);
  }
}

export default new CopyboxReferenceHttp();
