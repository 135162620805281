import { BaseHttp, ReviewsHttp } from '@/services/api';
import isPlainObject from 'lodash/isPlainObject';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

export default {
  /**
   * Action to get and set response integrations for reviews
   *
   * @param {Function} commit Function to call mutation
   */
  async getResponseIntegrations({ commit }) {
    return ReviewsHttp.getResponseIntegrations()
      .then((data) => {
        let sortedData = cloneDeep(data);

        // Sort the data alphabetically before mutating store
        // Check if array
        if (Array.isArray(data) && data.length) {
          // Sort the data alphabetically
          sortedData = sortBy(sortedData, ['public']);
        } else if (isPlainObject(sortedData)) {
          // eslint-disable-next-line
          for (let [key, value] of Object.entries(sortedData)) {
            sortedData[key] = sortBy(value, ['public']);
          }
        }

        // Check if object
        commit('setResponseIntegrations', sortedData);
      });
  },
  /**
   * Action to reviews response topics values for column.
   *
   * @param {Function} commit Function to call mutation
   */
  async getCustomTopics({ commit }) {
    return BaseHttp.getCustomTopics()
      .then((valuesToAdd) => {
        commit('grids/addDropdownColumnValues', { service: 'reviews', field: 'topic', valuesToAdd }, { root: true });
      });
  },
  /**
   * Action to fetch reviews customCol values for column.
   *
   * @param {Function} commit Function to call mutation
   */
  async getCustomColValues({ commit }) {
    return BaseHttp.getCustomColValues()
      .then((valuesToAdd) => {
        commit('grids/addDropdownColumnValues', { service: 'reviews', field: 'customCol', valuesToAdd }, { root: true });
      });
  },
  /**
   * Action to set Chart Height
   *
   * @param {Function} commit Function to call mutation
   */
  setChartsHeight({ commit }, status) {
    commit('setChartsHeight', status);
  },
  /**
   * Action to set showCharts status
   *
   * @param {Function} commit Function to call mutation
   */
  setShowCharts({ commit }, status) {
    commit('setShowCharts', status);
  },
  /**
   * Action to reset showCharts status to default
   *
   * @param {Function} commit Function to call mutation
   */
  resetShowCharts({ commit }) {
    commit('resetShowCharts');
  },
};
