export default {
  /**
   * Getter to return the Chart Height from the reviews store.
   *
   * @param {Object} state Reviews store data
   */
  getChartsHeight(state) {
    return state.chartsHeight;
  },
  /**
   * Getter to return the responseIntegrations from the reviews store.
   *
   * @param {Object} state Reviews store data
   */
  getResponseIntegrations(state) {
    return state.responseIntegrations;
  },
  /**
   * @returns {Boolean} flag to indicate if user has Bazaarvoice integration
   */
  hasBazaarvoiceIntegration(state) {
    if (state.responseIntegrations) {
      return Object.prototype.hasOwnProperty.call(state.responseIntegrations, 'bazaarvoice');
    }

    return false;
  },
  /**
   * Getter to return the showCharts boolean flag.
   *
   * @param {Object} state Reviews store data
   */
  getShowCharts(state) {
    const localState = state.reviewsShowCharts;

    if (localState === null) {
      return false;
    }
    return localState;
  },
};
