var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "slide-x-right-transition",
    { attrs: { duration: 300, mode: "out-in" } },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }