import BaseHttp from './BaseHttp';

/**
 * Class to handle all Copybox related http requests
 */
class CopyboxHttp extends BaseHttp {
  constructor() {
    super();

    // Default copybox columns
    this.defaultColumns = [
      {
        headerName: 'Catalog',
        groupId: 'general',
        children: [
          {
            headerName: '',
            field: 'checkbox',
          },
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'ID',
            field: 'listing',
          },
          {
            headerName: 'Name',
            field: 'name',
          },
          {
            headerName: 'Redirect',
            field: 'redirect',
          },
        ],
      },
      {
        headerName: 'Copybox',
        groupId: 'copybox',
        children: [
          {
            headerName: 'Compliance',
            field: 'compliance',
          },
          {
            headerName: 'Compliance Issues',
            field: 'compproblem',
          },
          {
            headerName: 'Guideline',
            field: 'guideline',
          },
          {
            headerName: 'Image',
            field: 'imgurl',
          },
          {
            headerName: 'Images',
            field: 'num_images',
          },
          {
            headerName: 'Brand',
            field: 'brand',
          },
          {
            headerName: 'Description',
            field: 'description',
          },
          {
            headerName: 'Type',
            field: 'dtype',
          },
          {
            headerName: 'Bullets',
            field: 'num_bullets',
          },
          {
            headerName: 'Variations',
            field: 'num_variations',
          },
          {
            headerName: 'Categories',
            field: 'num_categories',
          },
          {
            headerName: 'Updated',
            field: 'ts',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'num_images',
      'num_bullets',
      'num_variations',
      'num_categories',
    ];
  }

  /**
   * Static method to create ag-grid row data from copybox data.
   *
   * @param {Array} listingsData Array of objects for listings metadata
   * @param {Array} columnDefs Array of objects for ag-grid column definitions
   */
  static constructRowData(listingsData, columnDefs) {
    const rowData = [];

    // Return empty array if no listingsData
    if (listingsData.length === 0) {
      return rowData;
    }

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)),
      []
    );

    const cellsWithCountStatistics = {
      num_images: 'images',
      num_bullets: 'features',
      num_variations: 'variations',
      num_categories: 'categories',
    };

    listingsData.forEach((listing) => {
      const row = {};

      columnTypes.forEach((column) => {
        // Information is a built field
        if (Object.prototype.hasOwnProperty.call(listing, column)) {
          // Display information correctly
          if (column === 'compliance') {
            if (listing.compliance) {
              row[column] = 'No';
            } else if (listing.compliance === false) {
              row[column] = 'Yes';
            } else {
              row[column] = 'Unknown';
            }
          } else if (column === 'guideline') {
            if (listing.guideline) {
              row[column] = 'Passed';
            } else if (listing.guideline === false) {
              row[column] = 'Failed';
            } else {
              row[column] = 'Unknown';
            }
          } else if (column === 'dtype') {
            if (listing.dtype === 'aplus') {
              row[column] = 'A+';
            } else if (listing.dtype === 'enhanced') {
              row[column] = 'EBC';
            } else {
              row[column] = 'Plain';
            }
          } else if (column === 'ts') {
            row[column] = new Date(listing.ts * 1000);
          } else {
            row[column] = listing[column];
          }
          // information is a custom field
        } else if (
          Object.prototype.hasOwnProperty.call(listing, 'fields') &&
          listing.fields !== null &&
          Object.prototype.hasOwnProperty.call(listing.fields, column)
        ) {
          row[column] = listing.fields[column];
          // information is counting a built in field
        } else if (
          Object.prototype.hasOwnProperty.call(cellsWithCountStatistics, column)
        ) {
          if (listing[cellsWithCountStatistics[column]]) {
            row[column] = listing[cellsWithCountStatistics[column]].length;
          } else {
            row[column] = 0;
          }
        } else {
          row[column] = '';
        }

        if (row[column] === null) {
          row[column] = '';
        }
      });

      row.producturl = listing.url;
      row.redirect_url = listing.redirect_url;
      rowData.push(row);
    });

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default CopyboxHttp;
