/**
 * Mixin to handle redirecting to a saved tab for a parent page.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  beforeMount() {
    /**
     * Handle redirecting to saved tab path if user tries to navigate
     * to a parent page by enteriing the url or through side-menu.
     *
     * This requires added a data variable "service" in the parent page
     * associated with the names mapped in the Tabs store, located in
     * the file /store/modules/tabs/state.js
     */
    const { path, name } = this.$route;
    const savedTab = this.$store.getters['tabs/getSavedTabByService'](this.service);

    // If $route has the property redirectedFrom,
    // then use the savedTab since user was trying to
    // navigate to home of parent page
    if (Object.prototype.hasOwnProperty.call(this.$route, 'redirectedFrom')) {
      // But only redirect if not already at the page
      if (path !== savedTab.path) {
        this.$router.push(savedTab.path);
      }
    // Else remain on the page the user navigated to specifically
    // and set that in the savedTabHistory
    } else {
      this.$store.commit('tabs/setTabHistory', { service: this.service, path, name });
    }
  },
};
