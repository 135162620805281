import DefaultLayout from 'src/layouts/default.vue';
import CreateReportLayout from 'src/pages/Reports/CreateReport/CreateReportLayout.vue';

const ReportsEdit = () => import('src/pages/Reports/_id/edit/index.vue');
const ReportsView = () => import('src/pages/Reports/_id/view/index.vue');

export default {
  path: '/reports',
  name: 'Reports',
  redirect: '/',
  component: DefaultLayout,
  meta: { requiresAuth: true, module: 'reports' },
  children: [
    {
      path: '',
      name: 'Active Reports',
      meta: { requiresAuth: true, module: 'reports' },
      components: { default: () => import('src/pages/Reports/Reports.vue') },
    },
    {
      path: 'create',
      name: 'Create Report',
      redirect: '/',
      meta: { requiresAuth: true, module: 'reports' },
      component: CreateReportLayout,
      children: [
        {
          path: '',
          name: 'Report Select',
          meta: { requiresAuth: true, module: 'reports' },
          components: { default: () => import('src/pages/Reports/CreateReport/SelectReport.vue') },
        },
        {
          path: 'report-form',
          name: 'Report Form',
          meta: { requiresAuth: true, module: 'reports' },
          components: { default: () => import('src/pages/Reports/CreateReport/CreateReportForm.vue') },
        },
      ],
    },
    {
      path: ':id/edit',
      name: 'Edit Report',
      meta: { requiresAuth: true, module: 'reports' },
      component: ReportsEdit,
    },
    {
      path: ':id/view',
      name: 'View Report',
      meta: { requiresAuth: true, module: 'reports' },
      component: ReportsView,
    },
  ],
};
