import BaseHttp from './BaseHttp';

/**
 * Class to handle all CRM related http requests
 */
class CRMAccountsHttp extends BaseHttp {
  /**
   * Link up Salesforce & other external CRMs.
   */
  linkAccount(crm) {
    return this.get(`${this.PrependRoutes.ams}/portal/${crm}`)
      .then((response) => response.data);
  }
}

export default new CRMAccountsHttp();
