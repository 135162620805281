import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isUsingTemplate: false,
      isLoadingTemplate: false,
      openWidgetTemplateModal: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedWidgetTemplate: 'insights/selectedWidgetTemplate',
    })
  },
  methods: {
    ...mapActions({
      getSelectedWidgetTemplate: 'insights/getSelectedWidgetTemplate',
    }),
    ...mapMutations({
      setSelectedWidgetTemplate: 'insights/setSelectedWidgetTemplate',
    }),
    /**
     * Opens a template modal to select which widget template to use creating a widget.
     */
    onOpenWidgetTemplateModal() {
      this.openWidgetTemplateModal = true;
    },
    /**
     * Close the Edit Widget Modal and dialog
     */
    onCloseWidgetTemplateModal() {
      this.openWidgetTemplateModal = false;
    },
    /**
     * Method to handle using a widget template when creating a new widget.
     *
     * @todo
     * - Remove this function if it's not necessary. When we select a default or
     *   custom widget from the template view, we already have the settings attached
     *   to the object, so we don't need to fetch the widget again from the database.
     */
    onWidgetTemplateSelected(template, widgetId = null) {
      // Should fetch to get the layout, clone template as a new one - strip out ids
      this.isLoadingTemplate = true;
      return this.getSelectedWidgetTemplate({ id: template.id, widgetId })
        .then(() => {
          // Then open the edit widget modal

        })
        .catch((error) => {
          console.error('Could not get template', error.message);
          this.handleError(error);
        })
        .finally(() => {
          this.isLoadingTemplate = false;
        });
    },
    /**
     * Method to handle triggering a Hubspot feedback form for Insights Widget
     */
    onGiveFeedback() {
      console.log('user wants to give feedback');
    },
  }
};
