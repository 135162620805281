import cloneDeep from 'lodash/cloneDeep';
import BaseHttp from './BaseHttp';

/**
 * Class to handle all Topic related http requests
 */
class TopicHttp extends BaseHttp {
  async getTopics() {
    return this.get(`${this.PrependRoutes.reviewbox}/topics`).then(
      (response) => {
        const { topics } = response.data;
        if (topics === null) {
          return [];
        }
        for (let i = 0; i < topics.length; i += 1) {
          const topic = topics[i];
          for (let k = 0; k < Object.keys(topic).length; k += 1) {
            const key = Object.keys(topic)[k];
            try {
              topic[key] = JSON.parse(topic[key]);
            } catch (err) {
              // continue regardless of error
            }
          }
        }
        return topics;
      },
    );
  }

  setTopic(params = {}) {
    const data = cloneDeep(params);
    for (let k = 0; k < Object.keys(data).length; k += 1) {
      const key = Object.keys(data)[k];
      if (key === 'keywords') {
        data[key] = JSON.stringify(data[key]);
      }
    }
    return this.post(`${this.PrependRoutes.reviewbox}/topics`, data)
      .then((response) => response.data);
  }

  deleteTopic(params = {}) {
    return this.delete(`${this.PrependRoutes.reviewbox}/topics`, params)
      .then((response) => response.data);
  }
}

export default new TopicHttp();
