import format from 'date-fns/format';

/**
 * TimeParser Mixin for handling date/timestamp string formats
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Function to format a timestamp into a UTC date.
     *
     * @param {Number} value Timestamp
     * @param {String} format Format of desired date string
     * @returns {String} Returns a formated, readable date string
     */
    formatDate(value, f = 'MM-dd-yyyy', multiplier = 1000) {
      return format(new Date(value * multiplier), f);
    },

    /**
     * Function to format a timestamp into a local date.
     *
     * @param {Number} value Timestamp
     * @param {String} format Format of desired date string
     * @returns {String} Returns a formated, readable date string
     */
    formatLocalDate(value, f = 'MM-dd-yyyy', multiplier = 1000) {
      return format(new Date(value * multiplier), f);
    },

    /**
     * Function to return a date range string.
     *
     * @param {Number} s Timestamp usually in seconds
     * @param {Number} e Timestamp usually in seconds
     * @param {Number} multiplier Default 1000. To conver to miliseconds
     * @returns {String} Returns a string of the formatted date range
     */
    getDateRange(start, end, f = 'MM-dd-yyyy', multiplier = 1000) {
      let result = null;

      if (typeof start === 'number' && typeof end === 'number') {
        result = `${this.formatDate(start, f, multiplier)}`;

        if (start === end) {
          return result;
        }

        return `${result} to ${this.formatDate(end)}`;
      }

      if (typeof start === 'number' && typeof end !== 'number') {
        return `${this.formatDate(start, f, multiplier)}`;
      }

      if (typeof start !== 'number' && typeof end === 'number') {
        return `? to ${this.formatDate(end, f, multiplier)}`;
      }

      return null;
    },
    /**
     * Converts a date range into a readable string for the back-end.
     * Converts from local time to UTC
     *
     * @param {Date} s Start of date range, in local time
     * @param {Date} e End of date range, in local time
     * @returns {String} Returns a string representing date range
     */
    formatCustomDateRange(s, e) {
      // Get the UTC offset from local time so we can convert to UTC correctly
      // getTime is in milliseconds
      // getTimezoneOffset is in minutes, so need to convert to milliseconds -> m * 60 * 1000
      const start = new Date(s.getTime() - (s.getTimezoneOffset() * 60 * 1000));
      const startISOString = start.toISOString();

      // Get the UTC offset from local time so we can convert to UTC correctly
      // getTime is in milliseconds
      // getTimezoneOffset is in minutes, so need to convert to milliseconds -> m * 60 * 1000
      const end = new Date(e.getTime() - (e.getTimezoneOffset() * 60 * 1000));
      const endISOString = end.toISOString();

      return `custom-${startISOString} ${endISOString} UTC`;
    },
  },
};
