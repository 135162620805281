var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dropdown",
    {
      attrs: {
        trigger: "click",
        disabled: _vm.disabled,
        placement: "bottom-end",
      },
      on: { command: _vm.onSelectDownload },
    },
    [
      _c(
        "el-button",
        {
          staticClass: "bg-transparent",
          attrs: { type: "plain", size: "small", disabled: _vm.disabled },
        },
        [
          _vm._v(" Download"),
          _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
        ]
      ),
      _c(
        "el-dropdown-menu",
        {
          staticClass: "rb-download-dropdown",
          attrs: { slot: "dropdown" },
          slot: "dropdown",
        },
        [
          _vm._l(_vm.options, function (option, optionIndex) {
            return _c(
              "el-dropdown-item",
              {
                key: optionIndex,
                staticClass: "d-flex align-items-center",
                attrs: { disabled: option.disabled, command: optionIndex },
              },
              [
                option.icon
                  ? _c(option.icon, {
                      tag: "component",
                      staticClass: "rb-icon-sm mr-2",
                    })
                  : _vm._e(),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      disabled: !option.tooltip,
                      "open-delay": 300,
                      placement: "top",
                    },
                  },
                  [
                    _c("div", {
                      staticStyle: { "max-width": "250px" },
                      attrs: { slot: "content" },
                      domProps: { innerHTML: _vm._s(option.tooltip) },
                      slot: "content",
                    }),
                    _c("div", { staticClass: "w-100" }, [
                      _vm._v(" " + _vm._s(option.label) + " "),
                    ]),
                  ]
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            {
              staticClass:
                "dropdown-help border-top d-flex align-content-start",
            },
            [
              _c("info-icon", {
                staticClass: "rb-icon rb-icon-lg text-info mr-1",
              }),
              _c(
                "div",
                { staticClass: "dropdown-help__content" },
                [
                  _c("small", { staticClass: "d-block" }, [
                    _vm._v(
                      " Don't need particular data in your export? Edit your export settings to focus on the data important to you. "
                    ),
                  ]),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "el-button el-button--success el-button--mini mt-2",
                      attrs: { to: "/account/export-settings" },
                    },
                    [_vm._v(" Manage export settings ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }