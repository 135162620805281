var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "rb-grid-header w-100 d-flex justify-content-between header-text",
    },
    [
      _c(
        "div",
        { staticClass: "flex-grow-1", on: { click: _vm.onSortColumn } },
        [
          !_vm.isEdit
            ? _c("span", { staticClass: "customHeaderLabel" }, [
                _vm._v(" " + _vm._s(_vm.params.displayName) + " "),
                _vm.params.editHeader && !_vm.isEdit
                  ? _c(
                      "span",
                      {
                        ref: "headerEditButton",
                        on: {
                          click: function ($event) {
                            return _vm.onHeaderEdit($event)
                          },
                        },
                      },
                      [_c("pencil-icon", { staticClass: "rb-icon-sm" })],
                      1
                    )
                  : _vm._e(),
                _vm.params.enableSorting && _vm.isSortActive
                  ? _c(
                      "span",
                      { staticClass: "sort-label px-1" },
                      [
                        _vm.sortState === "desc"
                          ? _c("arrow-up-icon", { staticClass: "rb-icon-sm" })
                          : _vm._e(),
                        _vm.sortState === "asc"
                          ? _c("arrow-down-icon", { staticClass: "rb-icon-sm" })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.isEdit
            ? _c("span", { staticClass: "el-input el-input--mini" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.headerEditText,
                      expression: "headerEditText",
                    },
                  ],
                  ref: "headerInput",
                  staticClass: "rb-header-input el-input__inner",
                  attrs: { type: "text" },
                  domProps: { value: _vm.headerEditText },
                  on: {
                    click: function ($event) {
                      return _vm.onClickEditHeaderText($event)
                    },
                    blur: function ($event) {
                      return _vm.onSubmitEditHeader($event)
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return $event.target.blur()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.headerEditText = $event.target.value
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.isDropdownColumn
            ? _c(
                "span",
                {
                  staticClass: "ml-auto px-1",
                  on: { click: _vm.onAddDropdownColumnValue },
                },
                [_c("plus-icon", { staticClass: "rb-icon-sm" })],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.params.enableMenu && !_vm.isEdit
        ? _c(
            "div",
            {
              ref: "menuButton",
              on: {
                click: function ($event) {
                  return _vm.onMenuClicked($event)
                },
              },
            },
            [
              !_vm.params.column.isFilterActive()
                ? _c(_vm.params.menuIcon, {
                    tag: "component",
                    staticClass: "rb-icon-sm",
                  })
                : _vm._e(),
              _vm.params.column.isFilterActive()
                ? _c(_vm.params.menuIcon, {
                    tag: "component",
                    staticClass: "sort-label rb-icon-sm",
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }