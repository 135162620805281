import cloneDeep from 'lodash/cloneDeep';
import { filterServicesFromLinks } from '@/utils';

export default {
  getAddItemsLinks(state, getters, rootState, rootGetters) {
    return filterServicesFromLinks(
      cloneDeep(state.addItemsLinks),
      rootGetters['account/getActiveServices'],
    );
  },

  /**
   * Return the updated state of an accounts catalog after an update
   * has occurred, i.e. after a delete or an addition.
   *
   * @param {*} state
   * @returns { Object } Current state of the account's catalog
   */
  getCatalogCount: (state) => state.catalogCount
};
