import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/pricebox',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    service: 'pricebox',
    module: 'pricebox',
  },
  children: [
    {
      path: '',
      name: 'Pricebox',
      redirect: '/pricebox/products',
      meta: {
        requiresAuth: true,
        service: 'pricebox',
        module: 'pricebox',
      },
      components: {
        default: () => import('src/pages/Pricebox/PriceboxLayout.vue'),
      },
      children: [
        {
          path: 'products',
          name: 'Pricebox Products',
          label: 'Products',
          meta: {
            requiresAuth: true,
            service: 'pricebox',
            module: 'pricebox',
          },
          components: {
            default: () => import('src/pages/Pricebox/Pricebox.vue'),
          },
        },
        {
          path: 'sellers',
          name: 'Pricebox Sellers',
          label: 'Sellers',
          meta: {
            requiresAuth: true,
            service: 'pricebox',
            module: 'pricebox',
          },
          components: {
            default: () => import('src/pages/Pricebox/Sellers/Sellers.vue'),
          },
        },
      ],
    },
  ],
};
