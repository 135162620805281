import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import PrependRoutes from './PrependRoutes';
import EventBus from '@/services/event-bus/EventBus';

import {
  numericComparator,
  dateComparator,
  percentFormatter,
  percentParser,
} from '@/utils';

// Webpack dev server cert authority invalid when true
axios.defaults.withCredentials = true;

class BaseHttp {
  constructor() {
    this.PrependRoutes = PrependRoutes;
    this.axios = axios.create({ baseURL: process.env.VUE_APP_BASE_URL });

    // Helper functions for HTTP requests that insert middleware
    // to check for response errors
    this.get = (endpoint, params = {}, isCancelable = false) => {
      if (isCancelable) {
        const source = axios.CancelToken.source();
        const cancelToken = source.token;
        EventBus.$emit('http-cancel-token', source);

        return this.axios.get(endpoint, { params, cancelToken })
          .then((res) => (this.constructor.checkForErrors(res)));
      }

      return this.axios.get(endpoint, { params })
        .then((res) => (this.constructor.checkForErrors(res)));
    };

    this.post = (endpoint, payload = {}) => this.axios.post(endpoint, payload)
      .then(this.constructor.checkForErrors);

    this.postFile = (endpoint, payload = {}) => this.axios.post(endpoint, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(this.constructor.checkForErrors);

    // Patch should be passed as a body, but converts body to a query string
    this.patch = (endpoint, data = {}) => this.axios.patch(endpoint, data)
      .then(this.constructor.checkForErrors);

    this.delete = (endpoint, data = {}) => this.axios.delete(endpoint, { data })
      .then(this.constructor.checkForErrors);

    this.deleteFile = (endpoint, data = {}) => this.axios.delete(endpoint, { data }, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(this.constructor.checkForErrors);
  }

  /**
   * Async function to upload listings to services.
   *
   * @param {Object} params HTTP get params
   * @param {Boolean} isBulk Flag for bulk listings, uses a different endpoint
   */
  async uploadCatalog(isBulk, params) {
    if (isBulk) {
      return this.postFile(`${this.PrependRoutes.reviewbox}/bulk-catalog`, params);
    }

    return this.post(`${this.PrependRoutes.reviewbox}/catalog`, params);
  }

  /**
   * Async function to upload listings to services.
   *
   * @param {Object} params HTTP get params
   * @param {Boolean} isBulk Flag for bulk listings, uses a different endpoint
   */
  async deleteCatalog(isBulk, params) {
    if (isBulk) {
      return this.deleteFile(`${this.PrependRoutes.reviewbox}/bulk-catalog`, params);
    }

    return this.delete(`${this.PrependRoutes.reviewbox}/catalog`, params);
  }

  /**
   * Async function to get undo deleted grid listings.
   *
   * @param {Object} params HTTP get params
   */
  async getDeletedRows(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/gettrashcan`, params);
  }

  /**
   * Async function to fetch single listing data.
   *
   * @param {Object} params HTTP get params
   */
  async getProduct(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/product`, params)
      .then((response) => response.data);
  }

  /**
   * Async function to remove listings/terms from a service's trashcan.
   *
   * @param {Object} params HTTP get params
   */
  async removeListingsFromTrash(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/takeouttrash`, params);
  }

  /**
   * Async function to fetch listing data per service.
   *
   * @param {Object} params HTTP get params
   */
  async getListingData(service, params) {
    switch (service.toLowerCase()) {
      case 'reviewbox':
        return this.get(`${this.PrependRoutes.reviewbox}/reviewbox`, params)
          .then((response) => response.data);
      case 'pricebox':
        return this.get(`${this.PrependRoutes.reviewbox}/pricebox`, params, true)
          .then((response) => response.data);
      case 'sellers':
        return this.get(`${this.PrependRoutes.reviewbox}/sellers`, params, true)
          .then((response) => response.data);
      case 'copybox':
        return this.get(`${this.PrependRoutes.reviewbox}/copybox`, params)
          .then((response) => response.data);
      case 'content':
        return this.get(`${this.PrependRoutes.reviewbox}/content`, params)
          .then((response) => response.data.listings);
      case 'searchbox':
        return this.get(`${this.PrependRoutes.reviewbox}/searchbox`, params)
          .then((response) => response.data.listings);
      case 'searchterms':
        return this.get(`${this.PrependRoutes.reviewbox}/searchterms`, params)
          .then((response) => response.data.terms);
      default:
        throw new Error(`Cannot fetch grid data. ${service} is not a valid service.`);
    }
  }

  /**
   * Async function to fetch saved grid filters.
   *
   * @param {Object} params HTTP get params
   */
  async getSavedGridFilters(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/gridfilters`, params)
      .then((response) => {
        let filters = [];
        const { data } = response;

        if (Object.prototype.hasOwnProperty.call(data, 'filters') && data.filters) {
          filters = data.filters;
        }

        return filters;
      });
  }

  /**
   * Async function to add grid filters per service.
   *
   * @param {Object} params HTTP get params
   */
  async postGridFilters(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/gridfilters`, params);
  }

  /**
   * Async function to delete grid filters per service.
   *
   * @param {Object} params HTTP get params
   */
  async deleteGridFilters(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/gridfilters`, params);
  }

  /**
   * Async function to fetch saved grid preferences.
   *
   * @param {Object} params HTTP get params
   */
  async getSavedGridPreferences(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/columnprefs`, params)
      .then((response) => {
        const { data } = response;

        if (
          Object.prototype.hasOwnProperty.call(data, 'gridcols')
          && data.gridcols !== null
        ) {
          const columnGroups = data.gridcols;

          // Remove any questions preferences for sentiment
          if (params && params.grid_type === 'Questions') {
            // Get Response column group
            const responseColumnGroup = columnGroups.find((group) => group.groupId === 'response');

            // Remove 'sentiment' child column
            responseColumnGroup.children = responseColumnGroup.children.filter((col) => col.field !== 'sentiment');
          }

          return columnGroups;
        }

        return null;
      });
  }

  /**
   * Async function to update saved grid preferences.
   *
   * @param {Object} params HTTP get params
   */
  async updateSavedGridPreferences(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/columnprefs`, params);
  }

  /**
   * Async function to fetch custom fields for grid.
   *
   * @param {Object} params HTTP get parameters
   */
  async getProductCustomFields(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/fields`, params)
      .then((response) => {
        const { data } = response;

        if (
          Object.prototype.hasOwnProperty.call(data, 'fields')
          && data.fields !== null
        ) {
          return data.fields;
        }

        return [];
      });
  }

  /**
   * Fetches Review/Question custom topic values for ag-grid column.
   */
  async getCustomTopics() {
    return this.get(`${this.PrependRoutes.reviewbox}/customtopics`)
      .then((response) => response.data.custom_topics);
  }

  /**
   * Fetches Review/Question customCol values for ag-grid column.
   */
  async getCustomColValues() {
    return this.get(`${this.PrependRoutes.reviewbox}/customcolfields`)
      .then((response) => response.data.custom_fields);
  }

  /**
   * Async function to update custom field metadata for reviewbox products.
   *
   * @param {Object} params HTTP Post params object
   */
  async updateCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customfields`, params);
  }

  /**
   * Async function to delete custom field metadata for searchbox terms.
   * This happens when a user enters '' in an ag-grid cell for custom fields.
   *
   * @param {Object} params HTTP Post params object
   */
  async deleteCustomFields(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/fields`, params);
  }

  /**
   * Static method to check for HTTP response errors
   * @param {Object} response HTTP response object
   */
  static checkForErrors(response) {
    if (
      response.data
      && Object.prototype.hasOwnProperty.call(response.data, 'error')
      && Object.prototype.hasOwnProperty.call(response.data, 'error_description')
    ) {
      if (response.data.error !== 'warning') {
        throw Error(response.data.error_description);
      }

      if (response.data.error === 'warning') {
        EventBus.$emit('notification-warning', response.data.error_description);
      }
    } else if (response.status !== 200) {
      throw Error(response.data);
    }

    return response;
  }

  /**
   * Function to construct ag-grid grid data.
   * Shared by other services, so method is set in BaseHttp.
   *
   * @param {Object} productData Array of product data
   * @param {Array} gridPreferences Array of saved grid preferences
   * @param {Object} customFields Object of grid custom fields
   */
  async constructGrid(
    productData = [],
    gridPreferences = [],
    savedFilters = {},
    customFields = {},
  ) {
    // Grab all custom field names for grid
    const fields = Object.keys(customFields);

    // Set up column data
    const columns = this.constructColumnData(gridPreferences, customFields);

    // Set up row data
    const rows = this.constructor.constructRowData(productData, columns);

    return {
      columns,
      rows,
      savedFilters,
      customFields: fields,
    };
  }

  /**
   * Function to create column data for ag-grid.
   *
   * @param {Object} gridPreferences A user's saved grid settings object
   * @param {Object} customFields Custom field headers for ag-grid
   */
  constructColumnData(gridPreferences, customFields = {}) {
    const columnDefs = [];
    let customFieldsKeys = [];

    if (Array.isArray(customFields)) {
      customFieldsKeys = customFields;
    } else {
      customFieldsKeys = Object.keys(customFields);
    }

    let hasSavedCustomField = false;

    let allColumns = [];
    // If grid preferences merge them with the default columns
    if (gridPreferences) {
      allColumns = cloneDeep(gridPreferences);

      // Go through columns and add any aditional column info
      allColumns.forEach((columnGroup) => {
        // Handle non-custom custom field
        if (columnGroup.groupId !== 'custom') {
          const matchedDefaultColumn = this.defaultColumns.find(
            (defaultGroupColumn) => defaultGroupColumn.groupId === columnGroup.groupId,
          );

          // If there are more default columns than saved
          // add those to allColumns
          if (
            matchedDefaultColumn
            && matchedDefaultColumn.children.length > columnGroup.children.length
          ) {
            matchedDefaultColumn.children.forEach((childColumn) => {
              const columnInPreference = columnGroup.children.find(
                (column) => column.field === childColumn.field,
              );

              // Add additional column to allColumns, since it
              // wasn't in the gridPreferences
              if (typeof columnInPreference === 'undefined') {
                columnGroup.children.push(childColumn);
              }
            });
          }
          // Handle custom field
        } else {
          hasSavedCustomField = true;

          customFieldsKeys.forEach((key) => {
            const matchedColumn = columnGroup.children.find(
              (childColumn) => childColumn.field === key,
            );

            if (typeof matchedColumn === 'undefined') {
              columnGroup.children.push({
                headerName: key,
                field: key,
              });
            }
          });
        }
      });

      /**
       * Find any column groups in the default columns not in the saved preferences
       * If so, add them to the end. These are typically new groups that came after saved preferences
       *
       * @todo Can we find a better way to do this? Frontend should not need to account for
       * saved columns or default columns, it should come from the API with the grid data
       * cleaned and ready so as little data manipulation has to be done on the frontend.
       */
      this.defaultColumns.forEach((g) => {
        const { groupId } = g;
        const match = allColumns.find((a) => a.groupId === groupId);

        if (typeof match === 'undefined') {
          allColumns.push(g);
        }
      });
      // If no grid preferences just use the default columns
    } else {
      allColumns = cloneDeep(this.defaultColumns);
    }

    // Add custom columns either if preferences were saved
    // before custom columns were added, but now they are present
    // or if we have no column preferences saved
    if (!hasSavedCustomField) {
      const customChildrenColumns = customFieldsKeys.map((field) => ({
        field,
        headerName: field,
      }));

      if (customChildrenColumns.length) {
        allColumns.splice(1, 0, {
          headerName: 'Custom Fields',
          groupId: 'custom',
          children: customChildrenColumns,
        });
      }
    }

    // Create ag-grid column definitions
    allColumns.forEach((columnGroup) => {
      const childColumns = [];

      columnGroup.children.forEach((childColumn) => {
        const tempColumn = {
          ...childColumn,
          suppressDragLeaveHidesColumns: true,
          lockPinned: false,
        };

        // Lock general column group
        if (columnGroup.groupId === 'general') {
          tempColumn.lockVisible = false;
        }

        // Set up filtering and sorting for numeric types
        if (
          this.numericColumns
          && Array.prototype.indexOf.call(this.numericColumns, childColumn.field) >= 0
        ) {
          tempColumn.type = 'numericColumn';
          tempColumn.filter = 'agNumberColumnFilter';
          tempColumn.comparator = numericComparator;

          // Format value to percent for filtering purposes
          if (['bb_total_time_occupied', 'bb_dominant_merchant_occupied'].includes(childColumn.field)) {
            tempColumn.filterParams = {
              numberParser: (text) => percentParser(text),
            };

            tempColumn.valueFormatter = function (params) {
              return percentFormatter(params.value);
            };
          }
          // Set up filtering and sorting for date types
        } else if (
          Array.prototype.indexOf.call(['ts', 'dt', 'lts', 'cts', 'latestupdatets', 'last_seen_ts'], childColumn.field) >= 0
        ) {
          tempColumn.filter = 'agDateColumnFilter';
          tempColumn.cellRenderer = 'dateCellRenderer';
          tempColumn.filterParams = {
            comparator: dateComparator
          };
        } else if (
          columnGroup.groupId === 'custom'
          || Array.prototype.indexOf.call(
            [
              'source',
              'retailer_source',
              'status',
              'seller_status',
              'sentiment',
              'topic',
              'customCol',
              'image',
              'video',
              'verified',
              'vine',
              'buybox_stock',
              'bb_dominant_stock',
              'num_sellers_in_violation',
              'buybox_prime',
              'bb_dominant_prime',
              'buybox_subscribe',
              'bb_dominant_subscribe',
              'buybox_addon',
              'bb_dominant_addon',
              'buybox_pantry',
              'bb_dominant_pantry',
              'buybox_fresh',
              'bb_dominant_fresh',
              'match_type',
              'bb_dominant_used',
              'bazaarvoice_moderation_status'
            ],
            childColumn.field,
          ) >= 0
        ) {
          tempColumn.filter = 'agSetColumnFilter';
        } else if (childColumn.field === 'checkbox') {
          // ignore checkbox header
          return;
          // tempColumn.sortable = false;
          // tempColumn.filter = false;
        } else {
          tempColumn.filter = 'agTextColumnFilter';
        }

        // Set a default width for column
        if (Object.prototype.hasOwnProperty.call(childColumn, 'width')) {
          tempColumn.width = childColumn.width;
        } else {
          tempColumn.width = 150;
        }

        // Finally push tempColumn
        childColumns.push(tempColumn);
      });

      columnDefs.push({
        headerName: columnGroup.headerName,
        groupId: columnGroup.groupId,
        marryChildren: true,
        children: childColumns,
      });
    });

    return columnDefs;
  }

  /**
   * Async function to get custom fields based on term
   *
   * @param {Object} params HTTP Post params object
   */
  async getCustomFieldValues(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/customfieldvalues`, params)
      .then((response) => response.data);
  }

  /**
   * Async function to get saved cloud storage options.
   *
   * @param {Object} params HTTP Post params object
   */
  async getCloudStorage(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/cloudstorage`, params)
      .then((response) => response.data);
  }

  /**
   * Default implementation for grabbing the default columns.
   */
  getDefaultColumns() {
    return this.defaultColumns;
  }
}

export default BaseHttp;
export const Base = new BaseHttp();
