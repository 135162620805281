var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "footer", attrs: { id: "main-panel-footer" } },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "copyright" }, [
          _c(
            "div",
            {
              staticClass:
                "rb-footer-logo d-flex justify-content-center align-items-center",
            },
            [
              _c(
                "el-tooltip",
                { attrs: { "open-delay": 2000, content: _vm.version } },
                [
                  _c(
                    "a",
                    {
                      staticClass: "text-reset",
                      attrs: {
                        href: "https://getreviewbox.com",
                        target: "_blank",
                        rel: "noopener",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "rb-logo-img",
                        attrs: {
                          src: require("@/assets/images/powered_by_logo.webp"),
                          alt: "app-logo",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }