import { dateRanges } from '@/utils';

export default {
  /**
   * Mutation to set global date range.
   *
   * @param {Object} state Global vuex state
   * @param {Object} dateRange date range object {startDate, endDate}
   */
  setDateRange(state, dateRange) {
    state.dateRange = { ...dateRange };
    sessionStorage.setItem('global/dateRange', JSON.stringify(state.dateRange));
  },
  /**
   * Removes and reset global date range.
   * Removes global date range from sessionStorage.
   *
   * @param {Object} state Global vuex state
   */
  removeDateRange(state) {
    state.dateRange = {
      startDate: dateRanges.options.yesterday[0],
      endDate: dateRanges.options.yesterday[1],
    };

    sessionStorage.removeItem('global/dateRange');
  },
  setShowHelp(state, value) {
    state.showHelp = value;
  },
  setDefaultHelpSearch(state, value) {
    state.defaultHelpSearch = value;
  },
  setShowDownloads(state, value) {
    state.showDownloads = value;
  }
};
