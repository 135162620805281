export default {
  /**
   * Mutation to set Chart's Height.
   *
   * @param {Object} state Reviews store data object
   * @param {Boolean} status Height of Charts in Pixels
   */
  setChartsHeight(state, status) {
    state.chartsHeight = status;
    localStorage.setItem('pricebox/chartsHeight', status);
  },
  /**
   * Mutation to set showCharts status.
   *
   * @param {Object} state Reviews store data object
   * @param {Boolean} status Flag for displaying review charts
   */
  setShowCharts(state, status) {
    state.priceboxShowCharts = status;
    localStorage.setItem('pricebox/showCharts', JSON.stringify(status));
  },
  /**
   * Mutation to reset showCarts flag to default of true.
   *
   * @param {Object} state Reviews store data object
   */
  resetShowCharts(state) {
    state.priceboxShowCharts = true;
    localStorage.removeItem('pricebox/showCharts');
  },
  /**
   * Mutation to reset the cache of selected rows for the line chart
   * @param {Object} state Pricebox store data object
   */
  resetSelectCache(state) {
    state.priceboxSelectCache = [];
    localStorage.removeItem('pricebox/selectCache');
  },
  /**
   * Mutation to reset the cache of selected rows for the line chart
   * @param {Object} state Pricebox store data object
   */
  setSelectCache(state, status) {
    state.priceboxSelectCache = status;
    localStorage.setItem('pricebox/selectCache', JSON.stringify(status));
  },
};
