var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "rb-truncated-text",
      class: { "cursor-help": _vm.isTextOverLimit },
      attrs: { "data-test": "truncate-text" },
    },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            placement: "top",
            "open-delay": 500,
            disabled: !_vm.isTextOverLimit,
            "data-test": "truncate-tooltip",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { "max-width": "250px" },
              attrs: { slot: "content", "data-test": "full-text" },
              slot: "content",
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          ),
          _c("span", { attrs: { "data-test": "display-text" } }, [
            _vm._v(" " + _vm._s(_vm.displayText) + " "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }