import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/downloads',
  component: DefaultLayout,
  meta: { requiresAuth: true, module: 'downloads' },
  children: [
    {
      path: '',
      name: 'Downloads',
      meta: { requiresAuth: true, module: 'downloads' },
      components: { default: () => import('src/pages/Downloads.vue') },
    },
  ],
};
