var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "side-drawer-container bg-white",
      class: { open: _vm.productInfo },
      style: { width: _vm.width + "px", right: _vm.right + "px" },
      attrs: { "body-classes": "px-0 pt-0 pb-5" },
    },
    [
      _c("side-drawer-toolbar", {
        attrs: {
          "is-loading": _vm.isLoading,
          "is-fullscreen": _vm.isFullscreen,
          "show-response-drawer": true,
          "show-fullscreen": false,
          "show-walkthrough": false,
          "close-tooltip": "Close side drawer (esc)",
        },
        on: {
          prev: function ($event) {
            return _vm.$emit("prev")
          },
          next: function ($event) {
            return _vm.$emit("next")
          },
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "side-drawer" },
        [
          _vm.isLoading
            ? _c(
                "card",
                {
                  staticClass:
                    "h-100 w-100 d-flex flex-column justify-content-center align-items-center",
                },
                [_c("loading-panel")],
                1
              )
            : _vm._e(),
          _vm.isLoadingFailed
            ? _c(
                "card",
                {
                  staticClass:
                    "h-100 w-100 d-flex flex-column justify-content-center text-center",
                },
                [
                  _c(
                    "h5",
                    { staticClass: "title text-uppercase text-danger" },
                    [
                      _vm._v(" Oops! "),
                      _c("br"),
                      _vm._v("Something went wrong. "),
                      _c("br"),
                      _vm._v("Please try again. "),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          !_vm.isLoading && !_vm.isLoadingFailed && _vm.productInfo
            ? _c("card", { staticClass: "h-100 rounded-0" }, [
                _c("div", { staticClass: "border-bottom pb-4 mb-4" }, [
                  _vm.productImage
                    ? _c("div", { staticClass: "text-center mb-3" }, [
                        _vm.productImage
                          ? _c("img", {
                              staticClass: "img-fluid",
                              staticStyle: { "max-height": "180px" },
                              attrs: {
                                src: _vm.productImage,
                                alt: "Product Image",
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("h5", [_vm._v(" " + _vm._s(_vm.productInfo.name) + " ")]),
                  _c(
                    "a",
                    {
                      staticClass:
                        "el-button d-flex align-items-center justify-content-center w-100",
                      attrs: { href: _vm.productUrl, target: "_blank" },
                    },
                    [
                      _vm._v(" Go to product "),
                      _c("external-link-icon", {
                        staticClass: "rb-icon-sm ml-1",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col-sm" }, [
                    _c("h5", [_vm._v(" Product Info: ")]),
                    _c("p", { staticClass: "text-capitalize mb-1" }, [
                      _c("small", [_vm._v(" Source: ")]),
                      _vm.productInfo && _vm.productInfo.source
                        ? _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(" " + _vm._s(_vm.productInfo.source) + " "),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "text-captilize mb-1" }, [
                      _c("small", [_vm._v(" Brand: ")]),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(_vm.productBrand) + " "),
                      ]),
                    ]),
                    _c("p", { staticClass: "text-capitalize mb-1" }, [
                      _c("small", [_vm._v(" Total Ratings: ")]),
                      _vm.productInfo
                        ? _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.productInfo.variation_reviews) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "text-capitalize mb-1" }, [
                      _c("small", [_vm._v(" Review Count: ")]),
                      _vm.productInfo
                        ? _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " + _vm._s(_vm.productInfo.num_reviews) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "text-capitalize mb-1" }, [
                      _c("small", [_vm._v(" Average Rating: ")]),
                      _vm.productInfo
                        ? _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " + _vm._s(_vm.productInfo.avg_reviews) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("p", { staticClass: "text-capitalize mb-1" }, [
                      _c("small", [_vm._v(" Question Count: ")]),
                      _vm.productInfo
                        ? _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              " " + _vm._s(_vm.productInfo.num_questions) + " "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm" },
                    [
                      _vm.reviewRatingPieChart
                        ? _c("pie-chart", {
                            attrs: {
                              "chart-data": _vm.reviewRatingPieChart.chartData,
                              "extra-options":
                                _vm.reviewRatingPieChart.extraOptions,
                              height: 150,
                              width: 50,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "pt-3 border-top" }, [
                  _c("h5", [_vm._v(" Historical Info: ")]),
                  _c("p", { staticClass: "mb-1" }, [
                    _c("small", [_vm._v(" Reviews this week: ")]),
                    _c(
                      "span",
                      { staticClass: "font-weight-bold" },
                      [
                        _vm._v(" " + _vm._s(_vm.tWeekReviews) + " "),
                        _vm.tWeekReviews < _vm.lWeekReviews
                          ? _c("arrow-down-icon", { staticClass: "decrease" })
                          : _vm.tWeekReviews > _vm.lWeekReviews
                          ? _c("arrow-up-icon", { staticClass: "increase" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("p", { staticClass: "mb-1" }, [
                    _c("small", [_vm._v(" Reviews last week: ")]),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.lWeekReviews) + " "),
                    ]),
                  ]),
                  _c("p", { staticClass: "mb-1" }, [
                    _c("small", [_vm._v(" Reviews this month: ")]),
                    _c(
                      "span",
                      { staticClass: "font-weight-bold" },
                      [
                        _vm._v(" " + _vm._s(_vm.tMonthReviews) + " "),
                        _vm.tMonthReviews < _vm.lMonthReviews
                          ? _c("arrow-down-icon", { staticClass: "decrease" })
                          : _vm.tMonthReviews > _vm.lMonthReviews
                          ? _c("arrow-up-icon", { staticClass: "increase" })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("p", { staticClass: "mb-1" }, [
                    _c("small", [_vm._v(" Reviews last month: ")]),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(_vm.lMonthReviews) + " "),
                    ]),
                  ]),
                ]),
                _vm.reviewsBarChart
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("bar-chart", {
                          staticStyle: { height: "80%" },
                          attrs: {
                            "chart-data": _vm.reviewsBarChart.chartData,
                            "extra-options": _vm.reviewsBarChart.extraOptions,
                            "gradient-colors":
                              _vm.reviewsBarChart.gradientColors,
                            "gradient-stops": _vm.reviewsBarChart.gradientStops,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }