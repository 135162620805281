/**
 * Used with the percentFormatter when filter on ag-grid
 * Some values are in decimals for percents, but we display them as full numbers:
 * e.g. 0.8 -> 80%
 *
 * However when a user filters on a value, they'll type in 80 and it needs to
 * filter on 0.8, hence this percentParser utils.
 *
 * It takes a whole number text input and converts it to a decimal percent.
 * e.g. "80" -> 0.8
 */
export default (text) => {
  if (text === null) {
    return null;
  }

  try {
    const float = parseFloat(text);

    if (Number.isNaN(float)) {
      return null;
    }

    return float / 100;
  } catch {
    return text;
  }
};
