var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rb-collapse-header" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("div", { staticClass: "col-md-6" }, [
          _vm.loading
            ? _c("div", {
                staticClass: "skeleton skeleton-title skeleton-title-lg",
                attrs: { "data-test": "collapse-header-loading" },
              })
            : _vm.title || _vm.$slots.title
            ? _c("div", { staticClass: "d-flex" }, [
                _c(
                  "h4",
                  {
                    staticClass: "rb-collapse-header-title",
                    class: {
                      "cursor-pointer": _vm.content || _vm.$slots.default,
                    },
                    attrs: { "data-test": "collapse-header-title" },
                    on: {
                      click: function ($event) {
                        return _vm.onShowContent(!_vm.showContent)
                      },
                    },
                  },
                  [
                    _vm._t("title", function () {
                      return [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    }),
                  ],
                  2
                ),
                _vm.content || _vm.$slots.default
                  ? _c(
                      "span",
                      {
                        staticClass: "text-muted ml-2",
                        class: {
                          "cursor-pointer": _vm.content || _vm.$slots.default,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onShowContent(!_vm.showContent)
                          },
                        },
                      },
                      [
                        _c(_vm.collapseIcon, {
                          tag: "component",
                          staticClass: "rb-icon",
                          class: [
                            _vm.collapseIconSize,
                            {
                              "rb-rotate":
                                _vm.showContent && _vm.allowRotateIcon,
                            },
                          ],
                          attrs: { title: _vm.collapseIconTitle },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm.placeholder
            ? _c("div", { staticClass: "d-flex" }, [
                _c(
                  "h4",
                  { staticClass: "rb-collapse-header-title text-muted" },
                  [_vm._v(" " + _vm._s(_vm.placeholder) + " ")]
                ),
              ])
            : _vm._e(),
        ]),
        !_vm.loading && (_vm.helpText || _vm.$slots.help)
          ? _vm._t("help", function () {
              return [
                _c("div", { staticClass: "col-md-6 d-flex" }, [
                  _c("div", { staticClass: "ml-auto" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "cursor-pointer rb-help-btn rb-text-help text-muted d-flex align-items-center",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("helpClicked")
                          },
                        },
                      },
                      [
                        _c("info-icon", { staticClass: "rb-icon mr-2" }),
                        _vm._v(" " + _vm._s(_vm.helpText) + " "),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            })
          : _vm._e(),
        _c("collapse-transition", [
          _vm.showContent
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _vm._t("default", function () {
                    return [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "p",
                          {
                            staticClass: "col-md-6 rb-collapse-header-content",
                            attrs: { "data-test": "collapse-header-content" },
                          },
                          [_vm._v(" " + _vm._s(_vm.content) + " ")]
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }