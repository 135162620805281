var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column align-items-center justify-content-center",
      class: _vm.loadingSquareClasses,
    },
    [
      _vm.message
        ? _c("h6", { staticClass: "text-success" }, [
            _vm._v(" " + _vm._s(_vm.message) + " "),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "loading-ring",
        },
        [_c("div"), _c("div"), _c("div"), _c("div")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }