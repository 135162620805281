import BaseHttp from './BaseHttp';

/**
 * Class to handle all pricebox related http requests
 */
class SearchboxProductsHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'searchbox';

    // Default reviewbox columns
    this.defaultColumns = [
      {
        headerName: 'Catalog',
        groupId: 'general',
        children: [
          {
            headerName: '',
            field: 'checkbox',
          },
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'ID',
            field: 'listing',
          },
          {
            headerName: 'Name',
            field: 'name',
          },
        ],
      },
      {
        headerName: 'Organic Searches',
        groupId: 'organic_searches',
        children: [
          {
            headerName: 'Best Rank',
            field: 'best_organic_rank',
          },
          {
            headerName: 'Best Term',
            field: 'best_organic_term',
          },
          {
            headerName: '1st Page Terms',
            field: 'organic_first_page_terms',
          },
          {
            headerName: 'Total Terms',
            field: 'organic_total_terms',
          },
        ],
      },
      {
        headerName: 'Sponsored Searches',
        groupId: 'sponsored_searches',
        children: [
          {
            headerName: 'Best Rank',
            field: 'best_sponsored_rank',
          },
          {
            headerName: 'Best Term',
            field: 'best_sponsored_term',
          },
          {
            headerName: '1st Page Terms',
            field: 'sponsored_first_page_terms',
          },
          {
            headerName: 'Total Terms',
            field: 'sponsored_total_terms',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'best_organic_rank',
      'organic_total_terms',
      'best_sponsored_rank',
      'sponsored_total_terms',
      'organic_first_page_terms',
      'sponsored_first_page_terms',
    ];
  }

  /**
   * Async function to fetch Reviewbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    return this.constructGrid(
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  async getListings(params = {}) {
    return this.getListingData(this.service, params);
  }

  /**
   * Async function to fetch pricebox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Searchbox' });
  }

  /**
   * Async function to fetch pricebox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: 'Searchproducts' });
  }

  /**
   * Async function to update custom field metadata for searchbox terms.
   *
   * @param {Object} params HTTP Post params object
   */
  async updateSearchProductsCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customfields`, params);
  }

  /**
   * Static method to construct ag-grid row data for searchboxProducts.
   *
   * @param {Array} listingsData Array of objects for searchbox products listings metadata
   * @param {Array} columnDefs Array of ag-grid column definitions
   */
  static constructRowData(listingsData, columnDefs) {
    const rowData = [];

    // Return empty array of row data if listingsData does not exist
    if (!listingsData || !listingsData.length) {
      return rowData;
    }

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    listingsData.forEach((listing) => {
      const row = {};

      columnTypes.forEach((column) => {
        // Handle default fields
        if (Object.prototype.hasOwnProperty.call(listing, column)) {
          row[column] = listing[column];
          // Handle custom fields
        } else if (
          Object.prototype.hasOwnProperty.call(listing, 'fields')
          && listing.fields !== null
          && Object.prototype.hasOwnProperty.call(listing.fields, 'fields')
          && Object.prototype.hasOwnProperty.call(listing.fields.fields, column)
        ) {
          row[column] = listing.fields.fields[column];
        } else {
          row[column] = '';
        }

        if (row[column] === null) {
          row[column] = '';
        }
      });

      row.producturl = listing.url;

      rowData.push(row);
    });

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default new SearchboxProductsHttp();
