export default {
  /**
   * Getter to return grid filter state for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service service of grid to return
   * @returns {Object} Returns filter state object
   */
  getFilterState: (state) => (service) => state.filterStates[service],
  /**
   * Getter to return grid state for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service service of grid to return
   * @returns {Object} Returns grid state object
   */
  getGridState: (state) => (service) => state.gridStates[service],
  /**
   * Getter to return a grid's custom fields for a service.
   *
   * @param {Object} state Grid module state
   * @param {Object} getters Grid module getters
   * @param {String} service service of grid to return
   * @returns {Object} Returns grid state object
   */
  getCustomFields: (state, getters) => (service) => {
    const gridState = getters.getGridState(service);

    return gridState.customFields || [];
  },
  /**
   * Getter to return the default columns for the various grid types.
   *
   * @param {Object} state Grid module state
   * @returns {Object} Returns column settings organized by service
   */
  getDefaultColumns: (state) => state.defaultColumns || null,
  /**
   * Getter to return grid loading state for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Boolean} Returns grid loading
   */
  getIsGridLoading: (state) => (service) => state.isGridLoading[service] || false,
  /**
   * Getter to return download options for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Array} Returns an array of download options
   */
  getDownloadOptions: (state) => (service) => state.downloadOptions[service] || [],
  /**
   * Getter to return ag-grid cell renderers for for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Object} Returns an object of ag-grid cell renderers
   */
  getColumnCellRenderers: (state) => (service) => state.columnCellRenderers[service] || {},
  /**
   * Getter to return ag-grid cell renderers for for a service.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Object} Returns an object of ag-grid cell editors
   */
  getColumnCellEditors: (state) => (service) => state.columnCellEditors[service] || {},
  /**
   * Getter to return topic options array.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Array} Returns an array of objects of topic options
   */
  getTopicOptions: (state) => (service) => state.columnCellEditors[service]
    .topic.cellEditorParams.values.sort() || [],
  /**
   * Getter to return status options array.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Array} Returns an array of objects of status options
   */
  getStatusOptions: (state) => (service) => state.columnCellEditors[service]
    .status.cellEditorParams.values || [],
  /**
   * Getter to return sentiment options array.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Array} Returns an array of objects of sentiment options
   */
  getSentimentOptions: (state) => (service) => state.columnCellEditors[service]
    .sentiment.cellEditorParams.values || [],
  /**
   * Getter to return customCol options array.
   *
   * @param {Object} state Grid module state
   * @param {String} service Service of grid to return
   * @returns {Array} Returns an array of objects of sentiment options
   */
  getCustomColOptions: (state) => (service) => state.columnCellEditors[service]
    .customCol.cellEditorParams.values.sort() || [],
  /**
   * Return the boolean flag for showing the Response Drawer
   */
  showResponseDrawer: (state) => state.showResponseDrawer,
};
