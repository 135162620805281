var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "downloads-filters d-flex flex-wrap justify-content-between mb-2",
        },
        [
          _c("div", { staticClass: "left-actions" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c(
                  "label",
                  {
                    staticClass: "rb-text-help",
                    attrs: { for: "downloads-search" },
                  },
                  [_vm._v(" Search downloads ")]
                ),
                _c(
                  "el-input",
                  {
                    ref: "downloads-search",
                    attrs: {
                      id: "downloads-search",
                      type: "search",
                      placeholder: "Find a download",
                      size: "small",
                      disabled:
                        !_vm.filteredDownloads || !_vm.filteredDownloads.length,
                    },
                    model: {
                      value: _vm.searchString,
                      callback: function ($$v) {
                        _vm.searchString = $$v
                      },
                      expression: "searchString",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "d-flex align-items-center text-dark",
                        attrs: { slot: "prepend" },
                        on: {
                          click: function ($event) {
                            return _vm.$refs["downloads-search"].focus()
                          },
                        },
                        slot: "prepend",
                      },
                      [_c("search-icon")],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "right-actions" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c(
                  "label",
                  {
                    staticClass: "rb-text-help d-flex align-items-center",
                    attrs: { for: "downloads-sort" },
                  },
                  [
                    _vm._v(" Sort by "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "Sort downloads on their creation date, from the most recent to oldest (newest) or oldest to most recent (oldest) order.",
                          "open-delay": 500,
                        },
                      },
                      [
                        _c("info-icon", {
                          staticClass: "text-muted ml-1 cursor-pointer",
                          attrs: { title: "" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    attrs: {
                      id: "downloads-sort",
                      size: "small",
                      disabled:
                        !_vm.filteredDownloads || !_vm.filteredDownloads.length,
                    },
                    model: {
                      value: _vm.sortOrder,
                      callback: function ($$v) {
                        _vm.sortOrder = $$v
                      },
                      expression: "sortOrder",
                    },
                  },
                  _vm._l(_vm.sortOptions, function (option, optionIndex) {
                    return _c("el-option", {
                      key: `${option}--${optionIndex}`,
                      attrs: { value: option },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      !_vm.filteredDownloads
        ? _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c("div", { staticClass: "skeleton skeleton-text w-25 mb-2" }),
              _vm._l([1, 2, 3, 4, 5], function (skel, sIdx) {
                return _c("div", {
                  key: `skeleton--${sIdx}`,
                  staticClass: "skeleton rounded mb-2",
                  staticStyle: { height: "125px" },
                })
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.filteredDownloads && !_vm.filteredDownloads.length
        ? _c(
            "el-alert",
            {
              staticClass: "align-items-start",
              attrs: { type: "info", closable: false, "show-icon": "" },
            },
            [
              _c(
                "div",
                [
                  _c("h6", { staticClass: "mb-0 font-weight-bold" }, [
                    _vm._v(" No Downloads Available "),
                  ]),
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      " Create a report or export to get started analyzing your data. "
                    ),
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: "/reports/create" } },
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "info", size: "medium" } },
                        [_vm._v(" Create a report ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.filteredDownloads && _vm.filteredDownloads.length
        ? [
            _c("ul", { staticClass: "list-group" }, [
              _vm.searchString &&
              _vm.searchedDownloads &&
              _vm.searchedDownloads.length === 0
                ? _c(
                    "li",
                    {
                      staticClass:
                        "text-reset rb-help-list-item rb-help-no-results list-group-item d-flex flex-column flex-lg-row border-0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                        },
                        [
                          _c("folder-search-outline-icon", {
                            staticClass: "rb-icon rb-icon-3x",
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "rb-list-content" },
                        [
                          _vm._m(0),
                          _c(
                            "p",
                            { staticClass: "rb-list-description text-muted" },
                            [
                              _vm._v(
                                " Please enter a new search term or clear your search. "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "medium", plain: "" },
                              on: {
                                click: function ($event) {
                                  _vm.searchString = null
                                },
                              },
                            },
                            [_vm._v(" Clear search ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "el-timeline",
              { staticClass: "pl-0", attrs: { reverse: _vm.reverse } },
              _vm._l(_vm.groupedDownloads, function (group, key) {
                return _c(
                  "el-timeline-item",
                  { key: key, attrs: { timestamp: key, placement: "top" } },
                  _vm._l(group, function (download) {
                    return _c(
                      "div",
                      { key: download.job, staticClass: "download-card card" },
                      [
                        _c("div", { staticClass: "card-body py-2 px-3" }, [
                          download.options && download.options.exportReportName
                            ? _c("h6", { staticClass: "card-title mb-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(download.options.exportReportName) +
                                    " "
                                ),
                              ])
                            : download.type
                            ? _c("h6", { staticClass: "card-title mb-1" }, [
                                _vm._v(" " + _vm._s(download.type) + " "),
                              ])
                            : _vm._e(),
                          download.exportDate
                            ? _c(
                                "h6",
                                { staticClass: "text-muted small mb-1" },
                                [
                                  _c("b", [_vm._v("Created")]),
                                  _vm._v(
                                    ": " + _vm._s(download.exportDate) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          download.reportRange
                            ? _c(
                                "h6",
                                { staticClass: "text-muted small mb-2" },
                                [
                                  _c("b", [_vm._v("Date Range")]),
                                  _vm._v(
                                    ": " + _vm._s(download.reportRange) + " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tags-container d-flex flex-wrap align-items-center mb-2",
                            },
                            [
                              download.created_by || download.user
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { size: "mini" },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: `${
                                              download.options.exportReportName
                                                ? "The " +
                                                  download.options
                                                    .exportReportName
                                                : download.type
                                                ? "The " + download.type
                                                : "This download"
                                            } was created by ${
                                              download.created_by ||
                                              download.user
                                            }.`,
                                            "open-delay": 500,
                                            disabled:
                                              !download.user &&
                                              !download.created_by,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("account-icon", {
                                                staticClass: "mr-1 rb-icon-sm",
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    download.created_by ||
                                                      download.user
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-tag",
                                {
                                  staticClass: "d-flex align-items-center mr-2",
                                  attrs: {
                                    type: download.is_advanced_report
                                      ? "info"
                                      : "warning",
                                    size: "mini",
                                  },
                                },
                                [
                                  download.is_advanced_report
                                    ? [
                                        _c("bar-chart-icon", {
                                          staticClass: "mr-1 rb-icon-sm",
                                        }),
                                        _vm._v(" Report "),
                                      ]
                                    : [
                                        _c("file-icon", {
                                          staticClass: "mr-1 rb-icon-sm",
                                        }),
                                        _vm._v(" Export "),
                                      ],
                                ],
                                2
                              ),
                              download.is_recurring
                                ? _c(
                                    "el-tag",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { type: "default", size: "mini" },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            content: `This ${
                                              download.is_advanced_report
                                                ? "report"
                                                : "export"
                                            } will be emailed on a ${
                                              download.options.exportRecurring
                                            } basis.`,
                                            disabled:
                                              !download.options.exportRecurring,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-items-center",
                                            },
                                            [
                                              _c("email-sync-icon", {
                                                staticClass: "mr-1 rb-icon-sm",
                                              }),
                                              _vm._v(" Recurring "),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "card-footer py-1" },
                          [
                            download &&
                            download.status &&
                            download.status.type === "danger"
                              ? _c(
                                  "el-alert",
                                  {
                                    attrs: {
                                      type: "warning",
                                      title: "Download Failed",
                                      "show-icon": "",
                                      closable: false,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " Your download files could not be generated. Please try downloading the " +
                                        _vm._s(
                                          download.is_advanced_report
                                            ? "report"
                                            : "export"
                                        ) +
                                        " again in a moment. "
                                    ),
                                  ]
                                )
                              : download &&
                                download.status &&
                                download.status.type === "warning"
                              ? _c(
                                  "el-alert",
                                  {
                                    staticClass: "alert-download-pending",
                                    attrs: { type: "info", closable: false },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "icon-container" },
                                          [_c("timer-sand-icon")],
                                          1
                                        ),
                                        _c("div", { staticClass: "px-2" }, [
                                          _c(
                                            "h6",
                                            { staticClass: "alert-heading" },
                                            [
                                              _vm._v(
                                                " Generating download files "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "small text-muted" },
                                    [_vm._v(" Download files: ")]
                                  ),
                                  download &&
                                  download.status &&
                                  download.status.type === "success" &&
                                  !download.links
                                    ? _c(
                                        "p",
                                        { staticClass: "mb-0 text-muted" },
                                        [
                                          _vm._v(
                                            " No results could be found for this " +
                                              _vm._s(
                                                download.is_advanced_report
                                                  ? "report"
                                                  : "export"
                                              ) +
                                              ". "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center flex-wrap",
                                        },
                                        _vm._l(
                                          download.links,
                                          function (file, linkIndex) {
                                            return _c(
                                              "a",
                                              {
                                                key: linkIndex,
                                                staticClass: "mr-2",
                                                attrs: {
                                                  target: "_blank",
                                                  href: `${_vm.downloadUrl}/${file.link}`,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.fileIcon(file.link),
                                                      {
                                                        tag: "component",
                                                        staticClass:
                                                          "mr-1 rb-icon-sm",
                                                      }
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.parseFileName(
                                                            file.name
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                ],
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                )
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", [_c("span", [_vm._v(" No Downloads Found ")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }