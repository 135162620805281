import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/searchbox',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    service: 'searchbox',
    module: 'searchbox',
  },
  children: [
    {
      path: '',
      name: 'Searchbox',
      redirect: '/searchbox/products',
      meta: {
        requiresAuth: true,
        service: 'searchbox',
        module: 'searchbox',
      },
      components: {
        default: () => import('src/pages/Searchbox/SearchboxLayout.vue'),
      },
      children: [
        {
          path: 'products',
          name: 'Searchbox Products',
          label: 'Products',
          meta: {
            requiresAuth: true,
            service: 'searchbox',
            module: 'searchbox',
          },
          components: {
            default: () => import('src/pages/Searchbox/Products/Products.vue'),
          },
        },
        {
          path: 'terms',
          name: 'Searchbox Terms',
          label: 'Terms',
          meta: {
            requiresAuth: true,
            service: 'searchbox',
            module: 'searchbox',
          },
          components: {
            default: () => import('src/pages/Searchbox/Terms/Terms.vue'),
          },
        },
        {
          path: 'terms/:slug',
          name: 'Searchbox Term',
          label: 'Term',
          meta: {
            hideHelp: true,
            requiresAuth: true,
            service: 'searchbox',
            module: 'searchbox',
          },
          components: {
            default: () => import('src/pages/Searchbox/Terms/TermVisualizations.vue'),
          },
        },
      ],
    },
  ],
};
