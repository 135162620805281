import Vue from 'vue';

export default {
  /**
   * Mutation to set loading state.
   *
   * @param {Object} state Account module state
   * @param {Boolean} loading to determine loading state
   */
  setAccountLoading(state, loading) {
    state.accountIsLoading = loading;
  },

  /**
   * Mutation to reset loading state.
   *
   * @param {Object} state Account module state
   */
  resetAccountLoading(state) {
    state.accountIsLoading = false;
  },

  /**
   * Mutation to set user state and localStorage.
   *
   * @param {Object} state Account module state
   * @param {Object} user Object containing user information
   */
  setUser(state, user) {
    Vue.set(state, 'user', user);
    localStorage.setItem('user', JSON.stringify(user));
  },

  /**
   * Mutation to remove user state and localStorage.
   *
   * @param {Object} state Account module state
   */
  removeUser(state) {
    state.user = null;
    localStorage.removeItem('user');
  },

  /**
   * Mutation to set user profile.
   *
   * @param {Object} state Account module state
   * @param {Object} profile Object containing account information
   */
  setProfile(state, profile) {
    state.profile = profile;
    localStorage.setItem('user-profile', JSON.stringify(profile));
  },
  updateProfile(state, profile) {
    state.profile = {
      ...state.profile,
      ...profile,
    };

    localStorage.setItem('user-profile', JSON.stringify(profile));
  },

  /**
   * Mutation to set user profile filters.
   *
   * @param {Object} state Account module state
   * @param {Object} profile Object containing default profile filters.
   */
  setProfileFilters(state, payload) {
    for (let i = 0; i < payload.length; i += 1) {
      const filters = {
        service: payload[i].service,
        gridfilter: payload[i].filters,
        gridsort: payload[i].sort,
      };
      state.profileFilters[payload[i].service.toLowerCase()] = filters;
    }
    localStorage.setItem('user-profile-filter', JSON.stringify(state.profileFilters));
  },

  /**
   * Mutation to remove profile state.
   *
   * @param {Object} state Account module state
   */
  removeProfile(state) {
    state.profile = null;
    localStorage.removeItem('user-profile');
  },

  /**
   * Mutation to remove profile filter state.
   */
  removeProfileFilter(state) {
    state.profileFilters = {};
    localStorage.removeItem('user-profile-filter');
  },

  /**
   * Mutation to set plans.
   *
   * @param {Object} state Account module state
   * @param {Object} plans Object containing plan information
   */
  setPlans(state, plans) {
    state.plans = plans;
    localStorage.setItem('user-plans', JSON.stringify(plans));
  },

  /**
   * Mutation to remove plans.
   *
   * @param {Object} state Account module state
   */
  removePlans(state) {
    state.plans = null;
    localStorage.removeItem('user-plans');
  },

  /**
   * Mutation to set user plan summary.
   *
   * @param {Object} state Account module state
   * @param {Object} userPlanSummary Object containing plan information
   */
  setUserPlanSummary(state, userPlanSummary) {
    state.userPlanSummary = userPlanSummary;
    localStorage.setItem('user-plan-summary', JSON.stringify(userPlanSummary));
  },

  /**
   * Mutation to remove plans.
   *
   * @param {Object} state Account module state
   */
  removeUserPlanSummary(state) {
    state.userPlanSummary = null;
    localStorage.removeItem('user-plan-summary');
  },

  /**
   * Mutation to set ad accounts.
   *
   * @param {Object} state Account module state
   * @param {Object} adAccounts Object containing ad accounts
   */
  setAdAccounts(state, adAccounts) {
    state.adAccounts = adAccounts;
    localStorage.setItem('user-ad-accounts', JSON.stringify(adAccounts));
  },

  /**
   * Mutation to remove ad accounts.
   *
   * @param {Object} state Account module state
   */
  removeAdAccounts(state) {
    state.adAccounts = null;
    localStorage.removeItem('user-ad-accounts');
  },

  /**
   * Mutation to set source list per service.
   *
   * @param {Object} state Account module state
   * @param {Object} serviceSourceList Object of arrays with sources per service
   */
  setServiceSourceList(state, serviceSourceList) {
    state.serviceSourceList = serviceSourceList;
    localStorage.setItem('service-source-list', JSON.stringify(serviceSourceList));
  },

  /**
   * Mutation to remove ad accounts.
   *
   * @param {Object} state Account module state
   */
  removeServiceSourceList(state) {
    state.serviceSourceList = null;
    localStorage.removeItem('service-source-list');
  },

  /**
   * Mutation to set response templates.
   *
   * @param {Object} state Account module state
   * @param {Array} templates Array of response templates
   */
  setResponseTemplates(state, templates = []) {
    let tempTemplates = templates;
    if (tempTemplates === null) {
      tempTemplates = [];
    }
    state.responseTemplates = tempTemplates;
  },

  /**
   * Mutation to add a response template.
   *
   * @param {Object} state Account module state
   * @param {Object} template Object of new template info
   */
  addResponseTemplate(state, template = {}) {
    state.responseTemplates.push(template);
  },

  /**
   * Mutation to update response template.
   *
   * @param {Object} state Account module state
   * @param {Object} template Object of new template info
   */
  updateResponseTemplate(state, template = {}) {
    const templateId = template.template_id;
    const index = state.responseTemplates.findIndex((item) => item.template_id === templateId);

    Vue.set(state.responseTemplates, index, template);
  },

  /**
   * Mutation to delete response template.
   *
   * @param {Object} state Account module state
   * @param {Object} template Object of new template info
   */
  deleteResponseTemplate(state, template = {}) {
    const templateId = template.template_id;
    state.responseTemplates = state.responseTemplates.filter((t) => t.template_id !== templateId);
  },

  /**
   * Mutation to remove all response template.
   *
   * @param {Object} state Account module state
   */
  removeAllResponseTemplates(state) {
    state.responseTemplates = [];
  },
};
