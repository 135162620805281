var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: {
        display: "inline-block",
        width: "400px",
        "white-space": "nowrap",
        overflow: "hidden",
        "text-overflow": "ellipsis",
      },
    },
    [_vm._v(" " + _vm._s(_vm.value) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }