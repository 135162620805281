var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex align-items-end" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column" },
      [
        _c(
          "label",
          {
            staticClass: "rb-text-help",
            attrs: { for: "grid-custom-filters-input" },
          },
          [
            _vm._v(" Custom filters "),
            _c(
              "el-tooltip",
              { attrs: { "open-delay": 500 } },
              [
                _c(
                  "div",
                  {
                    staticStyle: { "max-width": "200px" },
                    attrs: { slot: "content" },
                    slot: "content",
                  },
                  [
                    _c("p", { staticClass: "compact-tooltips text-white" }, [
                      _c("b", [_vm._v("Team Filters")]),
                      _vm._v(
                        " are shared across your organization's account. Use Team Filters to share with your teammates. "
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "compact-tooltips text-white mb-0" },
                      [
                        _c("b", [_vm._v("User Filters")]),
                        _vm._v(
                          " are filters tied to your account. Save User Filters for your personal use. "
                        ),
                      ]
                    ),
                  ]
                ),
                _c("info-icon", {
                  staticClass: "cursor-pointer text-muted rb-icon-sm",
                  attrs: { title: "" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-select",
          {
            staticClass: "el-select--light",
            class: { active: !!_vm.selectedFilterAction },
            attrs: {
              id: "grid-custom-filters-input",
              value: _vm.selectedFilterAction,
              name: "grid-custom-filters-input",
              placeholder: "Select custom filters",
              size: "small",
              filterable: "",
              disabled: _vm.disabled,
            },
            on: { change: _vm.onSelectChange },
          },
          _vm._l(_vm.filterOptions, function (group, groupIndex) {
            return _c(
              "el-option-group",
              { key: groupIndex, attrs: { label: group.label } },
              _vm._l(group.options, function (option, optionIndex) {
                return _c(
                  "el-option",
                  {
                    key: optionIndex,
                    staticClass: "d-flex align-items-center",
                    attrs: { value: option.value, disabled: option.disabled },
                  },
                  [
                    option.icon
                      ? _c(option.icon, {
                          tag: "component",
                          staticClass: "rb-icon-sm mr-2",
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(option.label) + " "),
                  ],
                  1
                )
              }),
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }