import { mapGetters, mapActions } from 'vuex';
import { BaseHttp } from '@/services/api';

// Import Components
import { SaveFiltersModal } from '@/components';

import EventBus from '@/services/event-bus/EventBus';

/**
 * GridFiltersMixin keeps shared methods, etc. in one location
 * to keep things DRY. Also, not much changes between a
 * service's grid filters, so good to keep it centralized.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  components: { SaveFiltersModal },
  data() {
    return {
      showSaveFiltersModal: {
        show: false,
        isSaveFilter: true,
      },
      isGridFilterLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      gridState: 'grids/getGridState',
    }),
  },
  methods: {
    ...mapActions('grids', [
      'saveGridFilters',
      'clearGridFilters',
      'addGridFilters',
      'removeGridFilters',
    ]),
    ...mapActions('account', ['fetchProfileFilters']),
    /**
     * Method to dynamically construct any number of filters that will
     * be applied to ag-grid
     *
     * @param {Array} filters List of filters to construct
     */
    constructGridFilters(filters = []) {
      if (filters.length < 1) {
        return null;
      }

      /**
       * Filter map object that is a helper object
       * for applying multiple ag-grid filters
       *
       * @todo We'll want to refactor this and make it more flexible
       * for generating ag-grid filters.
       */
      const filterMap = {
        // stars is used in reviews
        stars: ({ filter, type }) => ({
          type,
          filter,
          filterType: 'number',
          filterTo: null,
        }),
        // verified is used in reviews
        verified: ({ values, filterType }) => ({ values, filterType }),
        // compliance is just a text filter, so re-use that function
        // compliance is used in copybox
        compliance: function compliance(filterPayload) {
          return this.textFilter(filterPayload);
        },
        // guidance is just a text filter, so re-use that function
        // guidance is used in copybox
        guideline: function guideline(filterPayload) {
          return this.textFilter(filterPayload);
        },
        textFilter({ filter, type = 'equals' }) {
          return {
            filter,
            type,
            filterType: 'text',
          };
        },
      };

      const gridFilters = {};

      // Go through array of filters and construct all of them
      filters.forEach(({ filterName, filterPayload }) => {
        gridFilters[filterName] = filterMap[filterName](filterPayload);
      });

      return gridFilters;
    },
    /**
     * Method to handle saving grid filters.
     * This will trigger HTTP request to update filters.
     *
     * @param {Object} value A string specifying which download and rows selected
     */
    onSaveGridFilters(filters, sort) {
      let doSave = false;
      if (sort && sort.length > 0) {
        doSave = true;
      } else if (filters && Object.keys(filters).length > 0) {
        doSave = true;
      }

      if (!doSave) {
        this.handleWarning(new Error('There are no filters applied.'));
      } else {
        const [savedFilters] = [this.gridState(this.service).savedFilters];

        this.showSaveFiltersModal = {
          show: true,
          isSaveFilter: true,
          gridFilter: filters,
          gridSort: sort,
          filters: savedFilters,
        };
      }
    },
    /**
     * Method to cancel saving filters.
     */
    onCancelSaveFilters() {
      this.showSaveFiltersModal = {
        show: false,
        isSaveFilter: true,
      };
    },
    /**
     * Method to detect and store when filters/sort have changed.
     */
    onFiltersChanged(filterObj) {
      this.deselectFilteredNodes();
      this.saveGridFilters({
        service: this.service,
        filters: filterObj.filters,
        sort: filterObj.sort,
      });
    },
    /**
     * Method to de-select any filtered rows (if multi-select is on). This is a stub
     * and should be over-written by specific grids.
     */
    deselectFilteredNodes() {
    },
    /**
     * Method to clear filters/sort.
     */
    onFiltersCleared() {
      this.clearGridFilters({
        service: this.service,
      });
    },
    /**
     * Method to update the column preferences.
     */
    onColumnChanged(service, columnObj) {
      // Grab all the columns regardless if they are hidden.
      const allColumns = columnObj.columnApi.getAllGridColumns();

      // column definitions we will save
      const columnGroups = {};
      const groupNames = {};
      const columnInfo = [];

      allColumns.forEach((childcol) => {
        if (childcol.colId === 'agGridViewData') {
          return;
        }

        const groupID = childcol.originalParent.groupId;
        const groupName = childcol.originalParent.colGroupDef.headerName;
        groupNames[groupID] = groupName;

        if (!Object.prototype.hasOwnProperty.call(columnGroups, groupID)) {
          columnGroups[groupID] = [];
        }

        const childColPinned = childcol.pinned;
        const childColWidth = childcol.actualWidth;
        const childColField = childcol.colDef.field;
        const childColName = columnObj.columnApi.getDisplayNameForColumn(childcol);

        // add the column to the list of children columns
        columnGroups[groupID].push({
          headerName: childColName,
          field: childColField,
          hide: !(childcol.visible),
          width: childColWidth,
          pinned: childColPinned,
        });
      });

      Object.keys(columnGroups).forEach((groupID) => {
        const groupName = groupNames[groupID];

        const childInfo = columnGroups[groupID];
        columnInfo.push({
          headerName: groupName,
          groupId: groupID,
          children: childInfo,
        });
      });

      // Now pass the column child info to the backend.
      BaseHttp.updateSavedGridPreferences({
        grid_type: service,
        columns: JSON.stringify(columnInfo),
      }).catch((error) => this.handleError(error));
    },
    /**
     * Method to handle confirming saving a filter.
     *
     * @todo We need to fix passing service name of "Reviewbox".
     * These are used for http requests to save
     * and delete a filter. They do not match to other service names,
     * e.g.) "reviewbox". Be nice to have everything consistent on
     * one service name.
     */
    onConfirmSaveGridFilter(service, filterObj) {
      const {
        name,
        gridfilter,
        filterType,
        gridsort,
      } = filterObj;

      const payload = {
        service,
        name,
        filterType,
        filters: JSON.stringify(gridfilter),
        sort: JSON.stringify(gridsort),
      };

      this.isGridFilterLoading = true;
      BaseHttp.postGridFilters(payload)
        .then((response) => {
          if (response.status === 200) {
            if (filterType === 'profileFilter') {
              // If it is a profile filter, get profile filters
              return this.fetchProfileFilters();
            }

            // Then add filter to state
            return this.addGridFilters({
              service: service.toLowerCase(),
              name,
              filterType,
              filters: gridfilter,
              sort: gridsort,
            })
              .then(() => {
                // Emit event for saving a filter
                EventBus.$emit('filter-saved', name);
              });
          }

          return true;
        })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Filter Saved',
            message: `The ${name} filter was successfully saved. You can apply this filter from the custom filters dropdown.`,
            position: 'bottom-right',
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.onCancelSaveFilters();
          this.isGridFilterLoading = false;
        });
    },
    /**
     * Method to handle deleting grid filters.
     * This will trigger HTTP request to update filters.
     */
    onDeleteGridFilters() {
      const [savedFilters] = [this.gridState(this.service).savedFilters];

      if (savedFilters && savedFilters.length < 1) {
        this.handleWarning(new Error('There are no saved filters to delete.'));
      } else {
        this.showSaveFiltersModal = {
          show: true,
          isSaveFilter: false,
          filters: savedFilters,
        };
      }
    },
    /**
     * Method to handle confirming deleting a filter.
     *
     * @todo We need to fix passing service name of "Reviewbox".
     * These are used for http requests to save
     * and delete a filter. They do not match to other service names,
     * e.g.) "reviewbox". Be nice to have everything consistent on
     * one service name.
     */
    onConfirmDeleteGridFilter(service, filter) {
      const { name, type } = filter;
      const payload = {
        service,
        name,
        type,
      };

      this.isGridFilterLoading = true;
      BaseHttp.deleteGridFilters(payload)
        .then((response) => {
          if (response.status === 200) {
            // Then add filter to state
            this.removeGridFilters({
              ...payload,
              service: service.toLowerCase(),
            });

            this.$notify({
              type: 'success',
              title: 'Filter Deleted',
              message: `The ${name} filter was successfully removed.`,
              position: 'bottom-right',
            });

            // Emit event for deleting a filter
            EventBus.$emit('filter-deleted', name);
          }
        })
        .catch((error) => this.handleError(error))
        .finally(() => {
          this.onCancelSaveFilters();
          this.isGridFilterLoading = false;
        });
    },
  },
};
