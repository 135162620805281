import Rollbar from 'rollbar';

export default {
  install(Vue) {
    // Set the Rollbar instance in the Vue prototype
    // before creating the first Vue instance.
    // This ensures it is available in the same way for every
    // instance in your app.
    // @see https://docs.rollbar.com/docs/vue-js
    Vue.prototype.$rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_ROLLBAR,
      captureUncaught: true,
      captureUnhandledRejections: true,
      // Set the environment to one of three: "development", "staging", "production"
      // @see https://docs.rollbar.com/docs/rollbarjs-configuration-reference#payload-1
      payload: {
        environment: process.env.VUE_APP_NODE_ENV
      },
      // Only allow rollbar errors to come from our website as a source
      // This will ignore 3rd-party errors
      hostSafeList: [process.env.VUE_APP_BASE_URL],
      // Do not send rollbar notifications if it's dev environment or a debug level
      checkIgnore(isUncaught, args, payload) {
        return payload && (payload.environment === 'development' || payload.level === 'debug');
      },
      ignoredMessages: [
        'Network Error',
        'ResizeObserver loop limit exceeded',
        'Request failed with status code 502',
        /^NavigationDuplicated/,
        /Navigation cancelled/,
      ]
    });

    // If you have already set up a global error handler,
    // just add `vm.$rollbar.error(err)` to the top of it.
    // If not, this simple example will preserve the app’s existing
    // behavior while also reporting uncaught errors to Rollbar.
    Vue.config.errorHandler = (err, vm) => {
      vm.$rollbar.error(err);
      throw err; // rethrow
    };
  },
};
