var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", { staticClass: "mb-0" }, [
        _vm._v(
          " " + _vm._s(_vm.label) + " " + _vm._s(_vm.required ? "*" : "") + " "
        ),
        _c("small", [
          _vm._v(
            " (" +
              _vm._s(_vm.numberRowsSelected) +
              " of " +
              _vm._s(_vm.selectedMaxLength) +
              " selected) "
          ),
        ]),
      ]),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham",
        class: _vm.gridClasses,
        staticStyle: { height: "300px", "margin-bottom": "1em" },
        attrs: { name: _vm.name, "grid-options": _vm.gridOptions },
      }),
      _vm.error
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }