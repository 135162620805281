var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.label
        ? _c("label", { staticClass: "form-check-label" }, [
            _vm._v(
              " " +
                _vm._s(_vm.label) +
                " " +
                _vm._s(_vm.required ? "*" : "") +
                " "
            ),
          ])
        : _vm._e(),
      _vm._l(_vm.options, function (option, index) {
        return _c("div", { key: index, staticClass: "form-check" }, [
          _c(
            "label",
            { staticClass: "form-check-label" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", disabled: _vm.disabled },
                domProps: {
                  value: option.value,
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, option.value) > -1
                    : _vm.model,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.model,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = option.value,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.model = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.model = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.model = $$c
                    }
                  },
                },
              }),
              _vm._v(" " + _vm._s(option.name) + " "),
              _c("span", { staticClass: "form-check-sign" }),
              _vm._t("default", function () {
                return [_vm.inline ? _c("span", [_vm._v(" ")]) : _vm._e()]
              }),
            ],
            2
          ),
        ])
      }),
      _vm.error
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }