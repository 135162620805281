var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass:
          "col-12 d-flex align-items-end justify-content-between flex-wrap",
      },
      [
        _c(
          "div",
          { staticClass: "left-filter-actions d-flex align-items-end mb-3" },
          [
            _vm._t("left-filter-actions", function () {
              return [
                _vm.enableDateRange
                  ? _c("date-range-input", {
                      ref: "gridDateRange",
                      staticClass: "grid-date-range mr-2 mb-0",
                      attrs: {
                        label: "Date range",
                        value: _vm.gridDateRange,
                        disabled: _vm.isDateLoading,
                      },
                      on: { "date-range-changed": _vm.onDateRangeChanged },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label-tooltip",
                            fn: function () {
                              return [
                                _vm._t("date-range-label-tooltip", function () {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      { attrs: { "open-delay": 500 } },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "max-width": "200px",
                                            },
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                " The default date range is "
                                              ),
                                              _c("strong", [
                                                _vm._v("Last 7 Days"),
                                              ]),
                                              _vm._v(". "),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                " The start of a week is "
                                              ),
                                              _c("strong", [_vm._v("Monday")]),
                                              _vm._v(
                                                '. Selecting a date range of "week" would be Monday to Sunday. '
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("info-icon", {
                                          staticClass:
                                            "cursor-pointer text-muted rb-icon-sm",
                                          attrs: { title: "" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  : _vm._e(),
                _vm.enableFilterActions
                  ? _c("grid-filters-input", {
                      ref: "grid-custom-filters",
                      attrs: {
                        "saved-filters": _vm.savedFilters,
                        disabled: _vm.isGridLoading,
                        "is-filtered": _vm.isFiltered,
                      },
                      on: {
                        "apply-filter": _vm.onApplyFilter,
                        "save-filters": _vm.onSaveFilters,
                        "clear-filters": _vm.onClearFilters,
                        "delete-filters": _vm.onDeleteFilters,
                      },
                    })
                  : _vm._e(),
                _vm.isFiltered
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-clear-filters bg-transparent ml-2",
                        attrs: {
                          type: "plain",
                          size: "small",
                          disabled: !_vm.isFiltered || _vm.isGridLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onClearFilters()
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c("cancel-icon", {
                              staticClass: "rb-icon-xs text-danger mr-1",
                            }),
                            _vm._v(" Clear filters "),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "left-filter-actions d-flex align-items-end mb-3" },
          [
            _vm._t("right-filter-actions", function () {
              return [
                _vm._t("grid-toolbar"),
                _vm.downloadOptions
                  ? _c("download-report-input", {
                      staticClass: "ml-2",
                      attrs: {
                        options: _vm.downloadOptions,
                        disabled: _vm.isGridLoading,
                      },
                      on: { "select-download": _vm.onSelectDownload },
                    })
                  : _vm._e(),
                _vm.chartToggler
                  ? _c(
                      "el-checkbox-group",
                      {
                        staticClass: "ml-2",
                        attrs: { size: "small", disabled: _vm.isGridLoading },
                        model: {
                          value: _vm.showCharts,
                          callback: function ($$v) {
                            _vm.showCharts = $$v
                          },
                          expression: "showCharts",
                        },
                      },
                      [
                        _c("el-checkbox", {
                          attrs: { label: "Charts", border: "" },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("ag-grid-vue", {
          staticClass: "ag-theme-balham",
          style: _vm.gridHeight + _vm.gridTransition,
          attrs: {
            id: "ag-grid-vue",
            "column-defs": _vm.columnDefs,
            "default-col-def": _vm.defaultColumnDef,
            "row-data": _vm.rowData,
            "grid-options": _vm.gridOptions,
            components: _vm.frameworkComponents,
            "suppress-row-click-selection": _vm.suppressRowClickSelection,
            "row-selection": _vm.agGridRowSelection,
            "suppress-drag-leave-hides-columns": true,
            "master-detail": _vm.masterDetail,
            "get-main-menu-items": _vm.getMainMenuItems,
            "loading-overlay-component": "loadingOverlayComponent",
            "no-rows-overlay-component": "noRowsOverlayComponent",
            "multi-sort-key": "shift",
          },
          on: {
            "grid-ready": _vm.onGridReady,
            "selection-changed": _vm.onSelectionChanged,
            "model-updated": _vm.onGridUpdate,
            "row-selected": _vm.onRowSelected,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }