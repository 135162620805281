var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "rb-help-drawer",
      attrs: {
        title: "Support and Feedback",
        size: "45%",
        visible: _vm.showHelp,
      },
      on: { close: _vm.onHelpDrawerClosed },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("h4", [_vm._v(" Support and Feedback ")]),
              _c("p", [
                _vm._v(
                  " Whether you have questions about a product, wish to talk to support, or have feedback for us, we’ve got you covered! "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "ul",
        { staticClass: "list-group" },
        [
          _c(
            "li",
            {
              staticClass:
                "text-reset rb-help-search border-top border-bottom list-group-item d-flex flex-column flex-lg-row",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                },
                [_c("search-icon", { staticClass: "rb-icon rb-icon-3x" })],
                1
              ),
              _c("div", { staticClass: "rb-list-content" }, [
                _c("h6", { staticClass: "rb-list-title" }, [
                  _vm._v(" Search Our Help Center "),
                ]),
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("el-input", {
                      ref: "search",
                      attrs: {
                        placeholder: "Type your question here...",
                        clearable: "",
                      },
                      on: { clear: _vm.onClearSearch },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  { staticClass: "rb-list-description text-muted mb-0" },
                  [
                    _vm._v(
                      " Troubleshoot common questions with articles from Reviewbox's Help Center. "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          !_vm.search
            ? _vm._l(_vm.links, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass:
                      "text-reset rb-help-list-item list-group-item d-flex flex-column flex-lg-row",
                    attrs: { href: item.link, target: "_blank", tag: "li" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                      },
                      [
                        _c(item.icon, {
                          tag: "component",
                          staticClass: "rb-icon rb-icon-3x",
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "rb-list-content" }, [
                      _c("h6", { staticClass: "rb-list-title" }, [
                        _c("span", [_vm._v(" " + _vm._s(item.title) + " ")]),
                        _c(
                          "sup",
                          [
                            _c("external-link-icon", {
                              staticClass:
                                "rb-external-link text-muted rb-icon-xs",
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "p",
                        { staticClass: "rb-list-description text-muted mb-0" },
                        [_vm._v(" " + _vm._s(item.description) + " ")]
                      ),
                    ]),
                  ]
                )
              })
            : _vm.isLoading
            ? _c(
                "li",
                {
                  staticClass:
                    "text-reset rb-help-list-item rb-help-no-results list-group-item d-flex flex-column flex-lg-row align-items-center",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                    },
                    [
                      _c("timer-sand-icon", {
                        staticClass: "rb-icon rb-icon-3x rb-icon-spin",
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "rb-list-content" }, [
                    _c("h6", { staticClass: "mb-0" }, [
                      _vm._v(" Searching... "),
                    ]),
                  ]),
                ]
              )
            : _vm.results && _vm.results.length
            ? [
                _vm._l(_vm.results, function (result, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass:
                        "rb-help-list-item rb-help-search-result list-group-item d-flex flex-column flex-lg-row",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                        },
                        [
                          _c(_vm.getIcon(result.category), {
                            tag: "component",
                            staticClass: "rb-icon-3x",
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "rb-list-content" }, [
                        result.title
                          ? _c("h6", {
                              staticClass: "rb-list-title",
                              domProps: { innerHTML: _vm._s(result.title) },
                            })
                          : _vm._e(),
                        result.description
                          ? _c("p", {
                              staticClass: "rb-list-description text-muted",
                              domProps: {
                                innerHTML: _vm._s(result.description),
                              },
                            })
                          : _vm._e(),
                        _c(
                          "a",
                          { attrs: { href: result.url, target: "_blank" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getLinkText(result.category)) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                _vm.results && _vm.results.length < _vm.total
                  ? _c("li", { staticClass: "p-3" }, [
                      _vm.results.length < 50 && !_vm.loadMoreFailed
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-block btn-primary",
                              attrs: { disabled: _vm.isLoadingMore },
                              on: { click: _vm.onLoadMore },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isLoadingMore
                                      ? "Loading..."
                                      : "Load more"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn-block btn-primary",
                              attrs: {
                                href: `https://helpcenter.getreviewbox.com/kb-search-results?term=${_vm.search}`,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" Find more in our Help Center ")]
                          ),
                    ])
                  : _vm._e(),
              ]
            : _vm.hasNoResults
            ? _c(
                "li",
                {
                  staticClass:
                    "text-reset rb-help-list-item rb-help-no-results list-group-item d-flex flex-column flex-lg-row",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "rb-list-icon mr-4 d-flex justify-content-center align-items-center",
                    },
                    [
                      _c("folder-outline-icon", {
                        staticClass: "rb-icon rb-icon-3x",
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "rb-list-content" }, [
                    _c("h6", [_c("span", [_vm._v(" No Results ")])]),
                    _c("p", { staticClass: "rb-list-description text-muted" }, [
                      _vm._v(
                        " We could not find any articles related to your search. Please try another search term, or go directly to the Help Center to view all our help articles. "
                      ),
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://helpcenter.getreviewbox.com",
                          target: "_blank",
                        },
                      },
                      [_vm._v(" Visit our help center ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }