import BaseHttp from './BaseHttp';

/**
 * Class to handle all sellers related http requests
 */
class SellersBaseHttp extends BaseHttp {
  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      sellerData,
      savedGridFiltersData,
    ] = await Promise.all([
      this.getSellerInfo(params.sellers || {}),
      this.getSavedFilters(),
    ]);

    const { data, grid_settings, columns } = sellerData;

    // Get user custom fields from listing data
    let newCustomFields = [];

    if (columns && columns.length) {
      newCustomFields = columns.filter((c) => c.is_custom).map((c) => c.name);
    }

    return this.constructGrid(
      data,
      grid_settings,
      savedGridFiltersData,
      newCustomFields,
    );
  }

  /**
   * Async function to fetch product seller info.
   */
  async getSellerInfo(params) {
    return this.getListingData('Sellers', params);
  }

  /**
   * Async function to fetch seller saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: this.service });
  }

  /**
   * Async function to fetch seller saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Async function to update seller status
   *
   * @param {Object} params HTTP patch parameters
   */
  async updateSellerStatus(params) {
    return this.patch(`${this.PrependRoutes.reviewbox}/seller`, params);
  }

  /**
   * Function to construct ag-grid row data for sellers page.
   *
   * @param {Object} sellersData An object of seller metadata
   * @param {Array} columnDefs An array of ag-grid column data
   */
  static constructRowData(sellerData, columnDefs) {
    const rowData = [];

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    sellerData.forEach((seller) => {
      const row = {};

      columnTypes.forEach((column) => {
        // Information is a built field
        if (Object.prototype.hasOwnProperty.call(seller, column)) {
          if (column === 'rating') {
            if (typeof seller.num_ratings === 'number' && seller.num_ratings > 0) {
              row[column] = seller[column];
            } else {
              row[column] = 'n/a';
            }
          } else if (column === 'num_ratings') {
            if (typeof seller.num_ratings === 'number' && seller.num_ratings > 0) {
              row[column] = seller[column];
            } else {
              row[column] = 0;
            }
          } else if (
            ['price', 'shipping'].includes(column)
          ) {
            if (seller[column] !== null) {
              row[column] = parseFloat(seller[column]).toFixed(2);
            }
          } else if (column === 'lts' || column === 'ts' || column === 'last_seen_ts') {
            row[column] = new Date(seller[column] * 1000);
          } else if (column === 'prime') {
            row[column] = seller[column] ? 'Yes' : 'No';
          } else {
            row[column] = seller[column];
          }
          // information is a custom field
        } else if (
          Object.prototype.hasOwnProperty.call(seller, 'fields')
          && seller.fields !== null
          && Object.prototype.hasOwnProperty.call(seller.fields, column)
        ) {
          row[column] = seller.fields[column];
        } else if (column === 'num_sellers_in_violation') {
          row[column] = seller[column];
        } else {
          row[column] = '';
        }

        if (row[column] === null) {
          row[column] = '';
        }
      });

      rowData.push(row);
    });

    // Sort row data by seller rank
    return rowData
      .sort((a, b) => a.rank - b.rank)
      .map((item, index) => ({
        ...item,
        agGridRowNumber: index + 1,
      }));
  }
}

export default SellersBaseHttp;
