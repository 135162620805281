import BaseInput from 'src/components/Inputs/BaseInput.vue';
import Card from 'src/components/Cards/Card.vue';
import BaseCheckbox from 'src/components/Inputs/BaseCheckbox.vue';
import {
  Input, InputNumber, Tooltip, Popover, Button,
} from 'element-ui';

// Vue Material Icons - https://github.com/robcresswell/vue-material-design-icons
// Material Icons - https://material.io/design/iconography/system-icons.html#design-principles
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import MenuLeftIcon from 'vue-material-design-icons/MenuLeft.vue';
import MenuRightIcon from 'vue-material-design-icons/MenuRight.vue';
import DragIcon from 'vue-material-design-icons/Drag.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import DashboardIcon from 'vue-material-design-icons/ViewDashboard.vue';
import CommentTextOutlineIcon from 'vue-material-design-icons/CommentTextOutline.vue';
import TagIcon from 'vue-material-design-icons/Tag.vue';
import PencilIcon from 'vue-material-design-icons/Pencil.vue';
import ArchiveIcon from 'vue-material-design-icons/Archive.vue';
import AccountIcon from 'vue-material-design-icons/Account.vue';
import AccountCircleIcon from 'vue-material-design-icons/AccountCircle.vue';
import HelpCircleIcon from 'vue-material-design-icons/HelpCircle.vue';
import CogIcon from 'vue-material-design-icons/Cog.vue';
import ArrowExpandIcon from 'vue-material-design-icons/ArrowExpand.vue';
import DollarIcon from 'vue-material-design-icons/CurrencyUsd.vue';
import WidgetsIcon from 'vue-material-design-icons/Widgets.vue';
import SearchIcon from 'vue-material-design-icons/Magnify.vue';
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue';
import TableIcon from 'vue-material-design-icons/Table.vue';
import DeleteIcon from 'vue-material-design-icons/Delete.vue';
import PlayCircleOutlineIcon from 'vue-material-design-icons/PlayCircleOutline.vue';
import PlusIcon from 'vue-material-design-icons/Plus.vue';
import ForumOutlineIcon from 'vue-material-design-icons/ForumOutline.vue';
import FormatListBulletedIcon from 'vue-material-design-icons/FormatListBulleted.vue';
import FormatListChecksIcon from 'vue-material-design-icons/FormatListChecks.vue';
import DownloadIcon from 'vue-material-design-icons/Download.vue';
import CloudCircleIcon from 'vue-material-design-icons/CloudCircle.vue';
import CloudDownloadIcon from 'vue-material-design-icons/CloudDownload.vue';
import CloudUploadOutlineIcon from 'vue-material-design-icons/CloudUploadOutline.vue';
import CloudUploadIcon from 'vue-material-design-icons/CloudUpload.vue';
import FileIcon from 'vue-material-design-icons/File.vue';
import SyncIcon from 'vue-material-design-icons/Sync.vue';
import CommentQuestionOutlineIcon from 'vue-material-design-icons/CommentQuestionOutline.vue';
import FilterIcon from 'vue-material-design-icons/Filter.vue';
import AlertCircleIcon from 'vue-material-design-icons/AlertCircle.vue';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import HeartIcon from 'vue-material-design-icons/HeartOutline.vue';
import ClipboardTextOutlineIcon from 'vue-material-design-icons/ClipboardTextOutline.vue';
import TimerSandIcon from 'vue-material-design-icons/TimerSand.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronDoubleDownIcon from 'vue-material-design-icons/ChevronDoubleDown.vue';
import ChevronDoubleUpIcon from 'vue-material-design-icons/ChevronDoubleUp.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import MinusIcon from 'vue-material-design-icons/Minus.vue';
import MinusCircleIcon from 'vue-material-design-icons/MinusCircle.vue';
import ArrowUpIcon from 'vue-material-design-icons/ArrowUp.vue';
import ArrowDownIcon from 'vue-material-design-icons/ArrowDown.vue';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';
import StarIcon from 'vue-material-design-icons/Star.vue';
import StarOutlineIcon from 'vue-material-design-icons/StarOutline.vue';
import StarCircleIcon from 'vue-material-design-icons/StarCircle.vue';
import InfoIcon from 'vue-material-design-icons/Information.vue';
import EyeIcon from 'vue-material-design-icons/Eye.vue';
import EyeOffIcon from 'vue-material-design-icons/EyeOff.vue';
import ExcelFileIcon from 'vue-material-design-icons/FileExcel.vue';
import FolderZipIcon from 'vue-material-design-icons/FolderZip.vue';
import ExternalLinkIcon from 'vue-material-design-icons/OpenInNew.vue';
import LinkIcon from 'vue-material-design-icons/Link.vue';
import ArrowBottomRightIcon from 'vue-material-design-icons/ArrowBottomRight.vue';
import StoreIcon from 'vue-material-design-icons/Store.vue';
import ContentCopyIcon from 'vue-material-design-icons/ContentCopy.vue';
import TuneIcon from 'vue-material-design-icons/Tune.vue';
import ClipboardCheckOutlineIcon from 'vue-material-design-icons/ClipboardCheckOutline.vue';
import BullHornOutlineIcon from 'vue-material-design-icons/BullhornOutline.vue';
import MessageAlertOutlineIcon from 'vue-material-design-icons/MessageAlertOutline.vue';
import LogoutIcon from 'vue-material-design-icons/Logout.vue';
import LifebuoyIcon from 'vue-material-design-icons/Lifebuoy.vue';
import BookOpenBlankVariantIcon from 'vue-material-design-icons/BookOpenBlankVariant.vue';
import FolderOutlineIcon from 'vue-material-design-icons/FolderOutline.vue';
import FolderSearchOutlineIcon from 'vue-material-design-icons/FolderSearchOutline.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';
import EmailSyncIcon from 'vue-material-design-icons/EmailSync.vue';
import FilterVariantPlusIcon from 'vue-material-design-icons/FilterVariantPlus.vue';
import CancelIcon from 'vue-material-design-icons/Cancel.vue';
import AccountGroupIcon from 'vue-material-design-icons/AccountGroup.vue';
import BriefcaseIcon from 'vue-material-design-icons/Briefcase.vue';
import TextBoxCheckOutlineIcon from 'vue-material-design-icons/TextBoxCheckOutline.vue';
import FormatTextIcon from 'vue-material-design-icons/FormatText.vue';
import CodeJsonIcon from 'vue-material-design-icons/CodeJson.vue';
import FileSearchOutlineIcon from 'vue-material-design-icons/FileSearchOutline.vue';
import BookAlphabetIcon from 'vue-material-design-icons/BookAlphabet.vue';
import FullscreenIcon from 'vue-material-design-icons/Fullscreen.vue';
import FullscreenExitIcon from 'vue-material-design-icons/FullscreenExit.vue';
import TranslateIcon from 'vue-material-design-icons/Translate.vue';
import ThumbUpIcon from 'vue-material-design-icons/ThumbUp.vue';
import ThumbDownIcon from 'vue-material-design-icons/ThumbDown.vue';
import SendIcon from 'vue-material-design-icons/Send.vue';
import TableCogIcon from 'vue-material-design-icons/TableCog.vue';
import FormatTextVariantIcon from 'vue-material-design-icons/FormatTextVariant.vue';
import FileDocumentIcon from 'vue-material-design-icons/FileDocument.vue';
import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';

// Chart types
import BarChartIcon from 'vue-material-design-icons/ChartBar.vue';
import LineChartIcon from 'vue-material-design-icons/ChartLine.vue';
import ChartBoxPlusOutlineIcon from 'vue-material-design-icons/ChartBoxPlusOutline.vue';
import ChartBoxIcon from 'vue-material-design-icons/ChartBox.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.use(Tooltip);
    Vue.use(Button);
    Vue.use(Popover);

    // Register vue material icons

    // Widget Type Icons
    Vue.component('chart-box-icon', ChartBoxIcon);
    Vue.component('book-alphabet-icon', BookAlphabetIcon);
    Vue.component('file-search-outline-icon', FileSearchOutlineIcon);
    Vue.component('bar-chart-icon', BarChartIcon);
    Vue.component('line-chart-icon', LineChartIcon);
    Vue.component('store-icon', StoreIcon);
    Vue.component('chart-box-plus-outline-icon', ChartBoxPlusOutlineIcon);
    Vue.component('menu-icon', MenuIcon);
    Vue.component('menu-left-icon', MenuLeftIcon);
    Vue.component('menu-right-icon', MenuRightIcon);
    Vue.component('drag-icon', DragIcon);
    Vue.component('close-icon', CloseIcon);
    Vue.component('close-circle-icon', CloseCircleIcon);
    Vue.component('dashboard-icon', DashboardIcon);
    Vue.component('comment-text-outline-icon', CommentTextOutlineIcon);
    Vue.component('tag-icon', TagIcon);
    Vue.component('pencil-icon', PencilIcon);
    Vue.component('archive-icon', ArchiveIcon);
    Vue.component('account-icon', AccountIcon);
    Vue.component('account-circle-icon', AccountCircleIcon);
    Vue.component('help-circle-icon', HelpCircleIcon);
    Vue.component('cog-icon', CogIcon);
    Vue.component('arrow-expand-icon', ArrowExpandIcon);
    Vue.component('dollar-icon', DollarIcon);
    Vue.component('widgets-icon', WidgetsIcon);
    Vue.component('search-icon', SearchIcon);
    Vue.component('dots-vertical-icon', DotsVerticalIcon);
    Vue.component('table-icon', TableIcon);
    Vue.component('delete-icon', DeleteIcon);
    Vue.component('play-circle-outline-icon', PlayCircleOutlineIcon);
    Vue.component('plus-icon', PlusIcon);
    Vue.component('format-list-bulleted-icon', FormatListBulletedIcon);
    Vue.component('format-list-checks-icon', FormatListChecksIcon);
    Vue.component('download-icon', DownloadIcon);
    Vue.component('cloud-download-icon', CloudDownloadIcon);
    Vue.component('cloud-upload-outline-icon', CloudUploadOutlineIcon);
    Vue.component('cloud-upload-icon', CloudUploadIcon);
    Vue.component('file-icon', FileIcon);
    Vue.component('sync-icon', SyncIcon);
    Vue.component('comment-question-outline-icon', CommentQuestionOutlineIcon);
    Vue.component('filter-icon', FilterIcon);
    Vue.component('alert-circle-icon', AlertCircleIcon);
    Vue.component('check-icon', CheckIcon);
    Vue.component('check-circle-icon', CheckCircleIcon);
    Vue.component('heart-icon', HeartIcon);
    Vue.component('clipboard-text-outline-icon', ClipboardTextOutlineIcon);
    Vue.component('timer-sand-icon', TimerSandIcon);
    Vue.component('chevron-double-left-icon', ChevronDoubleLeftIcon);
    Vue.component('chevron-double-right-icon', ChevronDoubleRightIcon);
    Vue.component('chevron-double-up-icon', ChevronDoubleUpIcon);
    Vue.component('chevron-down-icon', ChevronDownIcon);
    Vue.component('minus-icon', MinusIcon);
    Vue.component('minus-circle-icon', MinusCircleIcon);
    Vue.component('arrow-up-icon', ArrowUpIcon);
    Vue.component('arrow-down-icon', ArrowDownIcon);
    Vue.component('arrow-left-icon', ArrowLeftIcon);
    Vue.component('arrow-right-icon', ArrowRightIcon);
    Vue.component('star-icon', StarIcon);
    Vue.component('star-outline-icon', StarOutlineIcon);
    Vue.component('star-circle-icon', StarCircleIcon);
    Vue.component('info-icon', InfoIcon);
    Vue.component('chevron-double-down-icon', ChevronDoubleDownIcon);
    Vue.component('eye-icon', EyeIcon);
    Vue.component('eye-off-icon', EyeOffIcon);
    Vue.component('excel-file-icon', ExcelFileIcon);
    Vue.component('folder-zip-icon', FolderZipIcon);
    Vue.component('external-link-icon', ExternalLinkIcon);
    Vue.component('arrow-bottom-right-icon', ArrowBottomRightIcon);
    Vue.component('content-copy-icon', ContentCopyIcon);
    Vue.component('tune-icon', TuneIcon);
    Vue.component('clipboard-check-outline-icon', ClipboardCheckOutlineIcon);
    Vue.component('link-icon', LinkIcon);
    Vue.component('bullhorn-outline-icon', BullHornOutlineIcon);
    Vue.component('message-alert-outline-icon', MessageAlertOutlineIcon);
    Vue.component('logout-icon', LogoutIcon);
    Vue.component('lifebuoy-icon', LifebuoyIcon);
    Vue.component('book-open-blank-variant-icon', BookOpenBlankVariantIcon);
    Vue.component('folder-outline-icon', FolderOutlineIcon);
    Vue.component('folder-search-outline-icon', FolderSearchOutlineIcon);
    Vue.component('email-icon', EmailIcon);
    Vue.component('email-sync-icon', EmailSyncIcon);
    Vue.component('filter-variant-plus-icon', FilterVariantPlusIcon);
    Vue.component('cancel-icon', CancelIcon);
    Vue.component('account-group-icon', AccountGroupIcon);
    Vue.component('briefcase-icon', BriefcaseIcon);
    Vue.component('text-box-check-outline-icon', TextBoxCheckOutlineIcon);
    Vue.component('format-text-icon', FormatTextIcon);
    Vue.component('cloud-circle-icon', CloudCircleIcon);
    Vue.component('forum-outline-icon', ForumOutlineIcon);
    Vue.component('code-json-icon', CodeJsonIcon);
    Vue.component('fullscreen-icon', FullscreenIcon);
    Vue.component('fullscreen-exit-icon', FullscreenExitIcon);
    Vue.component('translate-icon', TranslateIcon);
    Vue.component('thumb-up-icon', ThumbUpIcon);
    Vue.component('thumb-down-icon', ThumbDownIcon);
    Vue.component('send-icon', SendIcon);
    Vue.component('table-cog-icon', TableCogIcon);
    Vue.component('format-text-variant-icon', FormatTextVariantIcon);
    Vue.component('file-document-icon', FileDocumentIcon);
    Vue.component('file-document-outline-icon', FileDocumentOutlineIcon);
  },
};

export default GlobalComponents;
