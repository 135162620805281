import { render, staticRenderFns } from "./ReportsAndExportsHelpButton.vue?vue&type=template&id=620fd1fc&scoped=true&"
import script from "./ReportsAndExportsHelpButton.vue?vue&type=script&lang=js&"
export * from "./ReportsAndExportsHelpButton.vue?vue&type=script&lang=js&"
import style0 from "./ReportsAndExportsHelpButton.vue?vue&type=style&index=0&id=620fd1fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620fd1fc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('620fd1fc')) {
      api.createRecord('620fd1fc', component.options)
    } else {
      api.reload('620fd1fc', component.options)
    }
    module.hot.accept("./ReportsAndExportsHelpButton.vue?vue&type=template&id=620fd1fc&scoped=true&", function () {
      api.rerender('620fd1fc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Help/ReportsAndExportsHelpButton.vue"
export default component.exports