import BaseHttp from './BaseHttp';

class ResponseHttp extends BaseHttp {
  async saveResponseDraft(params = {}) {
    return this.post(`${this.PrependRoutes.reviewbox}/response-draft`, params)
      .then((response) => response.data);
  }

  async getResponseDraft(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/response-draft`, params)
      .then((response) => response.data);
  }

  /**
   * Fetch a user's response templates.
   *
   * @param {Object} params HTTP post params object
   */
  async getResponseTemplates(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/response-templates`, params)
      .then((response) => response.data.templates);
  }

  /**
   * Create a user's response templates.
   *
   * @param {Object} params HTTP post params object
   */
  async postResponseTemplate(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/response-templates`, params)
      .then((response) => response.data);
  }

  /**
   * Delete a user's response templates.
   *
   * @param {Object} params HTTP post params object
   */
  async deleteResponseTemplate(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/response-templates`, params)
      .then((response) => response.data);
  }

  /**
   * Updates a user's current response.
   *
   * @param {Object} params HTTP post params object
   */
  async editResponse(params) {
    return this.patch(`${this.PrependRoutes.reviewbox}/response`, params)
      .then((response) => response.data);
  }

  /**
   * Delete a user's response.
   *
   * @param {Object} params HTTP post params object
   */
  async deleteResponse(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/response`, params)
      .then((response) => response.data);
  }
}

export default new ResponseHttp();
