import BaseHttp from './BaseHttp';

class NotificationsHttp extends BaseHttp {
  /**
   * @todo: WARNING: If we have seperate endpoints, we will need to refactor
   * code for each endpoint
   *
   * Fetch user's current subscription
   *
   */

  getNotifications() {
    return this.get(`${this.PrependRoutes.reviewbox}/notifications`);
  }

  postNotifications(payload) {
    return this.post(`${this.PrependRoutes.reviewbox}/notifications`, payload);
  }

  // used to test notificatinos
  patchNotifications(payload) {
    return this.patch(`${this.PrependRoutes.reviewbox}/notifications`, payload);
  }
}

export default new NotificationsHttp();
