/**
 * Mixin to create global custom form validations with vee-validate.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 * @see {@link http://vee-validate.logaretm.com/v2/guide/custom-rules.html#object-form|Vee-Validate}
 */
export default {
  data() {
    return {
      customValidations: {
        /**
         * Custom validation for export and report file names. File names
         * should not have forward slashes (/)
         */
        fileName: {
          getMessage() {
            return 'Invalid file name. Please remove any forward or back slashes from the file name.';
          },
          validate(value) {
            // Create regex for checking valid file name format
            const regex = /[/\\]/gm;

            // Validate file name for forward and back slashes
            const invalidFileName = regex.test(value);

            return !invalidFileName;
          },
        },
        /**
         * Custom validation for multiple emails.
         * It makes sure, if given a comma-separated email list,
         * that each email is valid.
         */
        multipleEmail: {
          getMessage() {
            return 'Invalid email. Make sure email(s) are correct.';
          },
          validate(value) {
            // Split string of emails into array
            const emails = value.split(',');
            // Create regex for checking valid email format
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            // Validate each email in a list and
            // create an array of validity check results
            const validEmailList = emails.map((email) => regex.test(email.trim()));
            // Assign isInvalid = true, if there is atleast one false valid,
            // else isInvalid = false, if all validEmailList entries are true
            if (validEmailList.some((isValid) => isValid === false)) {
              return false;
            }

            return value;
          },
        },
        /**
         * Search Terms Select Max
         */
        searchTermsSelectMax: {
          getMessage(value, args) {
            const max = parseInt(args[0], 10);

            return `Max exceeded. Select ${max} terms or fewer.`;
          },
          validate(value, args) {
            if (value === null) {
              return false;
            }

            const [max, manual] = args;
            let tempValue = value;

            /**
             * validate for manual. We know it will be an object on length 1.
             * So we just use Object.values to return an array of values of an object
             * then only access the first element.
             * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/values|Object Values}
             *
             * @todo Why validate manual?  See comment in SearchTermsSelect.vue
             * "We need to figure out a better way to implement this?
             * Just keep it a normal array of terms and on the back-end
             * throw those into a dictionary?
             * Having to catch for this makes is an extra step not needed."
             */
            if (manual) {
              [tempValue] = Object.values(tempValue);
            }

            if (tempValue.length > parseInt(max, 10)) {
              return false;
            }

            return tempValue;
          },
        },
        /**
         * Custom validation for response messages.
         * It makes sure no tokens, e.g. <author>, <name> are in the final text.
         */
        responseMessage: {
          getMessage() {
            return 'Invalid response. Please do not include tokens. E.g. <author>, <name>, etc.';
          },
          validate(value) {
            const tokens = ['<name>', '<author>', '<product>'];
            const invalid = tokens.some((token) => value.includes(token));

            if (invalid) {
              return false;
            }

            return value;
          },
        },
      },
    };
  },
  methods: {
    /**
     * Helper method to construct and register custom validations.
     */
    initializeCustomValidations() {
      // Create multiple email validation, accounting for comma-separated emails
      Object.keys(this.customValidations)
        .forEach((validationName) => {
          this.$validator.extend(validationName, this.customValidations[validationName]);
        });

      /**
       * Update local dictionary to have custom error messages
       * Global for the app
       *
       * @see {@link https://baianat.github.io/vee-validate/guide/messages.html#field-specific-custom-messages|VeeValidate Custom Messages}
       */
      this.$validator.localize('en', {
        messages: {
          required: 'This input field is required.',
        },
      });
    },
    /**
     * Method to dynamically create validations for an input element.
     *
     * @param {Object} input Input object with attributes for validation
     * e.g.) {type, required}
     *
     * @returns {Object} Returns a validation object per Vee-validate guidelines
     */
    createValidationObject(input = {}, hasStorageCredentials = false) {
      const validationObject = [];

      if (input.required) {
        validationObject.push('required');
      }

      if (input.type === 'email') {
        validationObject.push('email');
      }

      if (input.type === 'multipleEmail') {
        validationObject.push('multipleEmail');
      }

      if (input.type === 'responseMessage') {
        validationObject.push('responseMessage');
      }

      /**
       * This is a catch to make sure product is selected
       * otherwise exports and reports have nothing to base
       * off of.
       *
       * @todo We'll want to make sure any export/report that uses
       * a productSelect is required
       */
      if (input.type === 'productSelect' && validationObject.indexOf('require') < 0) {
        validationObject.push('required');
      }

      if (
        input.type === 'searchTermsSelect'
        && Object.prototype.hasOwnProperty.call(input, 'max_select')
      ) {
        let temp = `searchTermsSelectMax:${input.max_select}`;
        validationObject.push();

        // Catch for is_manual to set specific validation parameters
        if (input.is_manual) {
          temp += ',manual';
        }

        validationObject.push(temp);
      }

      if (input.name === 'exportReportName' && !hasStorageCredentials) {
        validationObject.push('fileName');
      }

      return validationObject.join('|');
    },
  },
};
