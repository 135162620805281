import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';

const agGridModules = [
  ClientSideRowModelModule,
  InfiniteRowModelModule,
  MenuModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  SetFilterModule,
  MultiFilterModule,
  SideBarModule,
  ColumnsToolPanelModule,
  MasterDetailModule,
  RichSelectModule,
  ClipboardModule,
  StatusBarModule
];

ModuleRegistry.registerModules(agGridModules);

export default agGridModules;
