import { BaseHttp } from '@/services/api';

export default {
  /**
   * Action to questions response topics values for column.
   *
   * @param {Function} commit Function to call mutation
   */
  async getCustomTopics({ commit }) {
    return BaseHttp.getCustomTopics()
      .then((valuesToAdd) => {
        commit('grids/addDropdownColumnValues', { service: 'questions', field: 'topic', valuesToAdd }, { root: true });
      });
  },
  /**
   * Action to fetch questions customCol values for column.
   *
   * @param {Function} commit Function to call mutation
   */
  async getCustomColValues({ commit }) {
    return BaseHttp.getCustomColValues()
      .then((valuesToAdd) => {
        commit('grids/addDropdownColumnValues', { service: 'questions', field: 'customCol', valuesToAdd }, { root: true });
      });
  },
};
