import SellersBaseHttp from './SellersBaseHttp';

/**
 * Class to handle all pricebox related http requests
 */
class SellersHttp extends SellersBaseHttp {
  constructor() {
    super();

    this.service = 'Sellers';

    // Default reviewbox columns
    this.defaultColumns = [
      // Catalog column group
      {
        headerName: 'Catalog',
        groupId: 'catalog',
        children: [
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'Seller Name',
            field: 'seller_name',
          },
          {
            headerName: 'ID',
            field: 'seller',
          },
        ],
      },
      // Sellers column group
      {
        headerName: 'Sellers',
        groupId: 'sellers',
        children: [
          {
            headerName: 'Number of Products',
            field: 'num_listings',
          },
          {
            headerName: 'Rating',
            field: 'rating',
          },
          {
            headerName: 'Total Ratings',
            field: 'num_ratings',
          },
          {
            headerName: 'Products with Violations',
            field: 'num_listings_had_violations',
          },
          {
            headerName: 'Last Seen (UTC)',
            field: 'last_seen_ts',
          },
        ],
      },
      // Response column group
      {
        headerName: 'Response',
        groupId: 'response',
        children: [
          {
            headerName: 'Status',
            field: 'seller_status',
          },
          {
            headerName: 'Notes',
            field: 'seller_comment',
          },
          {
            headerName: 'Contact',
            field: 'seller_url',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'price',
      'shipping',
      'num_listings',
      'rating',
      'num_ratings',
      'rank',
      'num_listings_had_violations',
    ];
  }
}

export default new SellersHttp();
