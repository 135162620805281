var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("a", { attrs: { href: _vm.link, target: "_blank" } }, [
    _c("img", { attrs: { border: "0", src: _vm.link, height: "75" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }