var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-auto rb-help-container" },
    [
      _c(
        "button",
        {
          staticClass: "rb-help-btn ml-auto btn btn-link btn-sm text-muted",
          on: {
            click: function ($event) {
              _vm.dialog = true
            },
          },
        },
        [
          _c("info-icon", { staticClass: "rb-icon mr-1" }),
          _c("span", { staticClass: "rb-help-text" }, [
            _vm._v(" How do reports and exports work? "),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog,
            "custom-class": "dialog-secondary",
            width: _vm.width,
            top: "60px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c("h4", { staticClass: "text-center text-white mb-0" }, [
                    _vm._v(" About Reports and Exports "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "rb-help-tabs",
              attrs: { "tab-position": "left" },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                staticClass: "font-weight-bold",
                attrs: {
                  label: "About",
                  disabled: "",
                  name: "Reports and Exports",
                },
              }),
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "el-tab-pane",
                  {
                    key: tab.label,
                    attrs: { label: tab.label, name: tab.label },
                  },
                  [
                    _c("div", { staticClass: "p-3" }, [
                      _c(
                        "p",
                        { staticClass: "text-center" },
                        [
                          tab.imageUrl
                            ? _c("img", {
                                staticClass: "img-fluid rounded border",
                                attrs: { src: tab.imageUrl },
                              })
                            : tab.icon
                            ? _c(tab.icon, {
                                tag: "component",
                                staticClass: "rb-icon-9x",
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "text-left text-muted",
                        domProps: { innerHTML: _vm._s(tab.content) },
                      }),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("p", { staticClass: "mb-0 text-muted" }, [
                _c("small", [
                  _vm._v(" Any feedback? "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://share.hsforms.com/12WAyDYyIReGDz9f2cQGOnw2zdch",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Let us know!")]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }