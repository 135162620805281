import { DashboardHttp } from '@/services/api';

export default {
  /**
   * Action to fetch dashboard data.
   *
   * @param {Function} commit Function to commit mutations in dashboard module
   * @param {Function} dispatch Function to call actions in dashboard module
   * @param {Object} params Parameters to pass to fetch dashboard
   */
  async getDashboard({ commit, dispatch }, params) {
    commit('setDashboardLoading', true);

    return DashboardHttp.getDashboard(params)
      .then((data) => {
        commit('setDashboard', data);
        dispatch('initializeDashboardFilters');
        commit('setDashboardLoading', false);
      }).catch(() => {
        commit('setDashboardLoading', true);
      });
  },
  /**
   * Action to set dashboard filters.
   *
   * @param {Object} state Dashboard module state
   * @param {Function} dispatch Function to call actions
   * @param {Function} commit Function to call mutations
   * @param {Object} rootState Object to access root state of VueX store
   */
  initializeDashboardFilters({
    state,
    dispatch,
    commit,
    rootState,
  }) {
    if (state.dashboard === null) {
      commit('setDashboardFilters', null);
    }

    const filterInputs = {
      filters: [],
      bfilters: [],
    };
    const filterForm = {
      dateRange: { ...rootState.dateRange },
      filters: {},
      bfilters: {},
    };
    if (
      Object.prototype.hasOwnProperty.call(state.dashboard, 'fields')
      && state.dashboard.fields !== null
    ) {
      Object.keys(state.dashboard.fields)
        .forEach((key) => {
          filterInputs.filters.push({
            label: key,
            options: [...state.dashboard.fields[key]],
          });
        });
    }

    // Initialize bfilters (which is only sources at the moment)
    if (
      Object.prototype.hasOwnProperty.call(state.dashboard, 'sources')
      && Array.isArray(state.dashboard.sources)
    ) {
      filterInputs.bfilters.push({
        label: 'source',
        // sources list items come as { source, name } object
        options: [...state.dashboard.sources.map((source) => source.source)].sort(),
      });
    }

    dispatch('updateDashboardFilters', { inputs: filterInputs, form: filterForm });
  },
  /**
   * Action to update dashboard filters.
   *
   * @param {Function} commit Function to call mutations
   * @param {Function} dispatch Function to call actions
   * @param {Object} filters Dashboard filters object
   */
  async updateDashboardFilters({ commit }, filters) {
    commit('setDashboardFilters', filters);
    return true;
  },
};
