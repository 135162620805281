import BaseHttp from './BaseHttp';

/**
 * Class to handle all Reviewbox related http requests
 */
class ReviewboxHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'Reviewbox';

    // Default reviewbox columns
    this.defaultColumns = [
      {
        headerName: 'Catalog',
        groupId: 'general',
        children: [
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'ID',
            field: 'listing',
          },
          {
            headerName: 'Name',
            field: 'name',
          },
        ],
      },
      {
        headerName: 'Reviewbox',
        groupId: 'reviewbox',
        children: [
          {
            headerName: 'Total Ratings',
            field: 'variation_reviews',
          },
          {
            headerName: 'Listing Reviews',
            field: 'num_reviews',
          },
          {
            headerName: 'Average Rating',
            field: 'avg_reviews',
          },
          {
            headerName: 'Questions',
            field: 'num_questions',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'variation_reviews',
      'num_reviews',
      'num_questions',
      'avg_reviews',
    ];
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    return this.constructGrid(
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  async getListings(params = {}) {
    return this.getListingData(this.service, params);
  }

  async getProductSentiment(params = {}) {
    return this.post(`${this.PrependRoutes.reviewbox}/productsSentiment`, params)
      .then((response) => response.data);
  }

  /**
   * Async function to fetch reviewbox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Reviewbox' });
  }

  /**
   * Async function to fetch reviewbox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Async function to update custom field metadata for reviewbox products.
   *
   * @param {Object} params HTTP Post params object
   */
  async updateReviewboxCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customfields`, params);
  }

  static constructRowData(listingsData, columnDefs) {
    const rowData = [];

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    if (
      Object.prototype.hasOwnProperty.call(listingsData, 'listings')
      && listingsData.listings !== null
      && listingsData.listings.length
    ) {
      listingsData.listings.forEach((listing) => {
        const row = {};

        columnTypes.forEach((column) => {
          if (Object.prototype.hasOwnProperty.call(listing, column)) {
            row[column] = listing[column];
          } else if (
            Object.prototype.hasOwnProperty.call(listing, 'fields')
            && listing.fields !== null
            && Object.prototype.hasOwnProperty.call(listing.fields, column)
          ) {
            row[column] = listing.fields[column];
          } else {
            row[column] = '';
          }

          if (row[column] === null) {
            row[column] = '';
          }
        });

        // Check if this is a Bazaarvoice row
        row.bazaarvoice = (Object.prototype.hasOwnProperty.call(listing, 'bazaarvoice') && listing.bazaarvoice);

        // If a product url exists, add it to row data so that
        // we can externally link product to Amazon, and etc.
        if (Object.prototype.hasOwnProperty.call(listing, 'url')
            && listing.url !== null
        ) {
          row.producturl = listing.url;
        }

        rowData.push(row);
      });
    } else {
      return rowData;
    }

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default new ReviewboxHttp();
