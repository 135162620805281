import Vue from 'vue';
import { LicenseManager } from '@ag-grid-enterprise/core'; // eslint-disable-line import/no-duplicates
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import DashboardPlugin from './plugins/dashboard-plugin';
import NotificationPlugin from './plugins/NotificationPlugin';
import ShepherdPlugin from './plugins/shepherd-plugin';
import RollbarPlugin from './plugins/rollbar-plugin';

import App from './App.vue';

import {
  ErrorHandler,
  TimeParser,
  UrlConstructors,
} from './mixins';

// Router setup
import router from './routes';
import i18n from './i18n';
import store from './store';

// Plugin setup
Vue.use(NotificationPlugin);
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(ShepherdPlugin);
Vue.use(RollbarPlugin);

// Register global mixins
Vue.mixin(TimeParser);
Vue.mixin(ErrorHandler);
Vue.mixin(UrlConstructors);

// Set Up Ag Grid Enterprise for Vue
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID_LICENSE);

// For the async-validator warnings
// Documented here: https://github.com/yiminghe/async-validator/issues/92#issuecomment-463207689
const { warn } = console;
console.warn = (...args) => {
  if (typeof args[0] === 'string' && args[0].startsWith('async-validator:')) {
    return;
  }

  warn(...args);
};

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  i18n,
});
