var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      staticClass: "rb-select-filter",
      attrs: {
        id: _vm.id,
        name: "filters",
        placeholder: _vm.placeholder,
        "no-data-text": "Filter not found",
        "no-match-text": "Filter not found",
        filterable: "",
        clearable: _vm.clearable,
        multiple: _vm.multiple,
        "collapse-tags": _vm.collapseTags,
        "default-first-option": _vm.defaultFirstOption,
        "allow-create": _vm.allowCreate,
        "filter-method": _vm.onFilterSearch,
        disabled: _vm.disabled,
        "popper-append-to-body": _vm.appendToBody,
        required: _vm.required,
        error: _vm.error,
      },
      model: {
        value: _vm.innerValue,
        callback: function ($$v) {
          _vm.innerValue = $$v
        },
        expression: "innerValue",
      },
    },
    [
      _vm.useDummyOption
        ? _c("el-option", {
            attrs: {
              value: null,
              disabled: true,
              label: _vm.placeholder || "Select",
            },
          })
        : _vm._e(),
      _vm._l(_vm.selectOptions, function (option) {
        return _c("el-option", {
          key: _vm.getLabel(option),
          staticClass: "select-primary",
          attrs: { label: _vm.getLabel(option), value: option },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }