import BaseHttp from './BaseHttp';

class AuthHttp extends BaseHttp {
  /**
   * Fetch OAuth log in iframe
   */
  getLogInPage() {
    return this.get(`${this.PrependRoutes.auth}/login`);
  }

  /**
   * Log out user from current session
   */
  logOutUser() {
    return this.get(`${this.PrependRoutes.auth}/logout`);
  }

  /**
   * Updates user profile on Oauth
   *
   * @param {Object} payload HTTP request params object
   */
  async updateUserProfile(params) {
    return this.patch(`${this.PrependRoutes.auth}/user`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches a user's OAuth profile.
   *
   * @param {Object} params HTTP params object
   */
  async getUserProfile(params) {
    return this.get(`${this.PrependRoutes.auth}/user`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches a user's account plan.
   *
   * @param {Object} params HTTP get request params object
   */
  async getUserPlans(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/plans`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches a single user account plan.
   *
   * @param {Object} params HTTP get request params object
   */
  async getUserPlan(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/plan`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches a user's plan summary.
   *
   * @param {Object} params HTTP get request params object
   */
  async getUserPlanSummary(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/plan-summary`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches all available sources specific to user and their uploaded products.
   *
   * @param {Object} params HTTP get request params object
   */
  async getUserSources(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/sources`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches all available sources for each service.
   *
   * @param {Object} params HTTP get request params object
   */
  async getServiceSourceList(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/sources-list`, params)
      .then((response) => response.data);
  }

  /**
   * Fetches a user's export settings for a service.
   *
   * @param {String} service Name of service to get export settings
   */
  async getUserExportSettings(service) {
    return this.get(`${this.PrependRoutes.reviewbox}/exportsettings`, { export_type: service })
      .then((response) => response.data);
  }

  /**
   * Updates a user's export settings for a service.
   *
   * @param {Object} params HTTP post params object
   */
  async updateUserExportSettings(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/exportsettings`, params);
  }
}

export default new AuthHttp();
