var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "white-space": "pre-line" } }, [
    _c("h4", { staticClass: "text-muted" }, [_vm._v(" Details ")]),
    _c(
      "p",
      { staticClass: "text-muted", staticStyle: { "white-space": "pre-line" } },
      [_vm._v(" " + _vm._s(_vm.content) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }