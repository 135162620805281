var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "btn btn-link btn-sm text-decoration-none text-reset d-flex flex-column justify-content-center align-items-center",
      on: {
        click: function ($event) {
          return _vm.setShowDownloads(true)
        },
      },
    },
    [_c("cloud-download-icon"), _vm._v(" Downloads ")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }