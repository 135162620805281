/**
 * Truncate string by amount of characters passed in. If
 * characters is null, default truncate is by one character.
 *
 * @param { string } text String passed into filter
 * @param { object } options Object of params to manipulate text string
 * @param { number } options.limit Number of characters to slice
 * @param { boolean } options.ellipses Whether to add or remove ellipses to truncated string
 *
 * @returns { string } result string that has been truncated with or without ellipses
 */
export default function truncate(
  text,
  { limit = 150, ellipses = true }
) {
  // Stop if text is null or empty
  if (!text) {
    return '';
  }

  // Convert numbers to strings
  let result = text.toString();

  // Don't manipulate if truncate length is 0
  if (limit === 0 || result.length <= limit) {
    return result;
  }

  // Truncate text
  result = result.slice(0, limit);

  // Add ellipses
  if (ellipses) {
    result = `${result}...`;
  }

  return result;
}
