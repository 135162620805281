var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-check form-check-radio", class: _vm.radioClasses },
    [
      _c(
        "label",
        { staticClass: "form-check-label", attrs: { for: _vm.cbId } },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            staticClass: "form-check-input",
            attrs: { id: _vm.cbId, type: "radio", disabled: _vm.disabled },
            domProps: { value: _vm.name, checked: _vm._q(_vm.model, _vm.name) },
            on: {
              change: function ($event) {
                _vm.model = _vm.name
              },
            },
          }),
          _vm._t("default"),
          _vm._v(" "),
          _c("span", { staticClass: "form-check-sign" }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }