var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "response-drawer-toolbar d-flex justify-content-between align-items-center",
    },
    [
      _c(
        "div",
        { staticClass: "left-actions d-flex align-items-center" },
        [
          _vm.showPrev
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `Previous ${
                      _vm.isReview ? "review" : "question"
                    } (shift + left arrow)`,
                    "open-delay": 500,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-action",
                      attrs: { size: "mini" },
                      on: { click: () => _vm.$emit("prev") },
                    },
                    [_c("arrow-left-icon")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showNext
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content: `Next ${
                      _vm.isReview ? "review" : "question"
                    } (shift + right arrow)`,
                    "open-delay": 500,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-action",
                      attrs: { size: "mini" },
                      on: { click: () => _vm.$emit("next") },
                    },
                    [_c("arrow-right-icon")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._t("left-actions"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "right-actions d-flex align-items-center" },
        [
          _vm._t("right-actions"),
          _vm.isReview && _vm.isAmazon && _vm.hasEnterprise
            ? _c(
                "el-dropdown",
                {
                  attrs: { trigger: "click" },
                  on: { command: _vm.onSubmitAction },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass:
                        "btn-more-action rb-response-management-more-actions pl-2 pr-1",
                      attrs: { size: "mini" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c("span", [_vm._v("More")]),
                          _c("chevron-down-icon", { attrs: { title: "" } }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { command: "vote", disabled: _vm.isLoading },
                        },
                        [
                          _c("thumb-up-icon", {
                            staticClass: "rb-icon-sm mr-2",
                            attrs: { title: "" },
                          }),
                          _vm._v(" Mark Helpful "),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { command: "abuse", disabled: _vm.isLoading },
                        },
                        [
                          _c("thumb-down-icon", {
                            staticClass: "rb-icon-sm mr-2",
                            attrs: { title: "" },
                          }),
                          _vm._v(" Report Abuse "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showWalkthrough
            ? _c(
                "el-tooltip",
                {
                  attrs: {
                    content:
                      "Click to get a walkthrough of the response management panel",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-action ml-1",
                      attrs: { size: "mini" },
                      on: {
                        click: function ($event) {
                          return _vm.onResponseManagementWalkthrough(
                            _vm.isReview
                          )
                        },
                      },
                    },
                    [
                      _c("info-icon", {
                        staticClass: "rb-icon",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showFullscreen
            ? _c(
                "el-tooltip",
                {
                  attrs: { "open-delay": 500 },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("div", [_vm._v(" Full screen: ")]),
                            _c("div", [_vm._v(" cmd + shift + f (Mac) ")]),
                            _c("div", [_vm._v(" ctrl + shift + f (Window) ")]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    164974333
                  ),
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn-action",
                      attrs: { size: "mini" },
                      on: { click: () => _vm.$emit("fullscreen") },
                    },
                    [
                      !_vm.isFullscreen
                        ? _c("fullscreen-icon", { attrs: { title: "" } })
                        : _vm._e(),
                      _vm.isFullscreen
                        ? _c("fullscreen-exit-icon", { attrs: { title: "" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tooltip",
            { attrs: { content: _vm.closeTooltip, "open-delay": 500 } },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-action",
                  attrs: { size: "mini" },
                  on: { click: () => _vm.$emit("close") },
                },
                [_c("close-icon", { attrs: { title: "" } })],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }