export default {
  /**
   * Action to set loading status for reports page.
   *
   * @param {Function} commit Function to call reports mutations
   * @param {Boolean} status Flag for loading status of reports page
   */
  setIsLoading({ commit }, status) {
    commit('setIsLoading', status);
  },
  /**
   * Action to set reports through mutation.
   *
   * @param {Function} commit Function to call reports mutations
   * @param {Array} reports Array of active reports information
   */
  setReports({ commit }, reports) {
    commit('setReports', reports);
  },
  /**
   * Action to set reports through mutation.
   *
   * @param {Function} commit Function to call reports mutations
   * @param {Array} reports Array of active reports information
   */
  setSelectedReport({ commit }, payload) {
    commit('setSelectedReport', payload);
  },
  /**
   * Action to remove a single report from the reports array.
   *
   * @param {Function} commit Function to call reports mutations
   * @param {String} reportId Unique ID of report/export
   */
  removeReportById({ commit }, reportId) {
    commit('removeReportById', reportId);
  },
  /**
   * Action to remove all reports from active list.
   *
   * @param {Function} commit Function to call reports mutations
   */
  removeReports({ commit }) {
    commit('removeReports');
  },
};
