import BaseHttp from './BaseHttp';

class DownloadsHttp extends BaseHttp {
  /**
   * Fetch Exports and Reports downloads.
   */
  getDownloads(params = {}) {
    return this.get(`${this.PrependRoutes.reviewbox}/jobs`, params);
  }
}

export default new DownloadsHttp();
