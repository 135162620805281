export default {
  /**
   * Mutation to setdashboardFilter dashboard data state.
   *
   * @param {Object} state Dashboard module state
   * @param {Object} data Dashboard data object
   */
  setDashboard(state, data) {
    state.dashboard = data;
  },
  /**
   * Mutation to remove dashboard data state.
   *
   * @param {Object} state Dashboard module state
   */
  removeDashboard(state) {
    state.dashboard = null;
  },
  /**
   * Mutation to set dashboard filter input.
   *
   * @param {Object} state Dashboard module state
   * @param {Object} filters Dashboard filters
   */
  setDashboardFilters(state, filters) {
    // Check how many filtering values there are across the custom
    // fields. If there are too many then Vue.js will pause too long
    // trying to draw the select menus!
    if (filters.input) {
      const tooManyOptions = filters.input.filters.some((filter) => filter.options.length > 256);

      if (tooManyOptions) {
        filters.inputs.filters = [];
      }
    }

    // Set the dasbboard filters and try to save it in the local storage.
    // We should probably replace this part with a more robust solution in the future.
    state.dashboardFilters = filters;
    // try {
    //   localStorage.setItem('dashboard/filters', JSON.stringify(filters));
    //   localStorage.setItem('dashboardFilters', JSON.stringify(filters));
    // } catch (e) {
    //   localStorage.removeItem('dashboard/filters');
    //   localStorage.removeItem('dashboardFilters');
    // }

    // Clean out the local storage. We may need to add this back in
    // at a future date!
    localStorage.removeItem('dashboardFilters');
  },
  /**
   * Mutation to remove dashboard filter input.
   *
   * @param {Object} state Dashboard module state
   */
  removeDashboardFilters(state) {
    state.dashboardFilters = null;
    localStorage.removeItem('dashboard/filters');
  },
  /**
   * Mutation to toggle dashboard loading status.
   *
   * @param {Object} state Dashboard module state
   * @param {Boolean} value Boolean for loading status
   */
  setDashboardLoading(state, value) {
    state.isLoading = value;
  },
};
