import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      selectedWidget: 'insights/selectedWidget',
      openWidgetModal: 'insights/openWidgetModal',
      isWidgetModalReadOnly: 'insights/isWidgetModalReadOnly',
    }),
  },
  methods: {
    ...mapActions({
      setSelectedWidget: 'insights/setSelectedWidget',
    }),
    ...mapMutations({
      setOpenWidgetModal: 'insights/setOpenWidgetModal',
      setWidgetModalReadOnly: 'insights/setWidgetModalReadOnly',
      setWidgetModalCreateMode: 'insights/setWidgetModalCreateMode',
    }),
    /**
     * Close the Edit Widget Modal and dialog
     */
    async onCloseDialog() {
      try {
        this.setOpenWidgetModal(false);
        this.setSelectedWidget(null);
      } catch (error) {
        console.error('Error closing dialog: ', error);
      }
    },
  }
};
