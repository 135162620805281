/**
 * Function that filters services out of a list of links
 *
 * @param {Array} links Array of links
 * @param {Object} activeServices Object of services allowed
 */
export default (links, activeServices) => {
  const filteredLinks = [];

  // Filter out links to services a user is not paying for
  for (let index = 0; index < links.length; index += 1) {
    const link = links[index];
    const hasService = Object.prototype.hasOwnProperty.call(link, 'service');
    const hasChildren = Object.prototype.hasOwnProperty.call(link, 'children');

    // Add links with no service or children
    if (!hasService && !hasChildren) {
      filteredLinks.push(link);
    // Add link if service is allowed
    } else if (hasService && activeServices[link.service]) {
      filteredLinks.push(link);
    // Add link with filtered children of allowable services
    } else if (hasChildren) {
      if ((hasService && activeServices[link.service]) || !hasService) {
        link.children = link.children.filter((child) => {
          if (
            Object.prototype.hasOwnProperty.call(child, 'service')
            && !activeServices[child.service]
          ) {
            return false;
          }

          return true;
        });

        filteredLinks.push(link);
      }
    }
  }

  return filteredLinks;
};
