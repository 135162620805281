/**
 * Safely compare two numbers with some fail-safes.
 *
 * @param {Number} x First number
 * @param {Number} y Second number
 * @returns {Number} Returns
 */
export default (x, y) => {
  const first = Number(x);
  const second = Number(y);

  if (Number.isNaN(first) && Number.isNaN(second)) {
    return 0;
  }

  if (Number.isNaN(first)) {
    return -1;
  }

  if (Number.isNaN(second)) {
    return 1;
  }

  return first - second;
};
