var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "rb-cell-text-input el-input el-input--mini rounded-0" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "input",
        staticClass: "el-input__inner rounded-0",
        attrs: { type: "text", "aria-label": "Input Editor" },
        domProps: { value: _vm.value },
        on: {
          blur: _vm.onBlur,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }