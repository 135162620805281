var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {
    directives: [
      {
        name: "loading",
        rawName: "v-loading",
        value: true,
        expression: "true",
      },
    ],
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: { "element-loading-text": "Loading..." },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }