import AuthLayout from 'src/pages/Account/AuthLayout.vue';

// Product Routes
import reviewboxRoutes from './reviewbox';
import priceboxRoutes from './pricebox';
import searchboxRoutes from './searchbox';
import copyboxRoutes from './copybox';
// import adstationRoutes from './adstation';
import reportsRoutes from './reports';
import downloadsRoutes from './downloads';
import accountRoutes from './account';
import dashboardRoutes from './dashboard';
// import insightsRoutes from './insights';
import catalogManagementRoutes from './catalogManagement';
import error404Page from './error404Page';

const routes = [
  {
    path: '/',
    redirect: '/catalog-management',
    name: 'Home',
  },
  dashboardRoutes,
  // insightsRoutes,
  downloadsRoutes,
  catalogManagementRoutes,
  reportsRoutes,
  reviewboxRoutes,
  priceboxRoutes,
  copyboxRoutes,
  searchboxRoutes,
  // adstationRoutes,
  accountRoutes,
  error404Page,
  {
    path: '/login',
    redirect: '/',
    component: AuthLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'Log In',
        components: {
          default: () => import('src/pages/Account/LogIn.vue'),
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
  },
];

export default routes;
