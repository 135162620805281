import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';
import storage from 'src/utils/localStorageUtil';

// Configure router
const router = new VueRouter({
  routes, // short for routes: routes
  mode: process.env.VUE_APP_NODE_ENV === 'development' ? 'hash' : 'history',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

// Set up redirect to LogIn if user is not
router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.state.account.user !== null;
  const isRequiredLogIn = to.matched.some((m) => m.meta.requiresAuth);

  // Handle login when user didn't have a current session going
  if (!isUserLoggedIn && isRequiredLogIn) {
    next({
      path: '/login',
      query: { redirect: to.path },
    });
    // Handle login when user refreshes session but alread logged in
  } else if (isUserLoggedIn && to.path === '/login') {
    if (Object.prototype.hasOwnProperty.call(to.query, 'redirect')) {
      next({ path: to.query.redirect });
    } else {
      next({ path: from.path });
    }
    // else just continue to the page tring to be accessed
  } else {
    // Check that a user has access to the page, if not redirect to home
    // this means checking activeServices and the parent route meta
    // match up and are allowed
    const activeServices = store.getters['account/getActiveServices'];
    const { meta } = to.matched[0];

    // Allow no meta, means the page is not tied to a specific service
    // or Allow if service is part of user's subscription
    if (
      typeof meta === 'undefined'
      || meta === null
      || activeServices[meta.service]
      || !Object.prototype.hasOwnProperty.call(meta, 'service')
    ) {
      /**
       * If trying to go to dashboards, but has access to insights, direct them to insights dashboard
       * @todo Insights Dashboard: remove this once it is live for all accounts
       */
      // if (to.path === '/dashboard' && store.getters.hasInsightsAccess) {
      //   next({ path: '/insights' });
      // }

      next();
      // Redirect home if unallowable service
    } else {
      next({ path: '/' });
    }
  }
});

// Set up browser document titles after navigating to page
router.afterEach((to) => {
  document.title = `Reviewbox | ${to.name}`;

  if (to.path !== '/login') {
    storage.setLSRoute(to.path);
  }
});

export default router;
