/**
 * Returns a object of account module state.
 *
 * @property {Object} user Object containing user information
 * @property {Object} profile Object containing user profile information
 * @property {Object} plans Object containing plan information
 * @property {Object} adAccounts Object containing Ad Accounts
 * @property {Object} accountIsLoading Object to determine loading status
 *
 * @returns {Object} Default state of account module
 *
 */
export default {
  accountIsLoading: false,
  user: JSON.parse(localStorage.getItem('user')),
  profile: JSON.parse(localStorage.getItem('user-profile')) || {},
  plans: JSON.parse(localStorage.getItem('user-plans')),
  adAccounts: JSON.parse(localStorage.getItem('user-ad-accounts')),
  userPlanSummary: JSON.parse(localStorage.getItem('user-plan-summary')),
  serviceSourceList: JSON.parse(localStorage.getItem('service-source-list')),
  responseTemplates: [],
  profileFilters: JSON.parse(localStorage.getItem('user-profile-filter')) || {
    reviewbox: { gridfilter: null },
    copybox: { gridfilter: null },
    content: { gridfilter: null },
    adstationCampaigns: { gridfilter: null },
    adstationProducts: { gridfilter: null },
    adstationKeywords: { gridfilter: null },
    adstationBrands: { gridfilter: null },
    pricebox: { gridfilter: null },
    sellers: { gridfilter: null },
    reviews: { gridfilter: null },
    questions: { gridfilter: null },
    searchbox: { gridfilter: null },
    searchterms: { gridfilter: null },
  },
};
