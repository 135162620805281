var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rb-alert el-alert is-light", class: _vm.alertClasses },
    [
      _vm.icon
        ? _c(_vm.icon, {
            tag: "component",
            staticClass: "el-alert__icon rb-icon-2x align-self-start",
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "el-alert__content" },
        [
          _c("h6", { staticClass: "font-weight-bold mb-1" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _vm._t("default", function () {
            return [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(" " + _vm._s(_vm.description) + " "),
              ]),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }