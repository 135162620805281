var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "rb-cell-select p-1" },
    [
      _c(
        "el-select",
        {
          ref: "cell-select-typeahead",
          staticClass: "select-default",
          attrs: {
            "popper-class": "rb-cell-editor-autocomplete",
            size: "mini",
            "no-match-text": "No results found.",
            "popper-append-to-body": true,
            multiple: _vm.isMultiple,
            clearable: _vm.isMultiple,
            "collapse-tags": _vm.isMultiple,
            filterable: "",
          },
          on: { "visible-change": _vm.onVisibleChange },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.cellOptions, function (option, optionIndex) {
          return _c("el-option", {
            key: `cell-editor-${option}--${optionIndex}`,
            attrs: { value: option },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }