var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "base-input",
        {
          staticClass: "mb-0",
          attrs: {
            required: _vm.required,
            label: _vm.label,
            name: _vm.name,
            placeholder: _vm.placeholder,
            error: _vm.error,
            disabled: _vm.isDisabled,
            "input-id": _vm.inputId,
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "select-primary",
              attrs: {
                id: `${_vm.label}--${_vm.inputId}`,
                error: _vm.error,
                disabled: _vm.isDisabled,
              },
              model: {
                value: _vm.selectedCloudStorage,
                callback: function ($$v) {
                  _vm.selectedCloudStorage = $$v
                },
                expression: "selectedCloudStorage",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  value: null,
                  disabled: true,
                  label: _vm.placeholder || "Select",
                },
              }),
              _vm._l(_vm.options, function (option, optionIndex) {
                return _c("el-option", {
                  key: optionIndex,
                  attrs: { value: option, label: option.name },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }