import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  /**
   * Sets loading status of grid.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Grid loading status
   */
  setGridLoading(state, payload) {
    const { service, status } = payload;

    state.isGridLoading[service] = status;
  },
  /**
   * Mutation to set state of specific grid.
   *
   * @param {Object} state Grids module state
   * @param {Object} gridState Grid state object
   */
  setGridState(state, gridState) {
    const { service } = gridState;

    state.gridStates[service] = {
      ...state.gridStates[service],
      ...gridState,
    };

    // localStorage.setItem(`gridstate/${service}`, JSON.stringify(gridState));
  },
  /**
   * Mutation to sync a single date range for every grid service that uses a date.
   *
   * @param {Object} state Grids module state
   * @param {Object} dateRange Grids date range {startDate, endDate}
   */
  synchronizeGridDateRange(state, dateRange) {
    Object.keys(state.gridStates)
      .forEach((service) => {
        if (
          state.gridStates[service]
          && Object.prototype.hasOwnProperty.call(state.gridStates[service], 'gridDateRange')
        ) {
          state.gridStates[service].gridDateRange = { ...dateRange };

          // localStorage.setItem(
          //   `gridstate/${service}`,
          //   JSON.stringify(state.gridStates[service]),
          // );
        }
      });
  },
  /**
   * Mutation to bulk update a grid's rows for a service
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object of row data
   */
  bulkUpdateGridRows(state, payload) {
    const { service, rows } = payload;
    const gridRows = state.gridStates[service].rows;

    rows.forEach((row) => {
      const index = gridRows.findIndex((item) => item.agGridRowNumber === row.agGridRowNumber);

      // if index a match, replace old row with new row values
      if (index > -1) {
        gridRows.splice(index, 1, row);
      }
    });
  },
  /**
   * Mutation to update a grid row for a service
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object of row data
   */
  updateSingleGridRow(state, payload) {
    const { service, row, agGridRowNumber } = payload;

    const gridRows = state.gridStates[service].rows;
    const index = gridRows.findIndex((item) => item.agGridRowNumber === agGridRowNumber);
    gridRows.splice(index, 1, row);
  },
  /**
   * Mutation to update a single column header display name.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object of header column info
   */
  updateColumnName(state, payload) {
    const {
      service,
      field,
      newValue,
      columnGroup,
    } = payload;
    const gridState = state.gridStates[service];

    // Get groupColumn index
    const groupColumnIndex = gridState.columns
      .findIndex((group) => group.groupId === columnGroup);

    // To get childColumn index
    const childColumnIndex = gridState.columns[groupColumnIndex].children
      .findIndex((column) => column.field === field);

    // To set childColumn headerName
    gridState.columns[groupColumnIndex].children[childColumnIndex].headerName = newValue;

    // Update state
    state.gridStates[service] = {
      ...state.gridStates[service],
      ...gridState,
    };
  },
  /**
   * Mutation to remove state of specific grid.
   *
   * @param {Object} state Grids module state
   * @param {String} service Name of grid associated with a service
   */
  removeGridState(state, service) {
    if (Object.prototype.hasOwnProperty.call(state.gridsDefaultDateRange, service)) {
      state.gridStates[service] = {
        gridDateRange: { ...state.gridsDefaultDateRange[service] },
      };
    } else {
      state.gridStates[service] = null;
    }
  },
  /**
   * Mutation to remove all grid states per service.
   *
   * @param {Object} state Grids module state
   */
  removeAllGridState(state) {
    Object.keys(state.gridStates)
      .forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(state.gridsDefaultDateRange, key)) {
          state.gridStates[key] = {
            gridDateRange: { ...state.gridsDefaultDateRange[key] },
          };
        } else {
          state.gridStates[key] = null;
        }
      });
  },
  /**
   * Removes a single grid state
   *
   * @param {Object} state Grids module state
   * @param {String} service Name of service associated with grid state
   */
  resetGridStateByKey(state, service) {
    if (Object.prototype.hasOwnProperty.call(state.gridsDefaultDateRange, service)) {
      Vue.set(state.gridStates, service, { gridDateRange: { ...state.gridsDefaultDateRange[service] } });
    } else {
      Vue.set(state.gridStates, service, null);
    }
  },
  /**
   * Mutation to append additional values to reviews' topic column.
   *
   * @param {Object} state Grids state module
   * @param {Array} topics Array of tpoic values
   */
  updateCustomTopics(state, payload) {
    const { topics, service } = payload;

    topics.forEach((topic) => {
      state.columnCellEditors[service.toLowerCase()].topic.cellEditorParams.values
        .splice(-1, 0, topic);
    });
  },
  /**
   * Mutation to append additional values to reviews' customCol column.
   *
   * @param {Object} state Grids state module
   * @param {Array} topics Array of tpoic values
   */
  updateCustomColValues(state, payload) {
    const { values, service } = payload;

    values.forEach((value) => {
      state.columnCellEditors[service.toLowerCase()].customCol.cellEditorParams.values
        .splice(-1, 0, value);
    });
  },
  /**
   * Mutation to add values for a dropdown column by specified service and field.
   *
   * @param {Object} state Grids state module
   * @param {Object} payload Value object {service, field, valuesToAdd}
   */
  addDropdownColumnValues(state, payload) {
    const { service, field, valuesToAdd } = payload;
    const currentValues = state.columnCellEditors[service][field].cellEditorParams.values.slice();

    valuesToAdd.forEach((value) => {
      // Don't add duplicates
      if (currentValues.indexOf(value) < 0) {
        state.columnCellEditors[service][field].cellEditorParams.values.push(value);
      }
    });
    let sorted = state.columnCellEditors[service][field].cellEditorParams.values;
    sorted = sorted.slice(5).sort((a, b) => a.localeCompare(b));
    // eslint-disable-next-line prefer-const
    let unsorted = state.columnCellEditors[service][field].cellEditorParams.values;
    // eslint-disable-next-line prefer-const
    let toAdd = unsorted.slice(0, 5).concat(sorted);
    state.columnCellEditors[service][field].cellEditorParams.values = toAdd;
  },
  /**
   * Mutation to remove values for a dropdown column by specified service and field.
   *
   * @param {Object} state Grids state module
   * @param {Object} payload Value object {service, field, valuesToAdd}
   */
  removeDropdownColumnValues(state, payload) {
    const { service, field, valuesToDelete } = payload;
    const currentValues = state.columnCellEditors[service][field].cellEditorParams.values.slice();

    valuesToDelete.forEach((value) => {
      const index = currentValues.indexOf(value);
      // Don't remove values not present
      if (index >= 0) {
        currentValues.splice(index, 1);
        state.columnCellEditors[service][field].cellEditorParams.values.splice(index, 1);
      }
    });
  },
  /**
   * Mutation to reset reviews/questions customCol column values to default.
   *
   * @param {Object} state Grids state module
   * @param {Object} payload Values to pass for updating grids {service}
   */
  resetCustomColValues(state, payload) {
    const { service } = payload;
    state.columnCellEditors[service].customCol.cellEditorParams.values = [];
  },
  /**
   * Mutation to add a grid's filters for a service.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object with service and filter to add
   */
  addGridFilters(state, payload) {
    const { service } = payload;

    // Join current saved filters with new filter
    if (payload.name !== '__profile_filter__') {
      const { filterType } = payload;

      if (filterType === 'teamFilter') {
        state.gridStates[service.toLowerCase()].savedFilters.team_filters.push(payload);
      } else if (filterType === 'userFilter') {
        // If an index exists, update and don't duplicate the entry
        const index = state.gridStates[service.toLowerCase()].savedFilters.user_filters.findIndex((f) => f.name === payload.name);
        if (index > -1) {
          state.gridStates[service.toLowerCase()].savedFilters.user_filters[index] = payload;
        } else {
          state.gridStates[service.toLowerCase()].savedFilters.user_filters.push(payload);
        }
      }
    }
  },
  /**
   * Mutation to save a grid's filters for a service.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object with service and filter to add
   */
  saveGridFilters(state, payload) {
    state.filterStates[payload.service.toLowerCase()] = {
      filters: payload.filters,
      sort: payload.sort,
    };
    sessionStorage.setItem(`filterstate/${payload.service.toLowerCase()}`, JSON.stringify({ filters: payload.filters, sort: payload.sort }));
  },
  /**
   * Mutation to clear a grid's filters for a service.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object with service and filter to add
   */
  clearGridFilters(state, payload) {
    state.filterStates[payload.service] = { filters: [], sort: [] };
    sessionStorage.removeItem(`filterstate/${payload.service}`);
  },
  /**
   * Mutation to update a grid's filters for a service.
   *
   * @param {Object} state Grids module state
   * @param {Object} payload Object with service and filter to add
   */
  removeGridFilters(state, payload) {
    const { service, name, type } = payload;
    const savedFilters = cloneDeep(state.gridStates[service].savedFilters);

    if (state.gridStates[service].savedFilters) {
      const key = type === 'teamFilter' ? 'team_filters' : 'user_filters';

      savedFilters[key] = savedFilters[key].filter((item) => item.name !== name);
    }

    // Re-assign updated saved filters to grid state
    state.gridStates[service].savedFilters = savedFilters;
  },
  /**
   * Mutate the boolean flag for displaying the Response Drawer
   */
  setShowResponseDrawer(state, value) {
    state.showResponseDrawer = value;
  }
};
