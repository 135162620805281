export const chartColors = {
  tooltips: {
    lightGray: '#f5f5f5',
    darkGray: '#333',
    gray: '#666',
  },
  gridLines: {
    blue0: 'rgba(29,140,248,0.1)',
    blue10: 'rgba(29,140,248,0.1)',
    green10: 'rgba(0,242,195,0.1)',
    pink10: 'rgba(253,93,147,0.1)',
    purple10: 'rgba(225,78,202,0.1)',
    red10: 'rgba(220,53,69,0.1)',
    white10: 'rgba(255, 255, 255, 0.1)',
    white5: 'rgba(255, 255, 255, 0.05)',
  },
  ticks: {
    gray: '#9e9e9e',
    blue: '#2380f7',
  },
  defaultColors: {
    green: '#42dca3',
    red: '#eC6353',
    blue: '#40a7d5',
    yellow: '#f9BF5D',
    purple: '#8965e0',
    mossGreen: '#adb993',
    pink: '#f79d84',
    mutedBlue: '#9BC1BC',
    darkYellow: '#9f7a3b',
    indigo: '#5603ad',
  },

  convertColor(color, factor, conversionFunction) {
    /**
     * Takes a hex color and returns a converted version of the color based on
     * the factor and the conversion function (tint or shade).
     *
     * @param {string} color value in hex
     * @param {number} factor factor amount to change the color
     * @param {function} conversionFunction tint or shade conversion
     *
     * @returns {string} hex value of the newly converted color
     */
    const parsedHexColor = color.match(/[^#]{2}/g);

    const convertedColor = parsedHexColor.map((value) => {
      const valueToInt = parseInt(value, 16);
      const convertedValue = conversionFunction(valueToInt, factor);
      const rounded = Math.round(convertedValue);
      return rounded.toString(16).padStart(2, '0');
    });

    return `#${convertedColor.join('')}`;
  },

  getColorTint(value, tintFactor) {
    return value + ((255 - value) * tintFactor);
  },

  getColorShade(value, shadeFactor) {
    return value * shadeFactor;
  },

  getListOfChartColors(numberOfColors, colors = null) {
    /**
     * Returns a list of n colors based on the colorList.
     * It will initially darken the colors until it reaches #000000 then will
     * lighted the colors.
     *
     * Note: This function is only good for 10 times the number of colors in the
     * colorList parameter.
     *
     * @param {number} numberOfColors to return
     * @param {Array, Object} colors list of colors to base chart colors on. Can be
     *  an array of hex values or a on object with colorName: hexValue pairs.
     */
    const colorList = colors || this.defaultColors;
    const colorValues = colors === Array ? colorList : Object.values(colorList);

    return Array(numberOfColors).fill().map((_, i) => {
      const colorIndex = i % colorValues.length;
      const factor = Math.floor(i / colorValues.length) / 10;

      if (factor < 1) {
        const shadeFactor = 1 - factor;
        return this.convertColor(
          colorValues[colorIndex], shadeFactor, this.getColorShade,
        );
      }
      const tintFactor = factor * 2; // Step by 0.2 so that there is more color variation
      return this.convertColor(
        colorValues[colorIndex], tintFactor, this.getColorTint,
      );
    });
  },
};

export const basicOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  responsive: true,
};

export const tooltipOptions = {
  backgroundColor: chartColors.tooltips.lightGray,
  titleFontColor: chartColors.tooltips.darkGray,
  bodyFontColor: chartColors.tooltips.gray,
  bodySpacing: 4,
  xPadding: 12,
  mode: 'nearest',
  intersect: 0,
  position: 'nearest',
};

const colorChartOptions = (gridLineColor, tickFontColor) => (
  {
    ...basicOptions,
    tooltips: { ...tooltipOptions },
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: gridLineColor.y,
            zeroLineColor: 'transparent',
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: tickFontColor.y,
          },
        },
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: gridLineColor.x,
            zeroLineColor: 'transparent',
          },
          ticks: {
            padding: 20,
            fontColor: tickFontColor.x,
          },
        },
      ],
    },
  }
);

export const blueChartOptions = colorChartOptions(
  { x: chartColors.gridLines.blue10, y: chartColors.gridLines.blue0 },
  { x: chartColors.ticks.blue, y: chartColors.ticks.blue },
);

export const purpleChartOptions = colorChartOptions(
  { x: chartColors.gridLines.purple10, y: chartColors.gridLines.blue0 },
  { x: chartColors.ticks.gray, y: chartColors.ticks.gray },
);

export const orangeChartOptions = colorChartOptions(
  { x: chartColors.gridLines.red10, y: chartColors.gridLines.blue0 },
  { x: chartColors.ticks.orange, y: chartColors.ticks.orange },
);

export const greenChartOptions = colorChartOptions(
  { x: chartColors.gridLines.green10, y: chartColors.gridLines.blue0 },
  { x: chartColors.ticks.gray, y: chartColors.ticks.gray },
);

export const lineChartOptionsBlue = {
  ...basicOptions,
  tooltips: { ...tooltipOptions },
  scales: {
    yAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.blue0,
          zeroLineColor: 'transparent',
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],

    xAxes: [
      {
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.blue10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],
  },
};

export const barChartOptionsGradient = {
  ...basicOptions,
  tooltips: { ...tooltipOptions },
  responsive: true,
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.pink10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],

    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.pink10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],
  },
};

export const pieChartOptions = {
  ...basicOptions,
  cutoutPercentage: 70,
  legend: {
    position: 'right',
    display: true,
  },
  tooltips: { ...tooltipOptions },
  scales: {
    yAxes: [
      {
        display: 0,
        ticks: {
          display: false,
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: 'transparent',
          color: chartColors.gridLines.white5,
        },
      },
    ],

    xAxes: [
      {
        display: 0,
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.white10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          display: false,
        },
      },
    ],
  },
};

export const barChartOptions = {
  ...basicOptions,
  tooltips: { ...tooltipOptions },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.blue10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 120,
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: chartColors.gridLines.blue10,
          zeroLineColor: 'transparent',
        },
        ticks: {
          padding: 20,
          fontColor: chartColors.ticks.gray,
        },
      },
    ],
  },
};
