import cloneDeep from 'lodash/cloneDeep';

export default {
  /**
   * Method to set the tab history for a parent page.
   * This is used to redirect a user to the last tab viewed
   * whenever they re-visit the parent page.
   *
   * @param {Object} state Tabs store state object
   * @param {String} service Parent page
   * @param {String} name Route name
   * @param {String} path Route path
   */
  setTabHistory(state, { service, name, path }) {
    const tabsInfo = { name, path };
    const tabsHistory = cloneDeep(state.tabsHistory);
    tabsHistory[service] = tabsInfo;
    state.tabsHistory = tabsHistory;

    localStorage.setItem('tabs/history', JSON.stringify(state.tabsHistory));
  },
  /**
   * Mutation to remove current saved tabs history
   * and result to default values.
   *
   * @param {Object} state Tabs store state object
   */
  removeTabsHistory(state) {
    localStorage.removeItem('tabs/history');

    state.tabsHistory = {
      reviewbox: {
        name: 'Reviews',
        path: '/reviewbox/reviews',
      },
      pricebox: {
        name: 'Pricebox Products',
        path: '/pricebox/products',
      },
      copybox: {
        name: 'Content Latest',
        path: '/copybox/content-latest',
      },
      searchbox: {
        name: 'Searchbox Products',
        path: '/searchbox/products',
      },
      adstation: {
        name: 'Campaigns',
        path: '/adstation/campaigns',
      },
      account: {
        name: 'Profile',
        path: '/account/profile',
      },
    };
  },
};
