import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/reviewbox',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    service: 'reviewbox',
    module: 'reviewbox',
  },
  children: [
    {
      path: '',
      name: 'Reviewbox',
      redirect: '/reviewbox/reviews',
      meta: {
        requiresAuth: true,
        service: 'reviewbox',
        module: 'reviewbox',
      },
      components: {
        default: () => import('src/pages/Reviewbox/ReviewboxLayout.vue'),
      },
      children: [
        {
          path: 'reviews',
          name: 'Reviewbox Reviews',
          label: 'Reviews',
          meta: {
            requiresAuth: true,
            service: 'reviewbox',
            module: 'reviewbox',
          },
          components: {
            default: () => import('src/pages/Reviewbox/Reviews/Reviews.vue'),
          },
        },
        {
          path: 'products',
          name: 'Reviewbox Products',
          label: 'Products',
          meta: {
            requiresAuth: true,
            service: 'reviewbox',
            module: 'reviewbox',
          },
          components: {
            default: () => import('src/pages/Reviewbox/Reviewbox.vue'),
          },
        },
        {
          path: 'questions',
          name: 'Reviewbox Questions',
          label: 'Questions',
          meta: {
            requiresAuth: true,
            service: 'reviewbox',
            module: 'reviewbox',
          },
          components: {
            default: () => import('src/pages/Reviewbox/Questions/Questions.vue'),
          },
        },
        // @todo
        // - Refactor this route into the Catalog Management updates because currently
        //   it doesn't work in the app. This was part of the legacy app that got ported
        //   over.
        // {
        //   path: 'product-info',
        //   name: 'Product Info',
        //   meta: {
        //     requiresAuth: true,
        //     service: 'reviewbox',
        //     module: 'reviewbox',
        //   },
        //   components: {
        //     default: () => import('src/pages/ProductInfo/ProductInfo.vue'),
        //   },
        // },
      ],
    },
  ],
};
