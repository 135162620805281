import { render, staticRenderFns } from "./RadioGroup.vue?vue&type=template&id=6a2b4921&"
import script from "./RadioGroup.vue?vue&type=script&lang=js&"
export * from "./RadioGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6a2b4921')) {
      api.createRecord('6a2b4921', component.options)
    } else {
      api.reload('6a2b4921', component.options)
    }
    module.hot.accept("./RadioGroup.vue?vue&type=template&id=6a2b4921&", function () {
      api.rerender('6a2b4921', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Reports/Components/FormInputs/RadioGroup.vue"
export default component.exports