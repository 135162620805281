import ReviewboxHttp from './ReviewboxHttp';
import PriceboxHttp from './PriceboxHttp';
import DetailedPriceboxHttp from './DetailedPriceboxHttp';
import SearchboxTermsHttp from './SearchboxTermsHttp';
import SearchboxProductsHttp from './SearchboxProductsHttp';
import CopyboxLatestHttp from './CopyboxLatestHttp';
import CopyboxReferenceHttp from './CopyboxReferenceHttp';
import AdstationHttp from './AdstationHttp';
import ReviewsHttp from './ReviewsHttp';
import QuestionsHttp from './QuestionsHttp';
import SellersHttp from './SellersHttp';
import DetailedSellersHttp from './DetailedSellersHttp';

/**
 * Helper Grid class to fetch, parse, and clean grid data to be used in ag-grid.
 */
class GridHttp {
  constructor() {
    this.gridDataFetchers = {
      reviewbox: ReviewboxHttp,
      pricebox: PriceboxHttp,
      detailedpricebox: DetailedPriceboxHttp,
      searchterms: SearchboxTermsHttp,
      searchbox: SearchboxProductsHttp,
      copybox: CopyboxLatestHttp,
      content: CopyboxReferenceHttp,
      adstation: AdstationHttp,
      reviews: ReviewsHttp,
      questions: QuestionsHttp,
      sellers: SellersHttp,
      detailedsellers: DetailedSellersHttp,
    };
  }

  async getGridData(service, params) {
    return this.gridDataFetchers[service].getGridData(params);
  }

  /**
   * Get the default grid columns.
   */
  getGridColumns(service) {
    return this.gridDataFetchers[service].getDefaultColumns();
  }
}

export default new GridHttp();
