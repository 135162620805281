var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rb-sidenav d-flex flex-column" }, [
    _c("div", { staticClass: "rb-sidenav-wrapper" }, [
      _c(
        "div",
        {
          staticClass:
            "rb-logo-nav d-flex justify-content-center align-items-center",
        },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "rb-logo-img",
              attrs: {
                src: require("@/assets/images/logo_40w.png"),
                alt: "app-logo",
              },
            }),
          ]),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "rb-sidenav-list" },
        _vm._l(_vm.links, function (link, index) {
          return _c("sidenav-item", { key: index, attrs: { link: link } })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }