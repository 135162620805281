export default {
  /**
   * Getter to return the Chart Height from the reviews store.
   *
   * @param {Object} state Reviews store data
   */
  getChartsHeight(state) {
    return state.chartsHeight;
  },
  /**
   * Getter to return the showCharts boolean flag.
   *
   * @param {Object} state Reviews store data
   */
  getShowCharts(state) {
    const localState = state.priceboxShowCharts;
    if (localState === null) {
      return false;
    }
    return localState;
  },
  /**
   * Getter to return the selected row cache.
   *
   * @param {Object} state Reviews store data
   */
  getSelectCache(state) {
    const localState = state.priceboxSelectCache;
    if (localState === null || localState === undefined) {
      return null;
    }
    return localState;
  },
};
