import BaseHttp from './BaseHttp';

class ReportsHttp extends BaseHttp {
  /**
   * Fetches an active list of reports/exports from mongo_reports
   */
  async getReports() {
    return this.get(`${this.PrependRoutes.reviewbox}/livereports`)
      .then((response) => response.data.reports);
  }

  /**
   * Fetches an a report's/export's edit form options
   */
  async getEditReportOptions(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/editreport`, params)
      .then((response) => response.data);
  }

  /**
   * POST request to create a new export/report.
   */
  async postReport(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/livereports`, params)
      .then((response) => response.data);
  }

  /**
   * Deletes an active report/export from mongo_reports
   *
   * @param {Object} params HTTP params object
   */
  async deleteReport(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/livereports`, params);
  }

  /**
   * Posts a request to download a report/export
   *
   * @param {Object} params HTTP params object, report info for downloading a report
   */
  async downloadReport(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/report`, params);
  }

  /**
   * Posts a request to get a report's visualizations.
   *
   * @param {Object} params HTTP params object, report info for downloading a report
   */
  async getReportView(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/viewreport`, params)
      .then((response) => response.data.report);
  }

  /**
   * Fetches a request to get a list of accounts shared to view a report.
   *
   * @param {Object} params HTTP params object, { report_id }
   */
  async getSharedAccounts(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/sharedreport`, params)
      .then((response) => response.data);
  }

  /**
   * Posts a request to add an email for sharing a report.
   *
   * @param {Object} params HTTP params object, { report_id }
   */
  async postSharedReportEmail(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/sharedreport`, params)
      .then((response) => response.data);
  }

  /**
   * Posts a request to refresh a shared report's expiration timestamp.
   *
   * @param {Object} params HTTP params object, { report_id }
   */
  async postRefreshSharedReportExpirationTimestamp(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/sharedexpiration`, params)
      .then((response) => response.data);
  }

  /**
   * Delete request to remove an email for sharing a report.
   *
   * @param {Object} params HTTP params object, { report_id }
   */
  async deleteSharedReportEmail(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/sharedreport`, params)
      .then((response) => response.data);
  }

  /**
   * Get request for report/export form options
   */
  async getReportFormOptions() {
    return this.get(`${this.PrependRoutes.reviewbox}/reportoptions`)
      .then((response) => {
        const reportOptions = response.data.report_options || [];

        // Split report options into reports and export groups
        const exports = [];
        const reports = [];
        reportOptions.forEach((option) => {
          if (option.is_advanced_report) {
            reports.push(option);
          } else {
            exports.push(option);
          }
        });

        return {
          reportOptions: { exports, reports },
          reportUnitsTotal: response.data.report_units.total || 0,
          reportUnitsCount: response.data.report_units.count || 0,
        };
      });
  }

  /**
   * Async function to fetch listing data for reports creation.
   *
   * @param {Object} params HTTP get params
   */
  async getProducts(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/products`, params)
      .then((response) => response.data);
  }
}

export default new ReportsHttp();
