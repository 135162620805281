// Import Utils
import { dateRanges, dateTimeReviver } from '@/utils';
import { GridHttp } from '@/services/api';

// Global Grid Framework Component Keyys
import { GRID_FRAMEWORK_COMPONENTS_KEYS } from '@/components/Grid/CellRenderers';

/**
 * @property {Object} gridStates Ag-grid states for a service
 * @property {Object} downloadOptions An object of arrays for download options per service
 * @property {Object} isGridLoading Object of grid loading states per service
 * @property {Object} columnCellRenderers Object of grid cell renderers per service
 * @property {Object} columnCellEditors Object of grid cell editors per service
 */
export default {
  // default columns per service.
  defaultColumns: {
    pricebox: GridHttp.getGridColumns('pricebox'),
    sellers: GridHttp.getGridColumns('sellers'),
    detailedsellers: GridHttp.getGridColumns('detailedsellers'),
    detailedpricebox: GridHttp.getGridColumns('detailedpricebox'),
  },
  // filter states per service
  filterStates: {
    reviewbox: JSON.parse(sessionStorage.getItem('filterstate/reviewbox')) || {
      filters: null,
      sort: null,
    },
    copybox: JSON.parse(sessionStorage.getItem('filterstate/copybox')) || {
      filters: null,
      sort: null,
    },
    content: JSON.parse(sessionStorage.getItem('filterstate/content')) || {
      filters: null,
      sort: null,
    },
    pricebox: JSON.parse(sessionStorage.getItem('filterstate/pricebox')) || {
      filters: null,
      sort: null,
    },
    sellers: JSON.parse(sessionStorage.getItem('filterstate/sellers')) || {
      filters: null,
      sort: null,
    },
    reviews: JSON.parse(sessionStorage.getItem('filterstate/reviews')) || {
      filters: null,
      sort: null,
    },
    questions: JSON.parse(sessionStorage.getItem('filterstate/questions')) || {
      filters: null,
      sort: null,
    },
    searchbox: JSON.parse(sessionStorage.getItem('filterstate/searchbox')) || {
      filters: null,
      sort: null,
    },
    searchterms: JSON.parse(
      sessionStorage.getItem('filterstate/searchterms')
    ) || { filters: null, sort: null },
  },
  // ag-grid grid states per service
  gridStates: {
    reviewbox: JSON.parse(
      localStorage.getItem('gridstate/reviewbox'),
      dateTimeReviver
    ),
    copybox: JSON.parse(
      localStorage.getItem('gridstate/copybox'),
      dateTimeReviver
    ),
    content: JSON.parse(
      localStorage.getItem('gridstate/content'),
      dateTimeReviver
    ),
    pricebox: JSON.parse(
      localStorage.getItem('gridstate/pricebox'),
      dateTimeReviver
    ) || {
      gridDateRange: {
        startDate: dateRanges.options.last7Days[0],
        endDate: dateRanges.options.last7Days[1],
      },
    },
    sellers: JSON.parse(
      localStorage.getItem('gridstate/sellers'),
      dateTimeReviver
    ) || {
      gridDateRange: {
        startDate: dateRanges.options.last7Days[0],
        endDate: dateRanges.options.last7Days[1],
      },
    },
    detailedsellers: JSON.parse(
      localStorage.getItem('gridstate/detailedsellers'),
      dateTimeReviver
    ) || {
      gridDateRange: {
        startDate: dateRanges.options.last7Days[0],
        endDate: dateRanges.options.last7Days[1],
      },
    },
    reviews: JSON.parse(
      localStorage.getItem('gridstate/reviews'),
      dateTimeReviver
    ) || {
      gridDateRange: {
        startDate: dateRanges.options.last7Days[0],
        endDate: dateRanges.options.last7Days[1],
      },
    },
    questions: JSON.parse(
      localStorage.getItem('gridstate/questions'),
      dateTimeReviver
    ) || {
      gridDateRange: {
        startDate: dateRanges.options.last7Days[0],
        endDate: dateRanges.options.last7Days[1],
      },
    },
    searchbox: JSON.parse(
      sessionStorage.getItem('gridstate/searchbox'),
      dateTimeReviver
    ),
    searchterms: JSON.parse(
      sessionStorage.getItem('gridstate/searchterms'),
      dateTimeReviver
    ),
  },
  // ag-grid loading states per service
  isGridLoading: {
    // adstation: false,
    reviewbox: false,
    reviews: false,
    questions: false,
    pricebox: false,
    sellers: false,
    copybox: false,
    content: false,
    searchbox: false,
    searchterms: false,
  },
  /**
   * export/report download options per service
   *
   * @todo Refactor this, we shouldn't have to hard-code download
   * options, since we'll want to continually add new exports dynamically.
   * This should get migrated to the back-end and should match the inputs
   * provided from the report builder.
   */
  downloadOptions: {
    reviewbox: [
      {
        label: 'Review Summary Export',
        value: 'ProductReviewsSummaryExport',
        form: null,
        icon: 'cloud-download-icon',
        tooltip:
          '<p>This export provides a summary of the reviews and questions for each product listing.</p><p>Each row corresponds to a product listing on a retailer and each column shows information about that listing.</p>',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    reviews: [
      {
        label: 'Review Details Export',
        value: 'BulkReviewsExport',
        form: [
          {
            type: 'select',
            label: 'Filter by rating',
            name: 'exportStars',
            options: [
              { name: 'No Filter', value: null },
              { name: 'Fewer than 2 stars', value: 1 },
              { name: 'Fewer than 3 stars', value: 2 },
              { name: 'Fewer than 4 stars', value: 3 },
              { name: 'Fewer than 5 stars', value: 4 },
            ],
            placeholder: 'Select',
            required: false,
            value: null,
          },
          {
            type: 'select',
            label: 'Filter by case status',
            name: 'exportStatus',
            options: [
              { name: 'No Filter', value: null },
              { name: 'Not Assigned', value: 'Not Assigned' },
              { name: 'Open', value: 'Open' },
              { name: 'In Dispute', value: 'In Dispute' },
              { name: 'Resolved', value: 'Resolved' },
              { name: 'Closed', value: 'Closed' },
            ],
            placeholder: 'Select',
            required: false,
            value: null,
          },
          {
            type: 'select',
            label: 'Filter by verified',
            name: 'exportVerified',
            options: [
              { name: 'No Filter', value: null },
              { name: 'Is Verified Review', value: true },
              { name: 'Is Not Verified Review', value: false },
            ],
            placeholder: 'Select',
            required: false,
            value: null,
          }
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides a list of all reviews for each product listing within the specified time range.',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    questions: [
      {
        label: 'Question Details Export',
        value: 'BulkQuestionsExport',
        form: [
          {
            type: 'select',
            label: 'Filter by case status',
            name: 'exportStatus',
            options: [
              { name: 'No Filter', value: null },
              { name: 'Not Assigned', value: 'Not Assigned' },
              { name: 'Open', value: 'Open' },
              { name: 'In Dispute', value: 'In Dispute' },
              { name: 'Resolved', value: 'Resolved' },
              { name: 'Closed', value: 'Closed' },
            ],
            placeholder: 'Select',
            required: false,
            value: null,
          }
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides a list of all questions for each product listing within the specified time range.',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    pricebox: [
      {
        label: 'Buy Box Details Export',
        value: 'BuyboxExport',
        form: [
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'summary' },
              { name: 'Raw', value: 'detailed' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: {
              name: 'Daily',
              value: 'summary',
            },
            instruction: 'Select how detailed you want the export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides a summary of the Buy Box price and owner, MAP violations, and number of sellers for each product listing.',
      },
      {
        label: 'Sales Rank Details Export',
        value: 'SalesRankExport',
        form: [
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'summary' },
              { name: 'Raw', value: 'detailed' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: {
              name: 'Daily',
              value: 'summary',
            },
            instruction: 'Select how detailed you want the export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides detailed information about the Amazon sales rank (bestsellers rank) of each product listing in multiple product categories.',
      },
      {
        label: 'Seller Details Export',
        value: 'SellerPricesExport',
        form: [
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'summary' },
              { name: 'Raw', value: 'detailed' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: { name: 'Daily', value: 'summary' },
            instruction: 'Select how detailed you want the export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides detailed data on the sellers and their prices for each product listing.',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    sellers: [
      {
        label: 'Seller Details Export',
        value: 'SellerPricesExport',
        form: [
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'summary' },
              { name: 'Raw', value: 'detailed' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: { name: 'Daily', value: 'summary' },
            instruction: 'Select how detailed you want the export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides detailed data on the sellers and their prices for each product listing.',
      },
      {
        label: 'Seller Summary Export',
        value: 'SellerSummaryExport',
        form: null,
        icon: 'cloud-download-icon',
        tooltip:
          '<p>This export provides a list of all sellers, including 3rd-party sellers, that are offering your products during the specified time frame.</p><p>Additional columns provide an overview of the seller rating, MAP violations, and number of products offered.</p>',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    copybox: [
      {
        label: 'Content Export',
        value: 'CopyboxContentExport',
        form: [
          {
            type: 'dateRangeSelect',
            label: 'Date Range',
            name: 'exportDateRange',
            placeholder: 'Select a date range',
            required: true,
            value: null,
            instruction: 'Range of dates to filter export on',
          },
          {
            type: 'select',
            label: 'Content Type',
            name: 'exportContentType',
            options: [
              { name: 'Current Content', value: 'latest' },
              { name: 'Reference Content', value: 'canonical' },
            ],
            required: true,
            value: { name: 'Current Content', value: 'latest' },
            instruction: 'Select the type of content you want to receive',
          },
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Content Changes Only', value: 'summary' },
              { name: 'Daily Content', value: 'detailed' },
            ],
            placeholder: 'Select a data resolution',
            required: true,
            value: { name: 'Content Changes Only', value: 'summary' },
            instruction: 'Select detail of export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides information about the content displayed in each product listing and indicates whether that content has changed.',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    content: [
      {
        label: 'Content Export',
        value: 'CopyboxContentExport',
        form: [
          {
            type: 'dateRangeSelect',
            label: 'Date Range',
            name: 'exportDateRange',
            placeholder: 'Select a date range',
            required: true,
            value: null,
            instruction: 'Range of dates to filter export on',
          },
          {
            type: 'select',
            label: 'Content Type',
            name: 'exportContentType',
            options: [
              { name: 'Current Content', value: 'latest' },
              { name: 'Reference Content', value: 'canonical' },
            ],
            required: true,
            value: { name: 'Current Content', value: 'latest' },
            instruction: 'Select the type of content you want to receive',
          },
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Content Changes Only', value: 'summary' },
              { name: 'Daily Content', value: 'detailed' },
            ],
            placeholder: 'Select a data resolution',
            required: true,
            value: { name: 'Content Changes Only', value: 'summary' },
            instruction: 'Select detail of export',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          'This export provides information about the content displayed in each product listing and indicates whether that content has changed.',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    searchbox: [
      {
        label: 'Search Rank Details Export',
        value: 'SearchRankExport',
        form: [
          {
            type: 'dateRangeSelect',
            label: 'Date Range',
            name: 'exportDateRange',
            placeholder: 'Select a date range',
            required: true,
            value: null,
            instruction: 'Range of dates to filter export on',
          },
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'daily' },
              { name: 'Weekly', value: 'weekly' },
              { name: 'Raw', value: 'raw' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: { name: 'Daily', value: 'daily' },
            instruction: 'Select how detailed you want for export',
          },
          {
            type: 'select',
            label: 'Search Result Type',
            name: 'exportRType',
            options: [
              {
                name: 'Organic Products Only',
                value: 'organic',
              },
              {
                name: 'Sponsored Products Only',
                value: 'sponsored',
              },
              {
                name: 'Organic & Sponsored',
                value: 'all',
              },
            ],
            placeholder: 'Select result type',
            required: true,
            value: { name: 'Organic Products Only', value: 'organic' },
            instruction: 'Select result type you want for export',
          },
          {
            type: 'select',
            label: 'Which products to export',
            name: 'exportRegisteredProducts',
            options: [
              { name: 'Export Only Your Products', value: 'true' },
              { name: 'Export All Products', value: 'false' },
            ],
            placeholder: 'Select which products',
            required: true,
            value: { name: 'Export Only Your Products', value: 'true' },
            instruction: 'Select which products for export',
          },
          {
            type: 'select',
            label: 'Maximum Page Results',
            name: 'exportMaximumPageResults',
            options: [
              { name: '1st Page', value: '1' },
              { name: '2nd Page', value: '2' },
              { name: '3rd Page', value: '3' },
              { name: '4th Page', value: '4' },
              { name: '5th Page', value: '5' },
              { name: '6th Page', value: '6' },
              { name: '7th Page', value: '7' },
              { name: '8th Page', value: '8' },
              { name: '9th Page', value: '9' },
              { name: '10th Page', value: '10' },
            ],
            placeholder: 'Select the max page results',
            required: true,
            value: { name: '1st Page', value: '1' },
            instruction: 'Page to aggregate share of voice',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          '<p>This export provides detailed information about every product listing that is returned by a particular search at a particular date and time.</p><p>There are separate exports for organic search results and for sponsored listings.</p>',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
    searchterms: [
      {
        label: 'Search Rank Details Export',
        value: 'SearchRankExport',
        form: [
          {
            type: 'dateRangeSelect',
            label: 'Date Range',
            name: 'exportDateRange',
            placeholder: 'Select a date range',
            required: true,
            value: null,
            instruction: 'Range of dates to filter export on',
          },
          {
            type: 'select',
            label: 'Data Resolution',
            name: 'exportDataResolution',
            options: [
              { name: 'Daily', value: 'daily' },
              { name: 'Weekly', value: 'weekly' },
              { name: 'Raw', value: 'raw' },
            ],
            placeholder: 'Select resolution',
            required: true,
            value: { name: 'Daily', value: 'daily' },
            instruction: 'Select how detailed you want for export',
          },
          {
            type: 'select',
            label: 'Search Result Type',
            name: 'exportRType',
            options: [
              {
                name: 'Organic Products Only',
                value: 'organic',
              },
              {
                name: 'Sponsored Products Only',
                value: 'sponsored',
              },
              {
                name: 'Organic & Sponsored',
                value: 'all',
              },
            ],
            placeholder: 'Select result type',
            required: true,
            value: { name: 'Organic Products Only', value: 'organic' },
            instruction: 'Select result type you want for export',
          },
          {
            type: 'select',
            label: 'Which products to export',
            name: 'exportRegisteredProducts',
            options: [
              { name: 'Export Only Your Products', value: 'true' },
              { name: 'Export All Products', value: 'false' },
            ],
            placeholder: 'Select which products',
            required: true,
            value: { name: 'Export Only Your Products', value: 'true' },
            instruction: 'Select which products for export',
          },
          {
            type: 'select',
            label: 'Maximum Page Results',
            name: 'exportMaximumPageResults',
            options: [
              { name: '1st Page', value: '1' },
              { name: '2nd Page', value: '2' },
              { name: '3rd Page', value: '3' },
              { name: '4th Page', value: '4' },
              { name: '5th Page', value: '5' },
              { name: '6th Page', value: '6' },
              { name: '7th Page', value: '7' },
              { name: '8th Page', value: '8' },
              { name: '9th Page', value: '9' },
              { name: '10th Page', value: '10' },
            ],
            placeholder: 'Select the max page results',
            required: true,
            value: { name: '1st Page', value: '1' },
            instruction: 'Page to aggregate share of voice',
          },
        ],
        icon: 'cloud-download-icon',
        tooltip:
          '<p>This export provides detailed information about every product listing that is returned by a particular search at a particular date and time.</p><p>There are separate exports for organic search results and for sponsored listings.</p>',
      },
      {
        label: 'Current Grid View',
        value: 'download-grid-data',
        form: null,
        icon: 'download-icon',
      },
    ],
  },
  // ag-grid column cell renderers per service
  columnCellRenderers: {
    reviewbox: {
      ts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      lts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      dt: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      name: GRID_FRAMEWORK_COMPONENTS_KEYS.reviewboxProductNameCellRenderer,
      variation_reviews:
        GRID_FRAMEWORK_COMPONENTS_KEYS.reviewboxGroupedReviewsCellRenderer,
      num_reviews:
        GRID_FRAMEWORK_COMPONENTS_KEYS.reviewboxGroupedReviewsCellRenderer,
      num_questions:
        GRID_FRAMEWORK_COMPONENTS_KEYS.reviewboxGroupedQuestionsCellRenderer,
    },
    reviews: {
      ts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      lts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      dt: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      latestupdatets: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      reply: GRID_FRAMEWORK_COMPONENTS_KEYS.reviewReplyCellRenderer,
    },
    questions: {
      ts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      lts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      latestupdatets: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      dt: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
      reply: GRID_FRAMEWORK_COMPONENTS_KEYS.questionReplyCellRenderer,
    },
    pricebox: {
      // merchant is the buy box owner
      buybox_merchant: GRID_FRAMEWORK_COMPONENTS_KEYS.buyBoxCellRenderer,
      listing: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
      redirect: GRID_FRAMEWORK_COMPONENTS_KEYS.redirectLinkCellRenderer,
      avg_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      min_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      max_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      max_shipping: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      bb_avg_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      bb_min_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
      bb_max_price: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
    },
    sellers: {
      rating: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerRatingCellRenderer,
      seller: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerLinkCellRenderer,
      seller_url: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerLinkCellRenderer,
      seller_name: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerNameCellRenderer,
      last_seen_ts: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
    },
    copybox: {
      listing: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
      redirect: GRID_FRAMEWORK_COMPONENTS_KEYS.redirectLinkCellRenderer,
      name: GRID_FRAMEWORK_COMPONENTS_KEYS.contentComparisonCellRenderer,
      imgurl: GRID_FRAMEWORK_COMPONENTS_KEYS.listingImageCellRenderer,
    },
    content: {
      listing: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
      name: GRID_FRAMEWORK_COMPONENTS_KEYS.contentComparisonCellRenderer,
    },
    searchbox: {
      listing: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
      best_organic_rank:
        GRID_FRAMEWORK_COMPONENTS_KEYS.searchboxRankingCellRenderer,
      best_sponsored_rank:
        GRID_FRAMEWORK_COMPONENTS_KEYS.searchboxRankingCellRenderer,
    },
    searchterms: {
      listing: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
      rank: GRID_FRAMEWORK_COMPONENTS_KEYS.searchboxRankingCellRenderer,
      term: GRID_FRAMEWORK_COMPONENTS_KEYS.searchboxSearchTermDialogCellRenderer,
    },
  },
  // ag-grid column cell editors per service
  columnCellEditors: {
    reviewbox: null,
    reviews: {
      comment: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.simpleTextInput
      },
      status: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: ['Not Assigned', 'Closed', 'In Dispute', 'Open', 'Resolved'],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
        },
      },
      sentiment: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: ['Not Assigned', 'Negative', 'Neutral', 'Positive'],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
        },
      },
      topic: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: [],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
          isMultiple: true,
        },
      },
      customCol: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: [],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
          isMultiple: true,
        },
      },
    },
    questions: {
      comment: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.simpleTextInput
      },
      status: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: ['Not Assigned', 'Closed', 'In Dispute', 'Open', 'Resolved'],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
        },
      },
      sentiment: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: ['Not Assigned', 'Negative', 'Neutral', 'Positive'],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
        },
      },
      topic: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: [],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
          isMultiple: true,
        },
      },
      customCol: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: [],
          cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.agRichSelectCellRenderer,
          isMultiple: true,
        },
      },
    },
    pricebox: {
      guideline_low: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.mapViolationInput,
      },
      guideline_high: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.mapViolationInput,
      },
    },
    sellers: {
      seller_comment: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.simpleTextInput
      },
      seller_status: {
        cellEditor: GRID_FRAMEWORK_COMPONENTS_KEYS.selectTypeAheadCellEditor,
        cellEditorParams: {
          values: [
            'Not Assigned',
            'Authorized',
            'Closed',
            'Counterfeit',
            'In Dispute',
            'Unauthorized',
          ],
        },
      },
    },
  },
  // Default date range select values for a service's grid
  gridsDefaultDateRange: {
    // Default date is yesterday
    reviews: {
      startDate: dateRanges.options.last7Days[0],
      endDate: dateRanges.options.last7Days[1],
    },
    // Default date is yesterday
    questions: {
      startDate: dateRanges.options.last7Days[0],
      endDate: dateRanges.options.last7Days[1],
    },
    // Default date is today
    sellers: {
      startDate: dateRanges.options.last7Days[0],
      endDate: dateRanges.options.last7Days[1],
    },
    // Default date is yesterday
    pricebox: {
      startDate: dateRanges.options.last7Days[0],
      endDate: dateRanges.options.last7Days[1],
    },
  },
  showResponseDrawer: false,
};
