var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("base-input", {
        staticClass: "mb-0",
        attrs: {
          type: "email",
          multiple: "",
          error: _vm.error,
          required: _vm.required,
          placeholder: _vm.placeholder || "Enter an email",
          name: _vm.name,
          label: _vm.label,
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "model",
        },
      }),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }