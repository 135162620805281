var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "modal",
      attrs: {
        top: "65px",
        title: _vm.isSaveFilter ? "Save Filter" : "Delete Filter",
        visible: _vm.show,
        "destroy-on-close": "",
        width: "50%",
        "custom-class": _vm.isSaveFilter
          ? "dialog-primary"
          : "dialog-secondary",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: { close: _vm.onCancel },
    },
    [
      _c("div", [
        _vm.isSaveFilter
          ? _c(
              "div",
              [
                _c("base-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.modelValidations.filterName,
                      expression: "modelValidations.filterName",
                    },
                  ],
                  attrs: {
                    label: "Filter name",
                    name: "filterName",
                    required: "",
                    disabled: _vm.isLoading,
                    placeholder: "Enter Filter Name",
                    error: _vm.getError("filterName"),
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.onSaveFilter.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.model.filterName,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "filterName", $$v)
                    },
                    expression: "model.filterName",
                  },
                }),
                _vm._l(_vm.filterTypes, function (fType) {
                  return _c(
                    "base-radio",
                    {
                      key: fType.value,
                      staticClass: "mb-3",
                      attrs: {
                        name: fType.value,
                        inline: true,
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.model.filterType,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "filterType", $$v)
                        },
                        expression: "model.filterType",
                      },
                    },
                    [_vm._v(" " + _vm._s(fType.label) + " ")]
                  )
                }),
                _c("el-alert", { attrs: { type: "info", closable: false } }, [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center mr-2" },
                      [_c("info-icon", { staticClass: "rb-icon rb-icon-xl" })],
                      1
                    ),
                    _c("div", [
                      _c("p", { staticClass: "mb-1" }, [
                        _c("b", [_vm._v("Team Filters")]),
                        _vm._v(
                          " are shared across your organization's account. Use Team Filters to share with your teammates. "
                        ),
                      ]),
                      _c("p", { staticClass: "mb-1" }, [
                        _c("b", [_vm._v("User Filters")]),
                        _vm._v(
                          " are filters tied to your account. Save User Filters for your personal use. "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              2
            )
          : _c(
              "div",
              [
                _c(
                  "base-input",
                  {
                    attrs: {
                      label: "Grid filters",
                      error: _vm.getError("filters"),
                      required: "",
                      disabled: _vm.isLoading,
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.modelValidations.deleteFilter,
                            expression: "modelValidations.deleteFilter",
                          },
                        ],
                        staticClass: "select-primary",
                        class: { disabled: _vm.isLoading },
                        attrs: {
                          placeholder: "Select filter to delete",
                          "value-key": "value",
                          filterable: "",
                          name: "filters",
                          disabled: _vm.isLoading,
                        },
                        model: {
                          value: _vm.model.selectedDeleteFilter,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "selectedDeleteFilter", $$v)
                          },
                          expression: "model.selectedDeleteFilter",
                        },
                      },
                      _vm._l(_vm.filterOptions, function (group) {
                        return _c(
                          "el-option-group",
                          { key: group.label, attrs: { label: group.label } },
                          _vm._l(group.options, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item },
                            })
                          }),
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
        _vm.filterFormError
          ? _c("div", [_vm._v(" " + _vm._s(_vm.filterFormError) + " ")])
          : _vm._e(),
      ]),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "danger",
                plain: "",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: { click: _vm.onCancel },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                "native-type": "submit",
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: () =>
                  _vm.isSaveFilter ? _vm.onSaveFilter() : _vm.onDeleteFilter(),
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.isSaveFilter ? "Save filter" : "Delete filters") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }