var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table tablesorter", class: _vm.tableClass },
    [
      _c("thead", { class: _vm.theadClasses }, [
        _c(
          "tr",
          [
            _vm._t(
              "columns",
              function () {
                return _vm._l(_vm.columns, function (column) {
                  return _c("th", { key: column }, [
                    _vm._v(" " + _vm._s(column) + " "),
                  ])
                })
              },
              { columns: _vm.columns }
            ),
          ],
          2
        ),
      ]),
      _c(
        "tbody",
        { class: _vm.tbodyClasses },
        _vm._l(_vm.data, function (item, dIndex) {
          return _c(
            "tr",
            { key: dIndex },
            [
              _vm._t(
                "default",
                function () {
                  return _vm._l(
                    _vm.filterColumns(item, _vm.columns),
                    function (column, cIndex) {
                      return _c("td", { key: cIndex }, [
                        _vm._v(" " + _vm._s(_vm.itemValue(item, column)) + " "),
                      ])
                    }
                  )
                },
                { row: item, index: dIndex }
              ),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }