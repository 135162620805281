var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "navbar fixed-top border-bottom py-0 dashboard-navbar bg-white navbar-expand",
      attrs: { transparent: false },
    },
    [
      _c(
        "div",
        {
          staticClass: "navbar-wrapper",
          attrs: { slot: "brand" },
          slot: "brand",
        },
        [_c("route-bread-crumb")],
        1
      ),
      _c(
        "ul",
        { staticClass: "navbar-nav ml-auto h-100 d-flex align-items-center" },
        [
          _c("downloads-button", { staticClass: "nav-item h-100" }),
          _c("help-button", { staticClass: "nav-item h-100" }),
          _c("div", {
            staticClass: "nav-item nav-divider mx-2 border-left h-75",
          }),
          _c(
            "el-dropdown",
            {
              staticClass: "nav-item rb-account-dropdown h-100 px-3",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "h-100 el-dropdown-link d-flex justify-content-between align-items-center",
                },
                [
                  _vm.username || _vm.mastername
                    ? _c("div", { staticClass: "mr-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-capitalize text-dark font-weight-bold",
                          },
                          [_vm._v(" " + _vm._s(_vm.username) + " ")]
                        ),
                        _vm.username !== _vm.mastername
                          ? _c(
                              "div",
                              { staticClass: "text-capitalize text-muted" },
                              [_vm._v(" " + _vm._s(_vm.mastername) + " ")]
                            )
                          : _vm._e(),
                      ])
                    : _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("account-circle-icon", {
                            staticClass: "rb-icon-2x",
                          }),
                        ],
                        1
                      ),
                  _c("chevron-down-icon"),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                [
                  _c(
                    "el-dropdown-item",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "el-dropdown-menu__item d-flex align-items-center text-reset",
                          attrs: { to: "/account/profile" },
                        },
                        [
                          _c("account-circle-icon", { staticClass: "mr-2" }),
                          _c("span", [_vm._v(" Edit profile ")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "el-dropdown-menu__item d-flex align-items-center text-reset",
                          attrs: { to: "/account/plan" },
                        },
                        [
                          _c("file-document-icon", { staticClass: "mr-2" }),
                          _c("span", [_vm._v(" Plan Summary ")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    { staticClass: "px-0" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "el-dropdown-menu__item d-flex align-items-center text-reset",
                          attrs: { to: "/account/notification-settings" },
                        },
                        [
                          _c("email-icon", { staticClass: "mr-2" }),
                          _c("span", [_vm._v("Notifications")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "my-1" }),
                  _c("el-dropdown-item", { staticClass: "px-0" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "el-dropdown-menu__item d-flex align-items-center text-reset",
                        attrs: {
                          href: "https://helpcenter.getreviewbox.com",
                          target: "_blank",
                        },
                      },
                      [
                        _c("help-circle-icon", { staticClass: "mr-2" }),
                        _c("span", [
                          _vm._v(" Help Center "),
                          _c(
                            "sup",
                            [
                              _c("external-link-icon", {
                                staticClass: "rb-icon-xs",
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-dropdown-item",
                    {
                      staticClass: "d-flex align-items-center",
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logOut.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("logout-icon", { staticClass: "mr-2" }),
                      _vm._v(" Log out "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }