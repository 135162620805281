import isNumber from 'lodash/isNumber';

/**
 * Used with the percentParser when filter on ag-grid
 *
 * @param { Number } value Decimal that needs to be converted to a percentage
 * @param { Number } digits Number of decimal places to pad the percentage
 *
 * @examples
 * percentFormatter(0.8, 3)-> 80.000%
 * percentFormatter(0.8, 2)-> 80.00%
 * percentFormatter(0.8)-> 80.00%
 * percentFormatter(0.8, 0)-> 80%
 *
 * @returns { String } returns a string formatted as a percentage
 */
export default (value, digits = 2) => {
  if (!isNumber(value)) {
    return value;
  }

  const percent = value * 100;

  return `${percent.toFixed(digits)}%`;
};
