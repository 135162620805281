import SellersBaseHttp from './SellersBaseHttp';
import {
  numericComparator,
  dateComparator,
} from '@/utils';

import { GRID_FRAMEWORK_COMPONENTS_KEYS } from '@/components/Grid/CellRenderers';

/**
 * Class to handle all pricebox related http requests
 */
class DetailedSellersHttp extends SellersBaseHttp {
  constructor() {
    super();

    this.service = 'DetailedSellers';

    // Default reviewbox columns
    this.defaultColumns = [
      // Sellers column group
      {
        headerName: 'Catalog',
        groupId: 'catalog',
        children: [
          {
            headerName: 'ID',
            field: 'seller',
            filter: 'agTextColumnFilter',
            editable: false,
          },
          {
            headerName: 'Seller Name',
            field: 'seller_name',
            filter: 'agTextColumnFilter',
            editable: false,
          },
          {
            headerName: 'Source',
            field: 'source',
            filter: 'agTextColumnFilter',
            editable: false,
          },
          {
            headerName: 'Contact',
            field: 'seller_url',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerLinkCellRenderer,
            editable: false,
          },
        ],
      },
      // Pricing column group
      {
        headerName: 'Pricing',
        groupId: 'pricing',
        children: [
          {
            headerName: 'Had Violations',
            field: 'had_violations',
            filter: 'agSetColumnFilter',
            editable: false,
          },
          {
            headerName: 'Avg. Price',
            field: 'avg_price',
            type: 'numericColumn',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Min Price',
            field: 'min_price',
            type: 'numericColumn',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Max Price',
            field: 'max_price',
            type: 'numericColumn',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Prime',
            field: 'prime',
            filter: 'agSetColumnFilter',
            editable: false,
          },
          {
            headerName: 'Max Shipping',
            field: 'max_shipping',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            editable: false,
          },
        ],
      },
      // Seller Group
      {
        headerName: 'Seller',
        groupId: 'seller',
        children: [
          {
            headerName: 'Rating',
            field: 'rating',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.sellerRatingCellRenderer,
            editable: false,
            width: 100,
          },
          {
            headerName: 'Total Ratings',
            field: 'num_ratings',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Last Seen (UTC)',
            field: 'last_seen_ts',
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dateComparator,
            },
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.dateCellRenderer,
            editable: false,
          },
          {
            headerName: 'Status',
            field: 'seller_status',
            filter: 'agSetColumnFilter',
            editable: false,
          },
          {
            headerName: 'Notes',
            field: 'seller_comment',
            filter: 'agSetColumnFilter',
            editable: false,
          },
        ],
      },
    ];
  }
}

export default new DetailedSellersHttp();
