import { Pie, mixins } from 'vue-chartjs';

export default {
  name: 'pie-chart',
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    extraOptions: Object,
    gradientColors: {
      type: Array,
      default: () => [
        'rgba(72,72,176,0.2)',
        'rgba(72,72,176,0.0)',
        'rgba(119,52,169,0)',
      ],
      validator: (val) => val.length > 1,
    },
    gradientStops: {
      type: Array,
      default: () => [1, 0.4, 0],
      validator: (val) => val.length > 1,
    },
  },
  data() {
    return {
      ctx: null,
    };
  },
  methods: {
    updateGradients(chartData) {
      if (!chartData) return;
      const ctx = this.ctx || document.getElementById(this.chartId).getContext('2d');
      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      this.gradientStops.forEach((stop, index) => {
        gradientStroke.addColorStop(stop, this.gradientColors[index]);
      });

      chartData.datasets.forEach((set) => {
        if (!set.backgroundColor) {
          set.backgroundColor = gradientStroke;
        }
      });
    },
    handlePieClick(point, event) {
      const item = event[0];

      if (item && typeof this.chartData.navigation !== 'undefined' && this.chartData.navigation.length > item._index) {
        this.$emit('on-click', {
          index: item._index,
          navigation: this.chartData.navigation[item._index],
        });
      }
    },
  },
  mounted() {
    this.$watch(
      'chartData',
      (newVal, oldVal) => {
        this.updateGradients(newVal);
        if (!oldVal) {
          const allOptions = this.extraOptions;
          allOptions.responsive = true;
          allOptions.onClick = this.handlePieClick;
          this.renderChart(this.chartData, allOptions);
        }
      },
      { immediate: true },
    );
  },
};
