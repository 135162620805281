import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/copybox',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    service: 'copybox',
    module: 'copybox',
  },
  children: [
    {
      path: '',
      name: 'Copybox',
      redirect: '/copybox/content-latest',
      meta: {
        requiresAuth: true,
        service: 'copybox',
        module: 'copybox',
      },
      components: {
        default: () => import('src/pages/Copybox/CopyboxLayout.vue'),
      },
      children: [
        {
          path: 'content-latest',
          name: 'Latest Content',
          meta: {
            requiresAuth: true,
            service: 'copybox',
            module: 'copybox',
          },
          components: {
            default: () => import('src/pages/Copybox/ContentLatest/ContentLatest.vue'),
          },
        },
        {
          path: 'content-reference',
          name: 'Reference Content',
          meta: {
            requiresAuth: true,
            service: 'copybox',
            module: 'copybox',
          },
          components: {
            default: () => import('src/pages/Copybox/ContentReference/ContentReference.vue'),
          },
        },
        {
          path: 'content-comparison',
          name: 'Content Comparison',
          meta: {
            hideHelp: true,
            requiresAuth: true,
            service: 'copybox',
            module: 'copybox',
          },
          components: {
            default: () => import('src/pages/Copybox/ContentComparison/ContentComparison.vue'),
          },
        },
      ],
    },
  ],
};
