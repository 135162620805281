export default {
  /**
   * Action to set Chart Height
   *
   * @param {Function} commit Function to call mutation
   */
  setChartsHeight({ commit }, status) {
    commit('setChartsHeight', status);
  },
  /**
   * Action to set showCharts status
   *
   * @param {Function} commit Function to call mutation
   */
  setShowCharts({ commit }, status) {
    commit('setShowCharts', status);
  },
  /**
   * Action to reset showCharts status to default
   *
   * @param {Function} commit Function to call mutation
   */
  resetShowCharts({ commit }) {
    commit('resetShowCharts');
  },
  /**
   * Action to set selectCache status
   *
   * @param {Function} commit Function to call mutation
   */
  setSelectCache({ commit }, status) {
    commit('setSelectCache', status);
  },
  /**
   * Action to reset selectCache status
   *
   * @param {Function} commit Function to call mutation
   */
  resetSelectCache({ commit }) {
    commit('resetSelectCache');
  },
};
