var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "base-input",
        {
          class: {
            "mb-0": !_vm.isCustomDateSelected,
            "mb-2": _vm.isCustomDateSelected,
          },
          attrs: {
            required: _vm.required,
            label: _vm.label,
            name: _vm.name,
            "input-id": _vm.inputId,
            error: _vm.error,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "helperText",
                fn: function () {
                  return [_vm._t("helpText")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _c(
            "el-select",
            {
              staticClass: "rb-report-date-picker select-success",
              attrs: {
                id: `${_vm.label}--${_vm.inputId}`,
                placeholder: _vm.placeholder || "Select Date Range",
              },
              model: {
                value: _vm.date,
                callback: function ($$v) {
                  _vm.date = $$v
                },
                expression: "date",
              },
            },
            _vm._l(_vm.options, function (option, optionIndex) {
              return _c("el-option", {
                key: optionIndex,
                staticClass: "select-success",
                attrs: { value: option, label: option.name },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm.isCustomDateSelected
        ? _c("date-range-input", {
            attrs: {
              required: _vm.required,
              label: "Custom date range",
              name: _vm.name,
            },
            model: {
              value: _vm.customDate,
              callback: function ($$v) {
                _vm.customDate = $$v
              },
              expression: "customDate",
            },
          })
        : _vm._e(),
      _vm.isCustomDateSelected && _vm.showWarning
        ? _c(
            "el-alert",
            {
              staticClass: "mt-2",
              attrs: { type: "info", closable: false, "show-icon": "" },
            },
            [
              _vm._v(
                " If you select custom date with a recurring export or report, it will always email the same date range. Select a non-custom date when creating a recurring export or report. "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }