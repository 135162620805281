import { mapGetters, mapActions } from 'vuex';
import { AuthHttp, BaseHttp } from '@/services/api';

// Import Components
import { BaseGrid } from '@/components';

/**
 * GridMixins keeps shared methods, etc. in one location
 * to keep things DRY. Also, not much changes between a
 * service's grid state management, so good to keep it centralized.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  components: { BaseGrid },
  data() {
    return {
      cancelTokens: [],
      isDateLoading: false
    };
  },
  computed: {
    ...mapGetters({
      gridState: 'grids/getGridState',
      filterState: 'grids/getFilterState',
      isGridLoading: 'grids/getIsGridLoading',
      downloadOptions: 'grids/getDownloadOptions',
      columnCellRenderers: 'grids/getColumnCellRenderers',
      columnCellEditors: 'grids/getColumnCellEditors',
      customFields: 'grids/getCustomFields',
    }),
  },
  methods: {
    ...mapActions('grids', [
      'setGridState',
      'setGridLoading',
      'getGridData',
      'getGridColumns',
      'updateColumnName',
    ]),
    /**
     * Method to reset an ag-grid cell's value to prior editing.
     * This is used whenever the edit HTTP request fails, so we
     * want to revert to previous value to reflect a non-change.
     *
     * @param {String} oldValue Cell value before edit
     * @param {String} field Column field identifier
     * @param {Object} cellData Ag-grid row data
     * @param {Object} rowNode Ag-grid rowNode object
     */
    resetGridCellValue(oldValue, field, cellData, rowNode) {
      const oldData = { ...cellData };
      oldData[field] = oldValue;

      rowNode.setData(oldData);
    },
    /**
     * Async method to update an ag-grid column header name and update
     * it in the back-end to reflect changes.
     *
     * The order is:
     * 1. Update export preferences
     * 2. Save column settings
     * 3. Set ag-grid column headers
     * 4. Refresh ag-grid column header display
     *
     * @todo We need to have a consistent field name when updating
     * column names. We change the ag-grid field name to match what's on
     * the back-end. This is not the same for saving gridPreferences and
     * saving exportSettings. Let's make it uniform so we don't have to
     * have multiple field names for one service. For example,
     * Reviewbox changes ag-grid field of "listings" to "id" for
     * exportSettings, but we still save it as field of "listings" for
     * gridPreferences. Why not just keep them the same and only have the display
     * name change?
     *
     * @param {String} service Name of service, e.g. reviewbox
     * @param {String} field Name of column, e.g. listings
     * @param {String} newValue New name of column
     */
    async updateColumnHeaderName(
      exportSettingsService,
      columnPreferencesService,
      field,
      newValue,
      oldValue,
      agGridParams,
    ) {
      const columnGroup = agGridParams.column.originalParent.groupId;
      const columnField = agGridParams.column.colDef.field;

      // Trigger action to update grid display name
      this.updateColumnName({
        newValue,
        columnGroup,
        field: columnField,
        service: this.service,
      });

      // Then update export settings in the back-end
      this.updateExportSettings(exportSettingsService, field, newValue)
        // Then save grid column preferences to back-end
        .then((response) => {
          /**
           * If updateExportSettings does not find a column name change
           * it will return false, so throw an error to reset the
           * column name back to oldValue.
           *
           * @todo This code was ported over from Angular.js
           * This shouldn't really happen? If you change a column header name
           * that means you're calling updateExportSettings, thus something has
           * changed. So returning false should never occur. We should revisit.
           */
          if (response === false) {
            throw new Error('No header value to change');
          }

          return this.saveColumnPreferences(columnPreferencesService, agGridParams);
        })
        .catch((error) => {
          this.handleError(error, {}, 'Unable to update header. Please try again.');

          // Reset newValue back to oldValue
          // if something fails saving settings and preferences
          this.updateColumnName({
            newValue: oldValue,
            columnGroup,
            field: columnField,
            service: this.service,
          });
        });
    },
    /**
     * Method to do a post request that changes the export
     * settings to reflect the new column header name.
     *
     * @param {String} service Name of service, e.g. Reviewbox
     * @param {String} field Name of field being changed
     * @param {String} newValue Name of field name
     */
    async updateExportSettings(service, field, newValue) {
      const customFields = this.customFields(this.service);
      let fieldBeingChanged = field;

      // We need to map new column names to old ones, e.g. retailer_source -> source
      if (field === 'retailer_source') {
        fieldBeingChanged = 'source';
      }

      // Get the current export settings
      const exportSettings = await AuthHttp.getUserExportSettings(service)
        .then((data) => {
          let columns = [];
          const { settings } = data;
          const defaultSettings = data.default_settings;

          // Assign columns to user specified settings
          if (settings) {
            columns = settings;
            // Else apply the default settings provided,
            // and associated custom fields
          } else {
            // Add custom fields to default columns
            if (customFields.length) {
              customFields.forEach((customField) => {
                const tempField = `${customField}_custom`;
                defaultSettings.push([tempField]);
              });
            }

            defaultSettings.forEach((setting) => {
              columns.push([setting, setting]);
            });
          }

          // Go through columns and change the name to
          // the correct one if the column is present
          columns.forEach((column) => {
            if (column[0] === fieldBeingChanged) {
              column[1] = newValue;
            }
          });

          return columns;
        })
        .catch((error) => {
          throw new Error(error.message);
        });

      // If hasChanged, POST new settings
      if (exportSettings) {
        return AuthHttp.updateUserExportSettings({
          export_type: service,
          export_settings: JSON.stringify(exportSettings),
        });
      }

      return false;
    },
    /**
     * Method to do a post request to update the ag-grid column
     * names preferences in the back-end.
     *
     * @param {String} newValue New value for column name
     * @param {Object} agGridParams Ag-grid params object
     */
    async saveColumnPreferences(service, agGridParams) {
      // Grab all the columns regardless if they are hidden.
      const allColumns = agGridParams.columnApi.getAllGridColumns();

      // column definitions we will save
      const columnGroups = {};
      const groupNames = {};
      const columnInfo = [];

      allColumns.forEach((childcol) => {
        if (childcol.colId === 'agGridViewData') {
          return;
        }

        const groupID = childcol.originalParent.groupId;
        const groupName = childcol.originalParent.colGroupDef.headerName;
        groupNames[groupID] = groupName;

        if (!Object.prototype.hasOwnProperty.call(columnGroups, groupID)) {
          columnGroups[groupID] = [];
        }

        const childColPinned = childcol.pinned;
        const childColWidth = childcol.actualWidth;
        const childColField = childcol.colDef.field;
        const childColName = agGridParams.columnApi.getDisplayNameForColumn(childcol);

        // add the column to the list of children columns
        columnGroups[groupID].push({
          headerName: childColName,
          field: childColField,
          hide: !(childcol.visible),
          width: childColWidth,
          pinned: childColPinned,
        });
      });

      Object.keys(columnGroups).forEach((groupID) => {
        const groupName = groupNames[groupID];

        const childInfo = columnGroups[groupID];
        columnInfo.push({
          headerName: groupName,
          groupId: groupID,
          children: childInfo,
        });
      });

      // Now pass the column child info to the backend.
      BaseHttp.updateSavedGridPreferences({
        grid_type: service,
        columns: JSON.stringify(columnInfo),
      }).catch((error) => this.handleError(error));
    },
    getLSGridDate(service, gridState) {
      let lsDate = sessionStorage.getItem(`${service}-gridDate`);

      if (lsDate) {
        lsDate = JSON.parse(lsDate);
        gridState.gridDateRange.startDate = new Date(lsDate.startDate);
        gridState.gridDateRange.endDate = new Date(lsDate.endDate);
      }

      return this.formatCustomDateRange(
        gridState.gridDateRange.startDate,
        gridState.gridDateRange.endDate,
      );
    },

    setLSGridDate(service, date) {
      const newDate = JSON.stringify({
        startDate: date.startDate.toString(),
        endDate: date.endDate.toString(),
      });
      sessionStorage.setItem(`${service}-gridDate`, newDate);
    },
    removeLSGridDate(service) {
      sessionStorage.removeItem(`${service}-gridDate`);
    },

    /**
     * Method to updating custom fields in grids
     */
    onUpdateCustomField(value, service = 'catalog') {
      const { field } = value.colDef;
      const { newValue, oldValue } = value;
      const { node, data } = value;
      const { listing, source } = data;
      const payload = {
        listing,
        source,
        field: field.replace('_user', ''),
        value: newValue,
        service,
      };

      // newValue is undefined when clicking in a cell and clicking away, so don't ping API
      if (typeof newValue !== 'undefined') {
        BaseHttp.updateCustomFields(payload).catch((error) => {
          this.handleError(error, {}, 'Unable to update cell value. Please try again.');

          // If error, reset row data back to previous state
          this.resetGridCellValue(oldValue, field, data, node);
        });
      }
    },
    /**
     * Add a cancel token to the tokens list
     */
    onAddCancelToken(token) {
      this.cancelTokens.push(token);
      this.isDateLoading = false;
    },
    /**
     * Cancel the service's current http request
     */
    handleCancelRequest() {
      const { length } = this.cancelTokens;

      if (length) {
        // Cancel current loading
        this.cancelTokens[length - 1].cancel('Operation canceled by the user.');
      }
    },
  },
};
