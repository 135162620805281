import DefaultLayout from 'src/layouts/default.vue';

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ 'src/pages/Dashboard/Dashboard.vue');

export default {
  path: '/dashboard',
  component: DefaultLayout,
  meta: { requiresAuth: true, module: 'dashboards' },
  children: [
    {
      path: '',
      name: 'Dashboard',
      meta: { requiresAuth: true, module: 'dashboards' },
      components: { default: Dashboard },
    },
  ],
};
