import BaseHttp from './BaseHttp';

/**
 * Class to handle all pricebox related http requests
 */
class PriceboxBaseHttp extends BaseHttp {
  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
    ]);

    // Get user custom fields from listing data
    const { data, columns } = listingData;

    let newCustomFields = [];

    if (columns && columns.length) {
      newCustomFields = columns.filter((c) => c.is_custom).map((c) => c.name);
    }

    return this.constructGrid(
      data,
      gridPreferencesData,
      savedGridFiltersData,
      newCustomFields,
    );
  }

  async getListings(params = {}) {
    return this.getListingData('Pricebox', params);
  }

  /**
   * Async function to fetch pricebox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: this.service });
  }

  /**
   * Async function to fetch pricebox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Async function to update custom field metadata for pricebox products.
   *
   * @param {Object} params HTTP Post params object
   */
  async updatePriceboxCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/customfields`, params);
  }

  /**
   * Async function to update custom field metadata for searchbox terms.
   *
   * @param {Object} params HTTP Post params object
   */
  async updatePriceboxMapPricing(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/pricealert`, params);
  }

  static constructRowData(listingsData, columnDefs) {
    const rowData = [];

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    if (listingsData && listingsData.length) {
      listingsData.forEach((listing) => {
        const row = {};

        columnTypes.forEach((column) => {
          if (Object.prototype.hasOwnProperty.call(listing, column)) {
            if (column === 'num_sellers_in_violation') {
              if (listing.map) {
                row[column] = `${listing.map} in Violation`;
              } else {
                row[column] = 'No Violation';
              }
            } else if (
              ['price', 'buybox_price', 'shipping', 'max_price', 'min_price', 'guideline_low', 'guideline_high'].includes(column)
            ) {
              if (listing[column]) {
                row[column] = parseFloat(listing[column]).toFixed(2);
              }
            } else if (
              ['buybox_prime', 'buybox_pantry', 'buybox_subscribe', 'buybox_fresh', 'buybox_addon', 'buybox_used'].includes(column)
            ) {
              row[column] = listing[column] ? 'Yes' : 'No';
            } else if (['buybox_merchant'].includes(column)) {
              if (listing[column] === null) {
                // row[column] = ['', ''];
                row[column] = [''];
              } else {
                // row[column] = [listing.buybox_merchant, listing.buybox_merchant_id];
                row[column] = [listing.buybox_merchant];
              }
            } else {
              row[column] = listing[column];
            }

            if (listing.notfound) {
              if (column === 'name') {
                row[column] = ` (Not Live) ${row[column]}`;
              } else if (column === 'stock') {
                row[column] = 'unavailable';
              } else if (['price', 'max_price', 'min_price'].includes(column)) {
                row[column] = '';
              } else if (column === 'buybox_merchant') {
                row[column] = ['', ''];
              }
            }
          } else if (
            Object.prototype.hasOwnProperty.call(listing, 'fields')
            && listing.fields !== null
            && Object.prototype.hasOwnProperty.call(listing.fields, column)
          ) {
            row[column] = listing.fields[column];
          } else {
            // These fields were added to the db later so this column doesn't
            // exist for some fields - handle them here
            row[column] = 'Not Available';
          }

          if (row[column] == null) {
            row[column] = '';
          }
        });

        // Add a products url to amazon
        row.producturl = listing.url;

        rowData.push(row);
      });
    }

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default PriceboxBaseHttp;
