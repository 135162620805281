import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/catalog-management',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    module: 'catalog',
  },
  children: [
    {
      path: '',
      name: 'Catalog Management',
      redirect: '/',
      meta: {
        requiresAuth: true,
        module: 'catalog',
      },
      components: {
        default: () => import('src/pages/CatalogManagement/CatalogManagementLayout.vue'),
      },
      children: [
        {
          path: '',
          name: 'Catalog',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/CatalogManagement.vue'),
          },
        },
        {
          path: 'add-listings',
          name: 'Add Listings',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/AddListings/AddListings.vue'),
          },
        },
        {
          path: 'delete-listings',
          name: 'Delete Listings',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/DeleteListings/DeleteListings.vue'),
          },
        },
        {
          path: 'add-terms',
          name: 'Add Terms',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/AddTerms/AddTerms.vue'),
          },
        },
        {
          path: 'delete-terms',
          name: 'Delete Terms',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/DeleteTerms/DeleteTerms.vue'),
          },
        },
        {
          path: 'add-reference-content',
          name: 'Add Reference Content',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/AddReferenceContent/AddReferenceContent.vue'),
          },
        },
        {
          path: 'update-content-guidelines',
          name: 'Update Content Guidelines',
          meta: {
            requiresAuth: true,
            module: 'catalog',
          },
          components: {
            default: () => import('src/pages/CatalogManagement/UpdateContentGuidelines/UpdateContentGuidelines.vue'),
          },
        },
      ],
    },
  ],
};
