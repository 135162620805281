var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "rb-tabs-navbar",
      attrs: {
        mode: "horizontal",
        router: "",
        "default-active": _vm.activeTab,
        "background-color": "transparent",
      },
    },
    [
      _vm._l(_vm.tabs, function (tab, index) {
        return _c("el-menu-item", { key: index, attrs: { index: tab.to } }, [
          _vm._v(" " + _vm._s(tab.name) + " "),
        ])
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }