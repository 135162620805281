import {
  BaseHttp,
  AuthHttp,
  AdstationHttp,
  ResponseHttp,
} from '@/services/api';
import storage from 'src/utils/localStorageUtil';

export default {
  /**
   * Action to change loading status
   * @param {Function} commit Function to call mutations in account store
   * @param {Boolean} loading Boolean to determine loading status
   */
  changeLoadingStatus({ commit }, loading) {
    commit('setAccountLoading', loading);
  },

  /**
   * Action to fetch log in iframe for Oauth
   */
  async getLogInPage() {
    return AuthHttp.getLogInPage();
  },

  /**
   * Async action to set the user state (logging in)
   * @param {Function} commit Function to call mutations in account store
   * @param {Object} user Object of user information
   */
  async logInUser({ commit, dispatch }, user) {
    try {
      dispatch('fetchOauthProfile', user.name.toLowerCase());
    } catch (error) {
      // Track login errors with Rollbar
      this.$rollbar.error('Could not get user profile', error);
    }

    commit('setUser', user);
  },

  /**
   * Action to log out a user and clear their store
   * @param {Function} Dispatch Function to call actions in account store
   * @param {Object} router Vue-router object to navigate page routes
   */
  logOutUser({ dispatch }, { router, redirect }) {
    return AuthHttp.logOutUser()
      .then(() => {
        dispatch('clearUserState');
        const logInPath = '/login';

        // Remove all saved data from sessionStorage
        sessionStorage.clear();

        // After log out redirect to log in
        if (redirect) {
          router.push({
            path: logInPath,
            query: { redirect },
          });
        } else {
          router.push(logInPath);
        }
      });
  },

  /**
   * Async action to fetch and set the user's profile
   * @param {Function} commit Function to call mutations in account store
   * @param {Object} payload Object of 'user_id'
   */
  async fetchOauthProfile({ commit }, userId) {
    const payload = { user_id: userId };

    return AuthHttp.getUserProfile(payload)
      .then((data) => commit('setProfile', data));
  },

  /**
   * Async action to get profile filters.
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async fetchProfileFilters({ commit }) {
    return BaseHttp.getSavedGridFilters({ filterType: 'profileFilter' })
      .then((data) => {
        commit('setProfileFilters', data);
      });
  },

  /**
   * Async action to fetch and set the user's plans
   * @param {Function} commit Function to call mutations in account store
   * Assumes that headers will set user correctly
   */
  async fetchUserPlans({ commit }) {
    return AuthHttp.getUserPlans()
      .then((data) => {
        const currentPlanId = data.plans ? data.plans[data.current].id : null;
        const planList = {
          currentPlanId,
          plans: data.plans,
        };

        commit('setPlans', planList);
      });
  },

  /**
   * Async action to fetch and set the user's plans
   * @param {Function} commit Function to call mutations in account store
   * Assumes that headers will set user correctly
   */
  async fetchUserPlanSummary({ commit }) {
    return AuthHttp.getUserPlanSummary()
      .then((data) => {
        const { total_active_listings, user_listing_counts, user_plan, reports } = data;

        commit('setUserPlanSummary', {
          totalActiveListings: total_active_listings,
          userListingCounts: user_listing_counts,
          userPlan: user_plan,
          reports,
        });
      });
  },

  /**
   * Async action to fetch and set the user's ad accounts
   * @param {Function} commit Function to call mutations in account store
   * Assumes that headers will set user correctly
   */
  async fetchAdAccounts({ commit }) {
    return AdstationHttp.getAdAccounts()
      .then((data) => {
        const accountList = [];

        if (Object.prototype.hasOwnProperty.call(data, 'profiles')) {
          // profiles is an array of objects
          // Relevant keys are 'brand_name' and 'source'
          const { profiles } = data;
          profiles.forEach((item) => {
            const entry = {
              profileId: item.profile_id,
              brandName: item.brand_name,
              source: item.source,
            };

            accountList.push(entry);
          });
          commit('setAdAccounts', accountList);
        }
      });
  },

  /**
   * Update the account list with a list of accounts.
   * @param {Function} commit Function to call mutations in account store
   * @param {Array} accountList List of accounts
   */
  async updateAdAccounts({ commit }, accountList) {
    commit('setAdAccounts', accountList);
  },

  /**
   * Async action to fetch and set the all sources for services
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async getServiceSourceList({ commit }) {
    return AuthHttp.getServiceSourceList()
      .then((data) => commit('setServiceSourceList', data));
  },

  /**
   * Async action to fetch and set response templates
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async getResponseTemplates({ commit }) {
    return ResponseHttp.getResponseTemplates()
      .then((data) => commit('setResponseTemplates', data));
  },

  /**
   * Async action to create a response template
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async postResponseTemplate({ commit }, payload) {
    return ResponseHttp.postResponseTemplate(payload)
      .then((data) => commit('addResponseTemplate', data));
  },

  /**
   * Async action to update a response template
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async updateResponseTemplate({ commit }, payload) {
    return ResponseHttp.postResponseTemplate(payload)
      .then((data) => commit('updateResponseTemplate', data));
  },

  /**
   * Async action to delete a response template
   *
   * @param {Function} commit Function to call mutations in account store
   */
  async deleteResponseTemplate({ commit }, payload) {
    return ResponseHttp.deleteResponseTemplate(payload)
      .then((data) => commit('deleteResponseTemplate', data));
  },

  /**
   * Action to clear the entire store of user data
   *
   * @todo Refactor this action so that we don't call a bunch of
   * mutations/actions to reset values.
   *
   * @param {Function} commit Function to call mutations for store modules
   * resetAccountLoading must come first to ensure destruction of all elements
   */
  clearUserState({ commit }) {
    const lsRoute = storage.getLSRoute();
    const username = storage.getUsername();

    commit('resetAccountLoading');
    commit('removeUser');
    commit('removeProfile');
    commit('removeProfileFilter');
    commit('removePlans');
    commit('removeAdAccounts');
    commit('removeUserPlanSummary');
    commit('removeServiceSourceList');
    commit('removeAllResponseTemplates');
    commit('downloads/removeDownloads', null, { root: true });
    commit('dashboard/removeDashboard', null, { root: true });
    commit('dashboard/removeDashboardFilters', null, { root: true });
    commit('grids/removeAllGridState', null, { root: true });
    commit('grids/resetCustomColValues', { service: 'reviews' }, { root: true });
    commit('grids/resetCustomColValues', { service: 'questions' }, { root: true });
    commit('reviews/resetResponseIntegrations', null, { root: true });
    commit('reviews/resetResponseIntegrations', null, { root: true });
    commit('tabs/removeTabsHistory', null, { root: true });
    commit('catalog/updateCatalogCount', {}, { root: true });

    if (username) storage.setLSRoute(lsRoute, username);
  },
};
