import BaseHttp from './BaseHttp';

/**
 * Class to handle all Adstation related http requests
 */
class AdstationHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'Adstation';
  }

  /**
   * Fetches a user's adstation accounts info.
   */
  async getAdAccounts() {
    return this.get(`${this.PrependRoutes.ams}/account`)
      .then((response) => response.data);
  }
}

export default new AdstationHttp();
