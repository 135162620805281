/**
 * Uniform parent route paths for express
 * This makes it quick and easy to update a
 * parent route path if the name no longer makes sense.
 *
 * For example:
 * We are on version 1 of an api
 * The parent initially is '/api/v1/'
 *
 * The api gets update to version 2
 * We can just update the api path to '/api/v2/'
 * */
class PrependRoutes {
  constructor() {
    // Base prepends
    this.api = '/api';
    this.auth = '/auth';

    // Secondary prepends
    this.reviewbox = `${this.api}/reviewbox`;
    this.ams = `${this.api}/ams`;
  }
}

export default new PrependRoutes();
