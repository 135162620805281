import PriceboxBaseHttp from './PriceboxBaseHttp';

import { numericComparator } from '@/utils';
import { GRID_FRAMEWORK_COMPONENTS_KEYS } from '@/components/Grid/CellRenderers';

/**
 * Class to handle all pricebox related http requests
 */
class DetailedPriceboxHttp extends PriceboxBaseHttp {
  constructor() {
    super();

    this.service = 'DetailedPricebox';

    // Default reviewbox columns
    this.defaultColumns = [
      // Catalog group
      {
        headerName: 'Catalog',
        groupId: 'catalog',
        children: [
          {
            headerName: 'ID',
            field: 'listing',
            filter: 'agTextColumnFilter',
            editable: false,
          },
          {
            headerName: 'Name',
            field: 'listing_name',
            filter: 'agTextColumnFilter',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.listingLinkCellRenderer,
            editable: false,
          },
          {
            headerName: 'Source',
            field: 'source',
            filter: 'agSetColumnFilter',
            editable: false,
          },
        ],
      },
      {
        headerName: 'MAP',
        groupId: 'map',
        children: [
          {
            headerName: 'Seller in Violation',
            field: 'had_violations',
            filter: 'agSetColumnFilter',
            editable: false,
          },
          {
            headerName: 'MAP Low',
            field: 'guideline_low',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'MAP High',
            field: 'guideline_high',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
        ],
      },
      // Current pricing group
      {
        headerName: 'Pricing',
        groupId: 'pricing',
        children: [
          {
            headerName: 'Avg. Price',
            field: 'avg_price',
            type: 'numericColumn',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            filter: 'agNumberColumnFilter',
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Min Price',
            field: 'min_price',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Max Price',
            field: 'max_price',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            comparator: numericComparator,
            editable: false,
          },
          {
            headerName: 'Max Shipping',
            field: 'max_shipping',
            type: 'numericColumn',
            filter: 'agNumberColumnFilter',
            cellRenderer: GRID_FRAMEWORK_COMPONENTS_KEYS.priceCellRenderer,
            comparator: numericComparator,
            editable: false,
          },
        ],
      },
    ];
  }
}

export default new DetailedPriceboxHttp();
