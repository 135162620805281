export default {

  getUsername() {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user).name;
    return null;
  },

  getLSRoute(_username = null) {
    const username = _username || this.getUsername();
    if (username) return sessionStorage.getItem(`LS_ROUTE_${username}`);
    return null;
  },

  setLSRoute(path, _username = null) {
    const username = _username || this.getUsername();
    if (username) sessionStorage.setItem(`LS_ROUTE_${username}`, path);
  },
};
