import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';

export default {
  /**
   * Getter to return reviewbox rating chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns a vue-chartjs object
   */
  reviewboxRatingsDistributionChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'stars')) {
      return null;
    }

    if (state.dashboard.stars == null) {
      return null;
    }

    const pieColorsMap = {
      1: chartConfigs.chartColors.defaultColors.red,
      2: chartConfigs.chartColors.defaultColors.pink,
      3: chartConfigs.chartColors.defaultColors.yellow,
      4: chartConfigs.chartColors.defaultColors.blue,
      5: chartConfigs.chartColors.defaultColors.green,
    };
    const pieLabels = [];
    const pieData = [];
    const pieColors = [];

    // Extract rating data for pie chart
    Object.keys(state.dashboard.stars)
      .forEach((key) => {
        pieLabels.push(`${key} star`);
        pieData.push(state.dashboard.stars[key]);
        pieColors.push(pieColorsMap[key]);
      });

    // Construct chart.js pie chart object
    const chartData = {
      datasets: [{
        data: pieData,
        backgroundColor: pieColors,
        label: 'Review Rating Data',
      }],
      labels: pieLabels,
      navigation: [1, 2, 3, 4, 5],
    };

    return {
      chartData,
      extraOptions: chartConfigs.pieChartOptions,
    };
  },
  /**
   * Getter to construct reviewbox new reviews chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns vue-chartjs chart object
   */
  reviewboxNewReviewsChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'asins')) {
      return null;
    }

    if (state.dashboard.asins == null) {
      return null;
    }

    const barLabels = [];
    const barData = [];
    const barNavigation = [];
    Object.keys(state.dashboard.asins)
      // Grab relevant asins data
      .map((product) => {
        const keySplit = product.split(' ');
        const listing = keySplit[0];
        const source = keySplit[1];

        return {
          source,
          listing,
          count: state.dashboard.asins[product],
        };
      })
      // Sort by new counts in descending order
      .sort((a, b) => b.count - a.count)
      // Limit to first 10 products
      .slice(0, 10)
      // Populate chart data on subset of asins
      .forEach((point) => {
        barLabels.push(point.listing);
        barData.push(point.count);
        barNavigation.push(point);
      });

    return {
      chartData: {
        labels: barLabels,
        datasets: [{
          label: 'New Review Count',
          fill: true,
          backgroundColor: chartConfigs.chartColors.defaultColors.green,
          borderWidth: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: barData,
        }],
        navigation: barNavigation,
      },
      extraOptions: {
        ...chartConfigs.barChartOptions,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: Math.max(...barData),
                padding: 20,
                fontColor: '#9e9e9e',
              },
              scaleLabel: {
                display: true,
                labelString: 'New Reviews',
              },
            },
          ],

          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#9e9e9e',
              },
            },
          ],
        },
      },
      gradientColors: config.colors.infoGradient,
      gradientStops: [1, 0.4, 0],
    };
  },
  /**
   * Getter to return reviewbox listing summary counts.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns listingsSummary object
   */
  reviewboxListingsSummary: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    const listingsSummary = [];

    // Check for negative review counts
    if (Object.prototype.hasOwnProperty.call(state.dashboard, 'negative')) {
      listingsSummary.push({ title: `${state.dashboard.negative} with Negative Reviews`, url: '/reviewbox/reviews?stars=2' });
    }

    // Calculate total listings and total reviews
    if (Object.prototype.hasOwnProperty.call(state.dashboard, 'asins')) {
      const listings = Object.keys(state.dashboard.asins);
      const totalReviews = listings.reduce(
        (acc, curr) => acc + state.dashboard.asins[curr], 0,
      );

      listingsSummary.push({ title: `${totalReviews} Total Reviews`, url: '/reviewbox/reviews?stars=5' });
    }

    if (listingsSummary.length) {
      return listingsSummary;
    }

    return null;
  },
  /**
   * Getter to roll-up all reviewbox dashboard data.
   *
   * @param {Object} state Dashboard module state
   * @param {Object} getters Dashboard module getter method
   * @returns {Object} Returns reviewbox dashboard data object
   */
  reviewboxDashboardSummary: (state, getters) => {
    if (state.dashboard === null) {
      return null;
    }

    if (
      getters.reviewboxRatingsDistributionChart === null
      && getters.reviewboxNewReviewsChart === null
      && getters.reviewboxListingsSummary === null
    ) {
      return null;
    }

    return {
      ratingsDistributionChart: getters.reviewboxRatingsDistributionChart,
      newReviewsChart: getters.reviewboxNewReviewsChart,
      listingsSummary: getters.reviewboxListingsSummary,
    };
  },
  /**
   * Getter to return pricebox buy box distribution chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns buy box chart object
   */
  priceboxBuyBoxDistributionChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'merchants')) {
      return null;
    }

    if (state.dashboard.merchants == null) {
      return null;
    }

    if (state.dashboard.merchants.length < 1) {
      return null;
    }

    const barLabels = [];
    const barData = [];

    // Populate chart data on subset of asins
    state.dashboard.merchants.forEach((merchant) => {
      barLabels.push(merchant.name === null ? 'No Merchant' : merchant.name);
      barData.push(merchant.count);
    });

    return {
      chartData: {
        labels: barLabels,
        datasets: [{
          fill: true,
          borderColor: config.colors.info,
          borderWidth: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: barData,
        }],
      },
      gradientColors: config.colors.infoGradient,
      gradientStops: [1, 1, 1],
      extraOptions: {
        ...chartConfigs.barChartOptions,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: Math.max(...barData),
                padding: 20,
                fontColor: '#9e9e9e',
              },
            },
          ],

          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#9e9e9e',
              },
            },
          ],
        },
      },
    };
  },
  /**
   * Getter to return pricebox listings summary data.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns pricebox listings summary object
   */
  priceboxListingsSummary: (state) => {
    if (state.dashboard === null) {
      return null;
    }
    const listingsSummary = [];

    if (
      Object.prototype.hasOwnProperty.call(state.dashboard, 'mapproducts')
      && state.dashboard.mapproducts != null
    ) {
      listingsSummary.push({ title: `${state.dashboard.mapproducts.True} in Map Violation` });
    }

    if (
      Object.prototype.hasOwnProperty.call(state.dashboard, 'stock')
      && state.dashboard.stock != null
    ) {
      listingsSummary.push({ title: `${state.dashboard.stock.out || 0} Listings Out of Stock` });
    }

    if (listingsSummary.length) {
      return listingsSummary;
    }

    return null;
  },
  /**
   * Getter to roll-up all pricebox dashboard data.
   *
   * @param {Object} state Dashboard module state
   * @param {Object} getters Dashboard module getter method
   * @returns {Object} Returns pricebox dashboard data object
   */
  priceboxDashboardSummary: (state, getters) => {
    if (
      getters.priceboxBuyBoxDistributionChart === null
      && getters.priceboxListingsSummary === null
    ) {
      return null;
    }

    return {
      buyBoxDistributionChart: getters.priceboxBuyBoxDistributionChart,
      listingsSummary: getters.priceboxListingsSummary,
    };
  },
  /**
   * Getter to return copybox description chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns a vue-chartjs object
   */
  copyboxContentTypePieChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'dtype')) {
      return null;
    }

    if (state.dashboard.dtype == null) {
      return null;
    }

    // Pie chart for proportion of content types.
    const pieColorsMap = {
      aplus: chartConfigs.chartColors.defaultColors.yellow,
      ebc: chartConfigs.chartColors.defaultColors.blue,
      plain: chartConfigs.chartColors.defaultColors.green,
    };
    const pieLabels = [];
    const pieData = [];
    const pieColors = [];

    if (Object.prototype.hasOwnProperty.call(state.dashboard.dtype, 'aplus')) {
      pieLabels.push('aplus');
      pieData.push(state.dashboard.dtype.aplus);
      pieColors.push(pieColorsMap.aplus);
    }

    if (Object.prototype.hasOwnProperty.call(state.dashboard.dtype, 'ebc')) {
      pieLabels.push('ebc');
      pieData.push(state.dashboard.dtype.ebc);
      pieColors.push(pieColorsMap.ebc);
    }

    if (Object.prototype.hasOwnProperty.call(state.dashboard.dtype, 'plain')) {
      pieLabels.push('plain');
      pieData.push(state.dashboard.dtype.plain);
      pieColors.push(pieColorsMap.plain);
    }

    // Construct chart.js pie chart object
    const chartData = {
      datasets: [{
        data: pieData,
        backgroundColor: pieColors,
        label: 'Content Type',
      }],
      labels: pieLabels,
    };

    return {
      chartData,
      extraOptions: chartConfigs.pieChartOptions,
    };
  },
  /**
   * Getter to return copybox listings summary data.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns copybox listings summary object
   */
  copyboxListingsSummary: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    const listingsSummary = [];

    if (Object.prototype.hasOwnProperty.call(state.dashboard, 'noncompliant')) {
      listingsSummary.push({ title: `${state.dashboard.noncompliant} Listings Are In Violation`, url: '#/copybox/content-latest?compliance=No' });
    }

    if (Object.prototype.hasOwnProperty.call(state.dashboard, 'nonguidance')) {
      listingsSummary.push({ title: `${state.dashboard.nonguidance} Are Not Following Content Guide`, url: '#/copybox/content-latest?guideline=Failed' });
    }

    if (listingsSummary.length) {
      return listingsSummary;
    }

    return null;
  },
  /**
   * Getter to construct copybox image features chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns vue-chartjs chart object
   */
  copyboxImageFeatureChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'images')) {
      return null;
    }

    if (state.dashboard.images == null) {
      return null;
    }

    const barLabels = [];
    const barData = [];

    // Map image counts from object to array
    const imageCounts = Object.keys(state.dashboard.images)
      .map((key) => ({
        label: parseInt(key, 10), // labels are string numbers "1" conver to 1
        count: state.dashboard.images[key],
      }));

    // Sort by new counts labels in ascending order
    imageCounts.sort((a, b) => a.label - b.label);

    // Populate chart data on subset of asins
    imageCounts.forEach((point) => {
      barLabels.push(point.label);
      barData.push(point.count);
    });

    return {
      chartData: {
        labels: barLabels,
        datasets: [{
          label: 'Number of Listings',
          fill: true,
          backgroundColor: chartConfigs.chartColors.defaultColors.green,
          borderWidth: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: barData,
        }],
      },
      extraOptions: {
        ...chartConfigs.barChartOptions,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: Math.max(...barData),
                padding: 20,
                fontColor: '#9e9e9e',
              },
              scaleLabel: {
                display: true,
                labelString: 'Number of Listings',
              },
            },
          ],

          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#9e9e9e',
              },
              scaleLabel: {
                display: true,
                labelString: 'Image Count',
              },
            },
          ],
        },
      },
      gradientColors: config.colors.infoGradient,
      gradientStops: [1, 0.4, 0],
    };
  },
  /**
   * Getter to construct copybox content features chart.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns vue-chartjs chart object
   */
  copyboxContentFeatureChart: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'features')) {
      return null;
    }

    if (state.dashboard.features == null) {
      return null;
    }

    const barLabels = [];
    const barData = [];

    // Map feature counts obj to array
    const featureCounts = Object.keys(state.dashboard.features)
      .map((key) => ({
        label: parseInt(key, 10), // labels are string numbers "1" conver to 1
        count: state.dashboard.features[key],
      }));

    // Sort by new counts labels in ascending order
    featureCounts.sort((a, b) => a.label - b.label);

    // Populate chart data on subset of asins
    featureCounts.forEach((point) => {
      barLabels.push(point.label);
      barData.push(point.count);
    });

    return {
      chartData: {
        labels: barLabels,
        datasets: [{
          label: 'Number of Listings',
          fill: true,
          backgroundColor: chartConfigs.chartColors.defaultColors.green,
          borderWidth: 0,
          borderDash: [],
          borderDashOffset: 0.0,
          data: barData,
        }],
      },
      extraOptions: {
        ...chartConfigs.barChartOptions,
        scales: {
          yAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: Math.max(...barData),
                padding: 20,
                fontColor: '#9e9e9e',
              },
              scaleLabel: {
                display: true,
                labelString: 'Number of Listings',
              },
            },
          ],

          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.1)',
                zeroLineColor: 'transparent',
              },
              ticks: {
                padding: 20,
                fontColor: '#9e9e9e',
              },
              scaleLabel: {
                display: true,
                labelString: 'Feature Count',
              },
            },
          ],
        },
      },
      gradientColors: config.colors.infoGradient,
      gradientStops: [1, 0.4, 0],
    };
  },
  /**
   * Getter to roll-up all copybox dashboard data.
   *
   * @param {Object} state Dashboard module state
   * @param {Object} getters Dashboard module getter method
   * @returns {Object} Returns copybox dashboard data object
   */
  copyboxDashboardSummary: (state, getters) => {
    if (
      getters.copyboxContentTypePieChart === null
      && getters.copyboxListingsSummary === null
      && getters.copyboxImageFeatureChart === null
      && getters.copyboxContentFeatureChart === null
    ) {
      return null;
    }

    return {
      contentTypePieChart: getters.copyboxContentTypePieChart,
      listingsSummary: getters.copyboxListingsSummary,
      imageFeatureChart: getters.copyboxImageFeatureChart,
      contentFeatureChart: getters.copyboxContentFeatureChart,
    };
  },
  /**
   * Getter to roll-up account service unit count.
   *
   * @param {Object} state Dashboard module state
   * @returns {Object} Returns object of license counts
   */
  accountLicenseSummary: (state) => {
    if (state.dashboard === null) {
      return null;
    }

    if (!Object.prototype.hasOwnProperty.call(state.dashboard, 'units')) {
      return null;
    }

    if (state.dashboard.units == null) {
      return null;
    }

    const licenseSummary = [];

    // Grab Reviewbox Unit Count
    if (
      Object.prototype.hasOwnProperty.call(state.dashboard.units, 'num_runits')
      && Object.prototype.hasOwnProperty.call(state.dashboard.units, 'max_runits')
    ) {
      licenseSummary.push({
        service: 'reviewbox',
        count: state.dashboard.units.num_runits,
        maxCount: state.dashboard.units.max_runits,
        type: 'info',
        icon: 'comment-text-outline-icon',
      });
    }

    // Grab Pricebox Unit Count
    if (
      Object.prototype.hasOwnProperty.call(state.dashboard.units, 'num_punits')
      && Object.prototype.hasOwnProperty.call(state.dashboard.units, 'max_punits')
    ) {
      licenseSummary.push({
        service: 'pricebox',
        count: state.dashboard.units.num_punits,
        maxCount: state.dashboard.units.max_punits,
        type: 'info',
        icon: 'dollar-icon',
      });
    }

    // Grab Copybox Unit Count
    if (
      Object.prototype.hasOwnProperty.call(state.dashboard.units, 'num_cunits')
      && Object.prototype.hasOwnProperty.call(state.dashboard.units, 'max_cunits')
    ) {
      licenseSummary.push({
        service: 'copybox',
        count: state.dashboard.units.num_cunits,
        maxCount: state.dashboard.units.max_cunits,
        type: 'info',
        icon: 'pencil-icon',
      });
    }

    return licenseSummary;
  },
  /**
   * Getter for loading status.
   *
   * @param {Object} state Dashboard module state
   * @returns {Boolean} returns loading status
   */
  isLoading: (state) => state.isLoading,
  /**
   * Getter to flag dashboard data status.
   *
   * @param {Object} state Dashboard module state
   * @param {Function} getters Dashboard module getters
   * @returns {Boolean} Returns true if data exists else false
   */
  isDashboardData: (state, getters) => {
    if (
      getters.reviewboxDashboardSummary === null
      && getters.priceboxDashboardSummary === null
      && getters.copyboxDashboardSummary === null
      && getters.accountLicenseSummary === null
    ) {
      return false;
    }

    return true;
  },

  getDashboardFilters(state) {
    if (state.dashboardFilters && state.dashboardFilters.form.filters.length > 20) {
      state.dashboardFilters.form.filters = state.dashboardFilters.form.filters.slice(0, 20);
    }
    return state.dashboardFilters;
  },
};
