var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group d-flex flex-column flex-fill",
      class: _vm.baseInputClasses,
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c(
                "label",
                {
                  class: { "d-flex align-items-center": _vm.tooltipText },
                  attrs: {
                    for: `${_vm.label}--${_vm.inputId || _vm.defaultId}`,
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.label) + " "),
                  !_vm.required
                    ? _c("span", { staticClass: "text-muted rb-text-help" }, [
                        _vm._v(" (Optional) "),
                      ])
                    : _vm._e(),
                  _vm.tooltipText
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: _vm.tooltipText,
                            "open-delay": 500,
                          },
                        },
                        [
                          _c("info-icon", {
                            staticClass:
                              "rb-icon-sm ml-1 text-muted cursor-pointer",
                            attrs: { title: "" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      }),
      _c(
        "div",
        { staticClass: "flex-fill d-flex flex-column", class: _vm.iconClass },
        [
          _vm._t("addonLeft", function () {
            return [
              _vm.addonLeftIcon
                ? _c("span", { staticClass: "input-group-prepend" }, [
                    _c("div", { staticClass: "input-group-text" }, [
                      _c("i", { class: _vm.addonLeftIcon }),
                    ]),
                  ])
                : _vm._e(),
            ]
          }),
          _vm._t("default", function () {
            return [
              _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "form-control",
                      attrs: {
                        id: `${_vm.label}--${_vm.inputId || _vm.defaultId}`,
                        autocomplete: "off",
                        "aria-describedby": "addon-right addon-left",
                        disabled: _vm.disabled,
                      },
                      domProps: { value: _vm.value },
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.listeners
                )
              ),
            ]
          }),
          _vm._t("addonRight", function () {
            return [
              _vm.addonRightIcon
                ? _c("span", { staticClass: "input-group-append" }, [
                    _c("div", { staticClass: "input-group-text" }, [
                      _c("i", { class: _vm.addonRightIcon }),
                    ]),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._t("error", function () {
        return [
          _c("small", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))]),
        ]
      }),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }