import account from './account';
// import adstation from './adstation';
import copybox from './copybox';
import dashboard from './dashboard';
import downloads from './downloads';
import pricebox from './pricebox';
import reports from './reports';
import reviewbox from './reviewbox';
import reviews from './reviews';
import questions from './questions';
import searchbox from './searchbox';
import grids from './grids';
import catalog from './catalog';
import tabs from './tabs';
// import insights from './insights';
import help from './help';

export default {
  account,
  // adstation,
  catalog,
  copybox,
  dashboard,
  downloads,
  pricebox,
  reports,
  reviewbox,
  reviews,
  questions,
  searchbox,
  grids,
  tabs,
  // insights,
  help,
};
