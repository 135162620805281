/**
 * IconsParser Mixin for handling specific icon representation in the app.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Function to parse filename and assign an
     * appropriate file image to file extension.
     *
     * @param {String} filename Filename
     * @returns {String} Returns icon matched to file extension
     */
    fileIcon(filename) {
      const extension = filename.slice((filename.lastIndexOf('.') + 1));

      switch (extension) {
        case 'xls':
        case 'xlsx':
          return 'excel-file-icon';
        case 'zip':
          return 'folder-zip-icon';
        default:
          return 'file-icon';
      }
    },
    /**
     * Returns an icon to signify a process status.
     *
     * @param {String} status Status of a process
     * @returns {String} Returns a string for icon classes
     */
    statusIcon(status) {
      switch (status) {
        case 'completed':
          return {
            type: 'success',
            icon: 'check-icon',
          };
        case 'submitted':
        case 'pending':
          return {
            type: 'warning',
            icon: 'timer-sand-icon',
          };
        case 'failed':
        case 'failedupload':
        default:
          return {
            type: 'danger',
            icon: 'close-icon',
          };
      }
    },
    /**
     * Returns an icon to represent a service.
     *
     * @param {String} service Name of service
     * @returns {String} String for icon class
     */
    serviceIcon(service = '') {
      switch (service.toLowerCase()) {
        case 'reviewbox':
        case 'reviews':
          return 'comment-text-outline-icon';
        case 'questions':
          return 'comment-questions-icon';
        case 'pricebox':
          return 'dollar-icon';
        case 'copybox':
          return 'pencil-icon';
        case 'searchbox':
          return 'search-icon';
        case 'searchterms':
          return 'format-text-variant-icon';
        default:
          return 'archive-icon';
      }
    },
  },
};
