import { render, staticRenderFns } from "./ProductInfoCellRenderer.vue?vue&type=template&id=142beec8&"
import script from "./ProductInfoCellRenderer.vue?vue&type=script&lang=js&"
export * from "./ProductInfoCellRenderer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('142beec8')) {
      api.createRecord('142beec8', component.options)
    } else {
      api.reload('142beec8', component.options)
    }
    module.hot.accept("./ProductInfoCellRenderer.vue?vue&type=template&id=142beec8&", function () {
      api.rerender('142beec8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Grid/CellRenderers/ProductInfoCellRenderer.vue"
export default component.exports