import BaseHttp from './BaseHttp';

class UserPlanHttp extends BaseHttp {
  /**
   * Fetch user's current subscription
   * @param {Object} params HTTP get parameters
   */
  getPlanSubscription() {
    return this.get(`${this.PrependRoutes.reviewbox}/subscription`);
  }
}

export default new UserPlanHttp();
