export default {
  /**
   * Mutation to set downloads in state.
   *
   * @param {Object} state Downloads module state
   * @param {Array} downloads Array of download objects
   */
  setDownloads(state, downloads) {
    state.downloads = downloads;
  },
  /**
   * Mutation to remove download from state.
   *
   * @param {Object} state Downloads module state
   */
  removeDownloads(state) {
    state.downloads = null;
  },
};
