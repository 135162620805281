import BaseHttp from './BaseHttp';

class CatalogHttp extends BaseHttp {
  /**
   * Get a user's archived listings by service
   *
   * @param {Object} params HTTP params object
   */
  async getArchivedListings(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/gettrashcan`, params)
      .then((response) => {
        /**
         * @todo Unpack listings to allow unarchiving at a per listing basis
         */
        // unpack response for easier data management
        // const { data: { deleted } } = response;
        // console.log('deleted', deleted);
        // const results = deleted.reduce((acc, curr) => {
        //   const items = [];

        //   curr.listings.forEach((listing) => {
        //     items.push({
        //       listing: listing[0],
        //       source: listing[1],
        //       time: curr.time, // format(curr.time * 1000, 'yyyy-MM-dd'),
        //     });
        //   });

        //   return acc.concat(items);
        // }, []);

        const { data: { archived } } = response;
        const results = archived.map((item) => ({
          time: item.time,
          listings: item.listings.map((i) => ({ listing: i[0], source: i[1] }))
        }));

        return results;
      });
  }

  async removeListingsFromTrashcan(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/takeouttrash`, params)
      .then((response) => response);
  }

  /**
   * @todo refactor api to just returns the fields needed and the counts of how many listings a field is present
   * e.g. [{ field: 'apple', num_listings: 25}]
   */
  async getCustomFields(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/fields`, params)
      .then((response) => {
        const { data: { fields } } = response;

        // Just flatten to get fields, don't care about field values
        if (fields) {
          const keys = Object.keys(fields);

          return keys.map((key) => ({ field: key }));
        }

        // if no fields exist just an empty array
        return [];
      });
  }

  async deleteCustomFields(params) {
    return this.delete(`${this.PrependRoutes.reviewbox}/fields`, params);
  }
}

export default new CatalogHttp();
