/**
 * Function to compare two dates for ag-grid, used for sorting date columns.
 *
 * @param {Date|String} filterLocalDateAtMidnight Date selected in the ag-grid calendar set at Midnight
 * @param {Date|String} cellValue Value of cell date to compare to
 * @returns {Number} returns numeric comparison between two dates
 */
export default (filterLocalDateAtMidnight, cellValue) => {
  try {
    if (!cellValue) {
      return -1;
    }

    if (!filterLocalDateAtMidnight) {
      return 1;
    }

    // Set Midnight in UTC hours to compare with ag-grid's midnight date
    // Comparator documentation can be found here:
    // https://www.ag-grid.com/vue-data-grid/filter-date/#reference-IDateFilterParams-comparator
    const first = new Date(new Date(filterLocalDateAtMidnight).setUTCHours(0, 0, 0, 0));
    const second = new Date(new Date(cellValue).setUTCHours(0, 0, 0, 0));

    if (second < first) {
      return -1;
    }

    if (second > first) {
      return 1;
    }

    return 0;
  } catch {
    // If invalid date, e.g. new Date('apple'), have a failsafe
    return 0;
  }
};
