import cloneDeep from 'lodash/cloneDeep';
import BaseHttp from './BaseHttp';

const originalColumns = [
  {
    headerName: 'Catalog',
    groupId: 'general',
    children: [
      {
        headerName: 'Show',
        field: 'checkbox',
      },
      {
        headerName: 'Source',
        field: 'retailer_source',
      },
      {
        headerName: 'ID',
        field: 'retailer_id',
      },
      {
        headerName: 'Name',
        field: 'name',
      },
    ],
  },
  {
    headerName: 'Questions',
    groupId: 'questions',
    children: [
      {
        headerName: 'Date',
        field: 'ts',
      },
      {
        headerName: 'Question',
        field: 'question',
      },
      {
        headerName: 'Question ID',
        field: 'id',
      },
    ],
  },
  {
    headerName: 'Response',
    groupId: 'response',
    children: [
      {
        headerName: 'Reply',
        field: 'reply',
      },
      {
        headerName: 'Status',
        field: 'status',
      },
      {
        headerName: 'Topic',
        field: 'topic',
      },
      {
        headerName: 'Custom',
        field: 'customCol',
      },
      {
        headerName: 'Notes',
        field: 'comment',
      },
      {
        headerName: 'Updates',
        field: 'updatetypes',
      },
      {
        headerName: 'Last Update',
        field: 'latestupdatets',
      },
    ],
  },
];

/**
 * Class to handle all Questions related http requests
 */
class QuestionsHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'Questions';

    // Default questions columns
    this.defaultColumns = [];

    // Columns that should be formatted as numbers
    this.numericColumns = [];
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      questionsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getQuestions(params.questions || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    /**
     * Check if a user has bazaarvoice, if so add the appropriate columns
     * @todo Can this be done on the backend so we don't have to manipulate things on the frontend?
     */
    if (params && params.hasBazaarvoiceIntegration) {
      this.addBazaarvoiceToDefaultColumns();
    } else {
      this.defaultColumns = cloneDeep(originalColumns);
    }

    return this.constructGrid(
      questionsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  async getQuestions(params = {}) {
    // Fetch Questions
    return this.get(`${this.PrependRoutes.reviewbox}/questions`, params, true)
      .then((response) => {
        const { data } = response;

        // Throw an error if no questions are returned
        if (
          !Object.prototype.hasOwnProperty.call(data, 'questions')
          || data.questions === null
        ) {
          throw new Error('Could not fetch questions');
        }

        // Throw an error if a warning is returned
        if (Object.prototype.hasOwnProperty.call(data, 'warning')) {
          throw new Error(data.warning);
        }

        /**
         * @todo Fix this hack, please. We have to insert field values AND product name
         * for reviews if we are fetching on a single listing. That is
         * because when we fetch for a listing's reviews, we do not
         * return custom field values as part of the data.reviews array.
         * The custom field values are returned as a separate field "data.fields".
         * This was because it's one listing, so all fields will just get applied
         * to this...however, doesn't make sense to change the structure of returned
         * JSON when passing queries. The structure should stay the same, the data
         * should just reflect the new queries. Otherwise we have to make workarounds
         * like this on the front-end.
         */
        const hasProductName = Object.prototype.hasOwnProperty.call(data, 'name');

        if (Object.prototype.hasOwnProperty.call(data, 'fields')
          && data.fields !== null
        ) {
          data.questions.forEach((question) => {
            Object.assign(question, data.fields);

            // Add product name
            if (hasProductName) {
              question.name = data.name;
            }
          });
        }

        // Return questions
        return data.questions;
      });
  }

  /**
   * Async function to fetch reviewbox saved filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Questions' });
  }

  /**
   * Async function to fetch reviewbox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: this.service });
  }

  /**
   * Function to create ag-grid row data.
   *
   * @param {Array} questionsData Array of product questions data
   * @param {Array} columnDefs Array of column data
   */
  static constructRowData(questionsData, columnDefs) {
    const rowData = [];

    // If no data just return empty array
    if (!questionsData || !questionsData.length) {
      return rowData;
    }

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    questionsData.forEach((question) => {
      const row = {};

      columnTypes.forEach((column) => {
        if (Object.prototype.hasOwnProperty.call(question, column)) {
          if (['ts', 'latestupdatets'].includes(column)) {
            row[column] = question[column] === null ? null : new Date(question[column] * 1000);
          } else if (column === 'listing') {
            [row[column]] = question.listing;
          } else if (column === 'name') {
            let productName = null;

            if (Object.prototype.hasOwnProperty.call(question, 'product_name') && question.product_name !== null) {
              productName = question.product_name;
            } else if (Object.prototype.hasOwnProperty.call(question, 'name') && question.name !== null) {
              productName = question.name;
            }

            row[column] = productName;
          } else {
            row[column] = question[column];
          }
        } else if (
          Object.prototype.hasOwnProperty.call(question, 'fields')
          && question.fields !== null
          && Object.prototype.hasOwnProperty.call(question.fields, column)
        ) {
          row[column] = question.fields[column];
        } else {
          row[column] = '';
        }

        if (column === null) {
          row[column] = '';
        }
      });

      /**
       * @todo Why do we randomly reassign custom_field to customCol?
       * Prob want to fix this later
       */
      row.customCol = question.custom_field;

      // Make sure to add in the listing & source even though we don't
      // directly display this information in the grid.
      // Keep in mind that the listings are a list so just pick the first one.
      [row.listing] = question.listing;
      row.source = question.source;

      if (question.source === 'bazaarvoice') {
        // This is a Bazaarvoice review. So instead of showing the
        // normal Bazaarvoice info, we should display the retailer
        // specific information.
        row.retailer_id = Object.prototype.hasOwnProperty.call(question, 'product_id')
          ? question.product_id
          : null;

        if (row.retailer_id && row.retailer_id.includes(question.product_retailer)) {
          const [splitId] = row.retailer_id.split(`-${question.product_retailer}`);
          row.retailer_id = splitId;
        }

        row.retailer_source = question.product_retailer;
        row.retailer_url = question.product_url;
      } else {
        [row.retailer_id] = question.listing;
        row.retailer_source = question.source;
        row.retailer_url = null;
      }

      row.reply = 'Leave a Reply';
      row.url = question.url;
      row.id = question.id;
      row.written_ts = question.ts;
      row.qurl = '/viewquestion?listing=';
      row.qurl += encodeURIComponent(question.listing);
      row.qurl += '&source=';
      row.qurl += encodeURIComponent(question.source);
      row.qurl += '&question=';
      row.qurl += encodeURIComponent(question.id);
      row.qurl += '&url=';
      row.qurl += encodeURIComponent(question.product_url);

      // Add row data
      rowData.push(row);
    });

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }))
      .sort((a, b) => a.agGridRowNumber - b.agGridRowNumber);
  }

  /**
   * Fetches a single question
   *
   * @param {Object} params Object of HTTP get parameters
   */
  async getQuestion(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/question`, params)
      .then((response) => response.data);
  }

  /**
   * Patch method to update a questions metadata.
   *
   * @param {Object} params HTTP request params object
   */
  async updateMetadata(params) {
    return this.patch(`${this.PrependRoutes.reviewbox}/question`, params)
      .then((response) => response.data);
  }

  addBazaarvoiceToDefaultColumns() {
    const defaultColumns = cloneDeep(originalColumns);

    // Only show this if users have BV data
    defaultColumns.push({
      headerName: 'Bazaarvoice',
      groupId: 'bazaarvoice',
      children: [
        {
          headerName: 'Moderation Status',
          field: 'bazaarvoice_moderation_status',
          filter: 'agSetColumnFilter',
        },
      ],
    });

    this.defaultColumns = defaultColumns;
  }
}

export default new QuestionsHttp();
