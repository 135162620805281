var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.isTerms
        ? _c(
            "base-input",
            {
              staticClass: "mb-0",
              attrs: {
                required: _vm.required,
                label: _vm.label,
                name: _vm.name,
                placeholder: _vm.placeholder,
                error: _vm.error,
              },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-primary",
                  attrs: {
                    error: _vm.error,
                    required: _vm.required,
                    disabled: _vm.disabled,
                  },
                  on: { change: () => _vm.onChangeField(_vm.selectedTerm) },
                  model: {
                    value: _vm.selectedTerm,
                    callback: function ($$v) {
                      _vm.selectedTerm = $$v
                    },
                    expression: "selectedTerm",
                  },
                },
                [
                  _c("el-option", {
                    attrs: {
                      value: null,
                      disabled: true,
                      label: _vm.placeholder || "Select",
                    },
                  }),
                  _vm._l(_vm.fieldOptions, function (option, optionIndex) {
                    return _c("el-option", {
                      key: optionIndex,
                      attrs: { value: option, label: option.name },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isTerms && !_vm.isSimple && _vm.isMounted
        ? _c(
            "div",
            _vm._l(_vm.selectGroups, function (group, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "custom-field-selects row",
                  class: { "mb-2": _vm.selectGroups.length > 1 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md" },
                    [
                      _c(
                        "base-input",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            required: _vm.required,
                            label: "Custom Field",
                            name: `input-group-${index}`,
                          },
                        },
                        [
                          _c("rb-select-filter", {
                            staticClass:
                              "select-primary custom-field-select-filter",
                            attrs: {
                              placeholder: _vm.placeholder || "Select",
                              options: _vm.fieldOptions,
                              disabled: _vm.disabled,
                              required: _vm.required,
                              "label-key": "name",
                              "search-keys": ["name"],
                            },
                            on: {
                              change: () =>
                                _vm.onChangeField(group.category, index),
                            },
                            model: {
                              value: group.category,
                              callback: function ($$v) {
                                _vm.$set(group, "category", $$v)
                              },
                              expression: "group.category",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c(
                        "base-input",
                        {
                          staticClass: "mb-0",
                          attrs: {
                            required: _vm.required,
                            label: "Custom Field Values",
                            name: "exportCategoryTerms",
                          },
                        },
                        [
                          _c("rb-select-filter", {
                            staticClass:
                              "select-primary rb-select rb-select-sources custom-field-select-filter",
                            attrs: {
                              disabled: _vm.termOptions.length > 0,
                              multiple: true,
                              "collapse-tags": true,
                              placeholder: _vm.placeholder || "Select",
                              options: group.term_options,
                              clearable: true,
                              "label-key": "name",
                              "search-keys": ["name"],
                            },
                            on: { change: () => _vm.onChangeTerms() },
                            model: {
                              value: group.selected_terms,
                              callback: function ($$v) {
                                _vm.$set(group, "selected_terms", $$v)
                              },
                              expression: "group.selected_terms",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-2 pt-4" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            "open-delay": 300,
                            content: "Remove custom field",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              "native-type": "button",
                              icon: "el-icon-close",
                              size: "small",
                              type: "danger",
                              circle: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onDeleteGroup(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      index >= _vm.selectGroups.length - 1 &&
                      _vm.selectGroups.length < _vm.fieldOptions.length
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                "open-delay": 300,
                                content: "Add additional custom field",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  "native-type": "button",
                                  icon: "el-icon-plus",
                                  size: "small",
                                  type: "success",
                                  circle: "",
                                },
                                on: { click: _vm.onAddGroup },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.isTerms && _vm.isSimple && _vm.isMounted
        ? _c(
            "div",
            { staticClass: "custom-field-selects" },
            [
              _c(
                "base-input",
                {
                  staticClass: "mb-0",
                  attrs: {
                    required: _vm.required,
                    label: "Group By Custom Field",
                    name: "input-group-simple",
                    error: _vm.error,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-primary",
                      attrs: {
                        required: _vm.required,
                        disabled: _vm.disabled,
                        error: _vm.error,
                      },
                      on: {
                        change: () =>
                          _vm.onChangeField(_vm.selectGroups[0].category, 0),
                      },
                      model: {
                        value: _vm.selectGroups[0].category,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectGroups[0], "category", $$v)
                        },
                        expression: "selectGroups[0].category",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          value: null,
                          disabled: true,
                          label: _vm.placeholder || "Select",
                        },
                      }),
                      _vm._l(_vm.fieldOptions, function (option, optionIndex) {
                        return _c("el-option", {
                          key: optionIndex,
                          attrs: { value: option, label: option.name },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.error && !_vm.isSimple && !_vm.isTerms
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }