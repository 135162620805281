import { filterServicesFromLinks } from '@/utils';
import cloneDeep from 'lodash/cloneDeep';

export default {
  hasInsightsAccess(state, getters) {
    try {
      const user = getters['account/mastername'] || getters['account/username'];

      return ['admin'].includes(user);
    } catch {
      return false;
    }
  },
  getSidebarLinks(state, getters) {
    let links = cloneDeep(state.sidebarLinks);

    /**
     * Show Insights Dashboard menu link if user has access, else default to old dashboard
     * @todo introduce insights alpha access when this feature is rolled out, in the meantime keep it the old dashboard
     */
    // if (getters.hasInsightsAccess) {
    //   links.unshift({
    //     title: 'Insights',
    //     icon: 'dashboard-icon',
    //     path: '/insights',
    //     isExternal: false,
    //     module: 'dashboards',
    //   });
    // } else {
    //   links.unshift({
    //     title: 'Dashboard',
    //     icon: 'dashboard-icon',
    //     path: '/dashboard',
    //     isExternal: false,
    //     module: 'dashboards',
    //   });
    // }
    // links.unshift({
    //   title: 'Dashboard',
    //   icon: 'dashboard-icon',
    //   path: '/dashboard',
    //   isExternal: false,
    //   module: 'dashboards',
    // });

    // Filter links based on a user's active services
    links = filterServicesFromLinks(links, getters['account/getActiveServices']);

    return links;
  },
  showHelp: (state) => state.showHelp,
  defaultHelpSearch: (state) => state.defaultHelpSearch,
  showDownloads: (state) => state.showDownloads,
};
