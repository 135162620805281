import { GridHttp } from '@/services/api';

export default {
  /**
   * Action to call setGridIsLoading mutation.
   *
   * @param {Function} commit Function to call a mutation
   * @param {Object} gridState Object of grid state
   */
  setGridLoading({ commit }, payload) {
    commit('setGridLoading', payload);
  },
  /**
   * Action to call setGridState mutation.
   *
   * @param {Function} commit Function to call a mutation
   * @param {Function} dispatch Function to call an action
   * @param {Object} gridState Object of grid state
   */
  setGridState({ commit }, gridState) {
    commit('setGridState', gridState);
  },
  /**
   * Action to call removeGridState mutation.
   *
   * @param {Function} commit Function to call a mutation
   * @param {String} service Name of grid to remove
   */
  removeGridState({ commit }, service) {
    commit('removeGridState', service);
  },
  /**
   * Action to call removeAllGridState mutation.
   *
   * @param {Function} commit Function to call a mutation
   */
  removeAllGridState({ commit }) {
    commit('removeAllGridState');
  },
  /**
   * Action to reset the gridState for a specific page
   *
   * @param {Function} commit Function to call a mutation
   */
  resetGridStateByKey({ commit }, key) {
    commit('resetGridStateByKey', key);
  },

  /**
   * Action to fetch reviewbox grid data.
   *
   * @param {Function} commit Function to call a mutation
   */
  async getGridData({ commit }, payload) {
    const { service, params, gridDateRange } = payload;

    // Toggle is loading
    commit('setGridLoading', { service, status: true });

    return GridHttp.getGridData(service, params)
      .then((data) => {
        const gridState = {
          service,
          columns: data.columns,
          rows: data.rows,
          savedFilters: data.savedFilters,
          customFields: data.customFields,
        };

        // If grid uses date range, add that to grid state object
        if (gridDateRange) {
          gridState.gridDateRange = gridDateRange;
        }

        commit('setGridState', gridState);

        return gridState;
      })
      .finally(() => {
        // Always set loading state back to false
        commit('setGridLoading', { service, status: false });
      });
  },
  /**
   * Action to update a grid column name.
   *
   * @param {Function} commit Function to call vuex mutations
   * @param {Object} payload Parameters to update column name
   */
  updateColumnName({ commit }, payload) {
    commit('updateColumnName', payload);
  },
  /**
   * Action to save grid filters for a service
   *
   * @param {Function} commit Function to call vuex mutations
   * @param {Object} payload Parameters to update column name
   */
  saveGridFilters({ commit }, payload) {
    commit('saveGridFilters', payload);
  },
  /**
   * Action to clear grid filters for a service
   *
   * @param {Function} commit Function to call vuex mutations
   * @param {Object} payload Parameters to update column name
   */
  clearGridFilters({ commit }, payload) {
    commit('clearGridFilters', payload);
  },
  /**
   * Action to add grid filters for a service
   *
   * @param {Function} commit Function to call vuex mutations
   * @param {Object} payload Parameters to update column name
   */
  addGridFilters({ commit }, payload) {
    commit('addGridFilters', payload);
  },
  /**
   * Action to delete grid filters for a service
   *
   * @param {Function} commit Function to call vuex mutations
   * @param {Object} payload Parameters to update column name
   */
  removeGridFilters({ commit, state }, payload) {
    /**
     * @todo We need to fix the back-end to split searchbox and searchterms
     * otherwise the filters lists are not in sync for the grids in the front-end.
     *
     * This is a work around till that fix. We check if deleting a filter
     * for searchterm and add it to searchbox, and vice versa
     */
    let { service } = payload;

    // Convert service to lowercase because
    // we store the filters with a capitalized name
    // i.e. Reviews --> reviews
    service = service.toLowerCase();

    if (service === 'searchterms' && state.gridStates.searchbox) {
      // update grid filters for searchbox
      commit('removeGridFilters', {
        ...payload,
        service: 'searchbox',
      });
    } else if (service === 'searchbox' && state.gridStates.searchterms) {
      // update grid filters for searchbox
      commit('removeGridFilters', {
        ...payload,
        service: 'searchterms',
      });
    } else if (service === 'copybox' && state.gridStates.content) {
      // update grid filters for copyboxReference
      commit('removeGridFilters', {
        ...payload,
        service: 'content',
      });
    } else if (service === 'content' && state.gridStates.copybox) {
      // update grid filters for copybox
      commit('removeGridFilters', {
        ...payload,
        service: 'copybox',
      });
    } else if (service === 'reviews' && state.gridStates.reviews) {
      // update grid filters for reviews
      commit('removeGridFilters', {
        ...payload,
        service: 'reviews',
      });
    } else if (service === 'questions' && state.gridStates.questions) {
      // update grid filters for questions
      commit('removeGridFilters', {
        ...payload,
        service: 'questions',
      });
    } else {
      /**
       * End of work around
       */
      commit('removeGridFilters', payload);
    }
  },
};
