import BaseHttp from './BaseHttp';

/**
 * Class to handle all Adstation related http requests
 */
class AdAccountsHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'AdAccounts';
  }

  /**
   * Fetches a user's adstation accounts info.
   */
  getAdAccounts() {
    return this.get(`${this.PrependRoutes.ams}/account`)
      .then((response) => response.data);
  }

  /**
   * Link up either a NA/EU Amazon ad account.
   */
  linkAccount(source) {
    return this.get(`${this.PrependRoutes.ams}/authportal`, { country: source })
      .then((response) => response.data);
  }

  /**
   * Unlink an existing ad account.
   */
  unlinkAccount(account) {
    const payload = { profile: account.profileId, source: account.source };

    return this.delete(`${this.PrependRoutes.ams}/account`, payload)
      .then((response) => response.data);
  }
}

export default new AdAccountsHttp();
