var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column rb-date-range-input",
      class: { active: _vm.dateRange && !_vm.disabled },
    },
    [
      _vm.label
        ? _c(
            "label",
            [_vm._v(" " + _vm._s(_vm.label) + " "), _vm._t("label-tooltip")],
            2
          )
        : _vm._e(),
      _c("date-range-picker", {
        ref: "picker",
        class: { disabled: _vm.disabled },
        attrs: {
          opens: "right",
          "locale-data": _vm.dateLocale,
          "max-date": _vm.maxDate,
          "show-dropdowns": true,
          "auto-apply": true,
          "linked-calendars": true,
          disabled: _vm.disabled,
        },
        on: { update: _vm.onInput },
        scopedSlots: _vm._u(
          [
            {
              key: "input",
              fn: function (picker) {
                return _vm.dateYear !== 1989
                  ? _c("div", {}, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("date")(picker.startDate)) +
                          " - " +
                          _vm._s(_vm._f("date")(picker.endDate)) +
                          " "
                      ),
                    ])
                  : _c("div", { attrs: { slot: "input" }, slot: "input" }, [
                      _vm._v(" All "),
                    ])
              },
            },
            {
              key: "ranges",
              fn: function () {
                return [
                  _c("div", { staticClass: "ranges" }, [
                    _c(
                      "ul",
                      _vm._l(_vm.ranges, function (item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "date-range-option",
                            class: { active: _vm.isDateActive(item.value) },
                            on: {
                              click: function ($event) {
                                return _vm.onSelectRange(item.value)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }