import { NO_RESPONSE_DICTIONARY, RESPONSE_DICTIONARY } from '@/utils';

/**
 * Global Mixin to handle any errors in the app and
 * display them as notifications.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Handle displaying errors for the app.
     *
     * @param {Object} error Error object
     * @param {Object} options Notification options config
     * @param {String} customMessage Whether to send a custom message to notifications UI,
     * sometimes backend errors are not user friendly, e.g. "System Error"
     * @param {Boolean} useRollbar Whether to send errors to Rollbar, sometimes
     * it's a validation error that doesn't need to be tracked in Rollbar
     *
     * @todo Refactor error handler to be more flexible
     */
    handleError(
      error,
      { type = 'error', duration = 10000, position = 'bottom-right', title = null, iconClass = '' } = {},
      customMessage = null,
      useRollbar = true
    ) {
      if (error.message === 'No user credentials') {
        const path = this.$route.path !== '/login' ? this.$route.path : '/';

        // Only logout if already logged in
        if (this.$route.path !== '/login') {
          this.$store.dispatch(
            'account/logOutUser',
            { router: this.$router, redirect: path },
          );
        }
      } else {
        let { message } = error;

        // We probably don't want to show certain error messages
        // to clients, so we run through the no response dictionary.
        if (!message || NO_RESPONSE_DICTIONARY[message] || error instanceof TypeError) {
          return;
        }

        if (RESPONSE_DICTIONARY[message]) {
          // Run the message through the response dictionary so that
          // we display more user friendly error messages.
          message = RESPONSE_DICTIONARY[message];
        }

        if (customMessage !== null) {
          message = customMessage;
        }

        // Let the user know they successfully saved the dashboard
        this.$notify({
          type,
          position,
          iconClass,
          // When we want to customize the message, add links, etc. NEVER pass in a user's input to a notification
          dangerouslyUseHTMLString: true,
          title,
          message,
          duration
        });

        // Track any errors in Rollbar
        if (useRollbar) {
          const payload = {};

          // Add account as context to triggered error
          if (this.$store.state.account && this.$store.state.account.user) {
            payload.user = this.$store.state.account.user.name;
            payload.masterUser = this.$store.state.account.user.masterUser;
          }

          this.$rollbar.error(message, error, payload);
        }
      }
    },
    /**
     * Handle displaying warnings for the app.
     *
     * @param {Object} warning Warning object
     */
    handleWarning(warning) {
      this.$notify({
        type: 'warning',
        position: 'bottom-right',
        message: warning.message,
      });
    },
  },
};
