import CopyboxHttp from './CopyboxHttp';

/**
 * CopyboxLatest Class manages fetching for the latest copybox data.
 */
class CopyboxLatestHttp extends CopyboxHttp {
  constructor() {
    super();

    this.service = 'copybox';
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || {}),
    ]);

    return this.constructGrid(
      listingData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData
    );
  }

  /**
   * Async method to fetch listing data for copyboxLatest
   *
   * @param {Object} params HTTP params object
   */
  async getListings(params = {}) {
    const response = await this.getListingData(this.service, params);

    /* eslint-disable no-await-in-loop */
    while (response.page) {
      const moreResponse = await this.getListingData(this.service, {
        ...params,
        page: response.page,
      });
      response.listings = response.listings.concat(moreResponse.listings);
      response.page = moreResponse.page;
    }
    /* eslint-enable no-await-in-loop */

    return response.listings;
  }

  /**
   * Async function to fetch copybox saved filters.
   *
   * @todo Make CopyboxLatest have its own saved filters.
   * Right now the saved filters are shared with CopyboxReference.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Copybox' });
  }

  /**
   * Async function to fetch copybox saved grid preferences.
   *
   * @todo Make CopyboxLatest have is own saved grid preferences.
   * Right now the grid preferences are shared with CopyboxReference.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: 'Copybox' });
  }

  /**
   * Async function to get historical copybox info.
   *
   * @param {Object} params HTTP get params
   */
  async getContentHistory(params) {
    return this.get(
      `${this.PrependRoutes.reviewbox}/contenthistory`,
      params
    ).then((response) => response.data);
  }
}

export default new CopyboxLatestHttp();
