/**
 * Only import what we need from plotly to help reduce bundle size
 * This is mostly used for viewing reports, so we use that to reference
 * what we need from plotly
 *
 * @see: https://community.plotly.com/t/modularizing-plotly/6029
 * @todo: Bundle size is still large - but that's because it's plotly,
 * Maybe we could use a charting library that is smaller?
 */
import plotlyCore from 'plotly.js/lib/core';
import plotlyBar from 'plotly.js/lib/bar';
import plotlyPie from 'plotly.js/lib/pie';
import plotlyTable from 'plotly.js/lib/table';
import plotlyScatter from 'plotly.js/lib/scatter';
import plotlyBox from 'plotly.js/lib/box';
import plotlyHistogram from 'plotly.js/lib/histogram';

const plotlyCharter = plotlyCore;
plotlyCharter.register(plotlyBar);
plotlyCharter.register(plotlyPie);
plotlyCharter.register(plotlyTable);
plotlyCharter.register(plotlyScatter);
plotlyCharter.register(plotlyBox);
plotlyCharter.register(plotlyHistogram);

export default plotlyCharter;
