import { dateTimeReviver } from '@/utils';

/**
 * Returns a object of dashboard module state.
 *
 * @param {Object} dashboard Object containing dashboard data
 * @param {Object} dashboardFilters Object of dashboard filters
 * @param {Boolean} isLoading Boolean of loading status
 *
 * @returns {Object} Default state of dashboard module
 */
export default {
  dashboard: JSON.parse(localStorage.getItem('dashboard')),
  dashboardFilters: JSON.parse(localStorage.getItem('dashboardFilters'), dateTimeReviver),
  isLoading: false,
};
