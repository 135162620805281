var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid-frame" },
    [
      _vm.gridTitle
        ? _c("h4", [_vm._v(" " + _vm._s(_vm.gridTitle) + " ")])
        : _vm._e(),
      _c("ag-grid-vue", {
        staticClass: "grid-frame-ag-grid ag-theme-balham",
        attrs: { "column-defs": _vm.columnDefs, "row-data": _vm.rowData },
        on: { "grid-ready": _vm.onGridReady },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }