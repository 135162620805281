var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.label
        ? _c(
            "label",
            [
              _vm._v(" " + _vm._s(_vm.label) + " "),
              !_vm.required
                ? _c("span", { staticClass: "text-muted" }, [
                    _vm._v(" (Optional) "),
                  ])
                : _vm._e(),
              _vm.tooltip
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.tooltip, "open-delay": 500 } },
                    [
                      _c("info-icon", {
                        staticClass: "text-muted cursor-pointer",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "base-radio",
          {
            key: index,
            attrs: {
              required: _vm.required,
              value: option.value,
              name: option.value,
            },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          },
          [_vm._v(" " + _vm._s(option.name) + " ")]
        )
      }),
      _vm.error
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }