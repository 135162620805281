var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "rb-menu-link rb-sidenav-list-item",
      class: { active: _vm.isActive },
    },
    [
      !_vm.link.isExternal
        ? _c(
            "el-popover",
            {
              staticClass: "w-100",
              attrs: {
                "popper-class": "rb-sidenav-children-popper ml-0",
                placement: "right",
                trigger: "hover",
                "open-delay": 500,
                disabled: !_vm.hasChildren,
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "rb-sidenav-link w-100",
                  attrs: {
                    slot: "reference",
                    target: "_self",
                    to: _vm.link.path,
                  },
                  slot: "reference",
                },
                [
                  _vm.link.icon
                    ? _c(_vm.link.icon, {
                        tag: "component",
                        staticClass: "rb-menu-icon rb-icon-lg",
                      })
                    : _vm._e(),
                  _c("span", { staticClass: "rb-menu-text" }, [
                    _vm._v(" " + _vm._s(_vm.link.title) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "rb-children-list shadow px-0 mb-0" },
                _vm._l(_vm.link.children, function (child) {
                  return _c(
                    "router-link",
                    {
                      key: child.title,
                      staticClass:
                        "rb-children-list-item rb-sidenav-link text-reset d-flex align-items-center p-3",
                      attrs: { target: "_self", to: child.path },
                    },
                    [
                      child.icon
                        ? _c(child.icon, {
                            tag: "component",
                            staticClass: "rb-menu-icon mr-2",
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "rb-child-list-item-title" }, [
                        _vm._v(" " + _vm._s(child.title) + " "),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.link.isExternal
        ? _c(
            "a",
            {
              staticClass: "rb-sidenav-link w-100",
              attrs: { target: "_blank", href: _vm.link.path },
            },
            [
              _vm.link.icon
                ? _c(_vm.link.icon, {
                    tag: "component",
                    staticClass: "rb-menu-icon rb-icon-lg",
                  })
                : _vm._e(),
              _c("span", { staticClass: "rb-menu-text" }, [
                _vm._v(" " + _vm._s(_vm.link.title) + " "),
                _c("sup", [_c("link-icon", { staticClass: "rb-icon-xs" })], 1),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }