// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import 'src/polyfills';

// Validation plugin used to validate forms
import VeeValidate from 'vee-validate';

// A plugin file where you could register global components used across the app
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import GlobalComponents from './globalComponents';

// asset imports
import 'src/assets/sass/rb-theme.scss';
import 'src/assets/css/nucleo-icons.css';

locale.use(lang);

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
  },
};
