export default {
  /**
   * Mutation to set responseIntegrations for reviews service.
   *
   * @param {Object} state Reviews store data object
   * @param {Array} responseIntegrations List of response integration by source
   */
  setResponseIntegrations(state, responseIntegrations = []) {
    state.responseIntegrations = responseIntegrations;
    localStorage.setItem('reviews/responseIntegrations', JSON.stringify(responseIntegrations));
  },
  /**
   * Mutation to reset responseInegrations to default of empty array.
   *
   * @param {Object} state Reviews store data object
   */
  resetResponseIntegrations(state) {
    state.responseIntegrations = [];
    localStorage.removeItem('reviews/responseIntegrations');
  },
  /**
   * Mutation to set Chart's Height.
   *
   * @param {Object} state Reviews store data object
   * @param {Boolean} status Height of Charts in Pixels
   */
  setChartsHeight(state, status) {
    state.chartsHeight = status;
    localStorage.setItem('reviews/chartsHeight', status);
  },
  /**
   * Mutation to set showCharts status.
   *
   * @param {Object} state Reviews store data object
   * @param {Boolean} status Flag for displaying review charts
   */
  setShowCharts(state, status) {
    state.reviewsShowCharts = status;
    localStorage.setItem('reviews/showCharts', JSON.stringify(status));
  },
  /**
   * Mutation to reset showCarts flag to default of true.
   *
   * @param {Object} state Reviews store data object
   */
  resetShowCharts(state) {
    state.reviewsShowCharts = true;
    localStorage.removeItem('reviews/showCharts');
  },
};
