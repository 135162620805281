import Vue from 'vue';
import Vuex from 'vuex';

import state from './global/state';
import getters from './global/getters';
import actions from './global/actions';
import mutations from './global/mutations';
import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { ...modules },
  state,
  actions,
  getters,
  mutations,
});

export default store;
