var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c(
        "base-input",
        {
          staticClass: "mb-0",
          attrs: {
            required: _vm.required,
            label: _vm.label,
            name: _vm.name,
            placeholder: _vm.placeholder,
            disabled: _vm.isDisabled,
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "select-primary",
              attrs: { disabled: _vm.isDisabled },
              on: { change: () => _vm.onSelectBrand(_vm.selectedBrand) },
              model: {
                value: _vm.selectedBrand,
                callback: function ($$v) {
                  _vm.selectedBrand = $$v
                },
                expression: "selectedBrand",
              },
            },
            [
              _c("el-option", {
                attrs: {
                  value: null,
                  disabled: true,
                  label: _vm.placeholder || "Select",
                },
              }),
              _vm._l(_vm.options, function (option, optionIndex) {
                return _c("el-option", {
                  key: optionIndex,
                  attrs: { value: option, label: option.name },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.error
        ? _c("small", { staticClass: "text-danger" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }