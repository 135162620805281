export default {
  /**
   * Action to synchronize date ranges selected across service pages.
   * e.g.) If a user selects last 30 days in pricebox, the date ranges will
   * be updated for the dashboard, reviews, etc.
   *
   * @param {Function} commit commits actions across VueX store
   * @param {Object} dateRanges Object of date ranges {startDate, endDate}
   */
  synchronizeDateRanges({ commit }, dateRange) {
    commit('grids/synchronizeGridDateRange', dateRange, { root: true });
    commit('dashboard/synchronizeDashboardDateRange', dateRange, { root: true });
    commit('setDateRange', dateRange);
  },
};
