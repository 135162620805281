export default {
  colors: {
    default: '#344675',
    primary: '#2e9a72',
    info: '#1d8cf8',
    danger: '#fd5d93',
    orange: '#ff8a76',
    teal: '#00d6b4',
    primaryGradient: [
      'rgba(76, 211, 150, 0.1)',
      'rgba(53, 183, 125, 0)',
      'rgba(119,52,169,0)',
    ],
    successGradient: [
      'rgba(66, 220, 163, 0.7)',
      'rgba(66, 220, 163, 0.3)',
      'rgba(66, 220, 163, 0)',
    ],
    infoGradient: [
      'rgba(64, 167, 213, 0.7)',
      'rgba(64, 167, 213, 0.3)',
      'rgba(64, 167, 213, 0)',
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)'],
  },
};
