import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      isUsingTemplate: false,
      isLoadingTemplate: false,
      openTemplateModal: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedDashboardTemplate: 'insights/selectedDashboardTemplate',
    })
  },
  methods: {
    ...mapActions({
      getSelectedDashboardTemplate: 'insights/getSelectedDashboardTemplate',
    }),
    ...mapMutations({
      setSelectedDashboardTemplate: 'insights/setSelectedDashboardTemplate',
    }),
    /**
     * Opens a template modal to select which dashboard template to use creating a dashboard.
     */
    onUseTemplate() {
      this.openTemplateModal = true;
    },
    /**
     * Close the Edit Widget Modal and dialog
     */
    onCloseTemplateModal() {
      this.openTemplateModal = false;
    },
    /**
     * Method to handle using a dashboard template when creating a new dashboard.
     */
    onDashboardTemplateSelected(template, redirect = false, dashboardId = null) {
      // Should fetch to get the layout, clone template as a new one - strip out ids
      this.isLoadingTemplate = true;
      return this.getSelectedDashboardTemplate({ id: template.id, dashboardId })
        .then(() => {
          // Then redirect to create page
          if (redirect) {
            this.$router.push('/insights/create/dashboard');
          }
        })
        .catch((error) => {
          console.error('Could not get template', error.message);
          this.handleError(error);
        })
        .finally(() => {
          this.isLoadingTemplate = false;
        });
    },
    /**
     * Method to handle triggering a Hubspot feedback form for Insights Dashboard
     */
    onGiveFeedback() {
      console.log('user wants to give feedback');
    },
  }
};
