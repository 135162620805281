import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      totalActiveListings: 'account/getTotalActiveListings',
    })
  },
  methods: {
    /**
     * Check whether a user is subscribed to a service
     *
     * @param { String } service
     */
    isServiceSubscribed(service) {
      if (!service) {
        return true;
      }

      if (this.totalActiveListings && this.totalActiveListings[service] && this.totalActiveListings[service].limit > 0) {
        return true;
      }

      return false;
    },
  }
};
