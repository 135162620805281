var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      staticClass: "rb-downloads-drawer",
      attrs: {
        title: "Support and Feedback",
        visible: _vm.showDownloads,
        size: "45%",
        "destroy-on-close": "",
      },
      on: { close: _vm.onDownloadsDrawerClosed },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c("h4", [_vm._v(" Downloads ")]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " All download links expire after two weeks. You will need to request a new report or export download any time after a link has expired. "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "downloads-container bg-gray-light" },
        [_c("downloads")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }