import BaseHttp from './BaseHttp';

/**
 * Class to handle all pricebox related http requests
 */
class SearchboxTermsHttp extends BaseHttp {
  constructor() {
    super();

    this.service = 'searchterms';

    // Default reviewbox columns
    this.defaultColumns = [
      {
        headerName: 'Catalog',
        groupId: 'general',
        children: [
          {
            headerName: '',
            field: 'checkbox',
          },
          {
            headerName: 'Source',
            field: 'source',
          },
          {
            headerName: 'Search Term',
            field: 'term',
          },
        ],
      },
      {
        headerName: 'Search Terms',
        groupId: 'searchterms',
        children: [
          {
            headerName: 'Best Rank',
            field: 'rank',
          },
          {
            headerName: 'Best Listing',
            field: 'listingname',
          },
          {
            headerName: 'Total Listings',
            field: 'numlistings',
          },
          {
            headerName: 'Unknown Listings',
            field: 'numunknown',
          },
        ],
      },
    ];

    // Columns that should be formatted as numbers
    this.numericColumns = [
      'rank',
      'numlistings',
      'numunknown',
    ];
  }

  /**
   * Async function to fetch reviewwbox grid data.
   *
   * @param {Object} params HTTP get parameters
   */
  async getGridData(params = {}) {
    const [
      termsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    ] = await Promise.all([
      this.getListings(params.listings || {}),
      this.getSavedPreferences(params.preferences || {}),
      this.getSavedFilters(),
      this.getProductCustomFields(params.filters || { service: 'terms' }),
    ]);

    return this.constructGrid(
      termsData,
      gridPreferencesData,
      savedGridFiltersData,
      customFieldsData,
    );
  }

  async getListings(params = {}) {
    return this.getListingData(this.service, params);
  }

  /**
   * Async function to fetch pricebox saved filters.
   *
   * @todo We'll want to split out saved grid filters so that SearchboxTerms
   * and SearchboxProducts have their own filters.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedFilters() {
    return this.getSavedGridFilters({ service: 'Searchterms' });
  }

  /**
   * Async function to fetch pricebox saved grid preferences.
   *
   * @param {Object} params HTTP get parameters
   */
  async getSavedPreferences() {
    return this.getSavedGridPreferences({ grid_type: 'Searchterms' });
  }

  /**
   * Async function to update custom field metadata for searchbox terms.
   *
   * @param {Object} params HTTP Post params object
   */
  async updateSearchTermsCustomFields(params) {
    return this.post(`${this.PrependRoutes.reviewbox}/searchterms`, params);
  }

  /**
   * Async function to get search terms.
   *
   * @param {Object} params HTTP Post params object
   */
  async getSearchTerms(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/searchterms`, params)
      .then((response) => response.data);
  }

  /**
   * Async function to get a single search term.
   *
   * @param {Object} params HTTP Post params object
   */
  async getSearchTerm(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/searchterm`, params)
      .then((response) => response.data);
  }

  /**
   * Static method to construct ag-grid row data for searchboxProducts.
   *
   * @param {Array} termsData Array of objects for searchbox products listings metadata
   * @param {Array} columnDefs Array of ag-grid column definitions
   */
  static constructRowData(termsData, columnDefs) {
    const rowData = [];

    // Return empty array of row data if termsData does not exist
    if (!termsData || !termsData.length) {
      return rowData;
    }

    // Flatten out all child column field names
    const columnTypes = columnDefs.reduce(
      (acc, curr) => acc.concat(curr.children.map((x) => x.field)), [],
    );

    termsData.forEach((term) => {
      const row = {};

      columnTypes.forEach((column) => {
        // Handle default fields
        if (Object.prototype.hasOwnProperty.call(term, column)) {
          if (
            term.listing === null
            && ['listingname', 'rank'].includes(column)
          ) {
            if (column === 'listingname') {
              row[column] = 'No Listing Is Ranking On Term';
            } else {
              row[column] = 10000;
            }
          } else {
            row[column] = term[column];
          }
          // Handle custom fields
        } else if (
          Object.prototype.hasOwnProperty.call(term, 'fields')
          && term.fields !== null
          && Object.prototype.hasOwnProperty.call(term.fields, column)
        ) {
          row[column] = term.fields[column];
        } else {
          row[column] = '';
        }

        if (row[column] === null) {
          row[column] = '';
        }
      });

      row.listing = term.listing;

      rowData.push(row);
    });

    return rowData.map((item, index) => ({
      ...item,
      agGridRowNumber: index + 1,
    }));
  }
}

export default new SearchboxTermsHttp();
