import Vue from 'vue';

export default {
  onResponseTemplateWalkthrough() {
    const tour = Vue.prototype.$shepherd({
      useModalOverlay: true,
      classPrefix: 'rb-tour-',
    });

    const walkthroughOptions = {
      cancelIcon: {
        enabled: true,
        label: 'Cancel walkthrough',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [10, 24] } }]
      },
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const footer = currentStepElement.querySelector('.shepherd-footer');
          const progress = document.createElement('div');
          progress.classList.add('rb-walkthrough-steps', 'mr-auto', 'd-flex', 'align-items-center', 'text-muted');

          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length} Steps`;
          footer.prepend(progress);
        }
      }
    };

    tour.addSteps([
      {
        id: 'step-1',
        title: 'Creating Response Templates',
        text: '<p>Click <strong>Create template</strong> button to add a new response template.</p><p>You can create response templates for questions or reviews.</p>',
        attachTo: {
          element: '.rb-response-templates-create-button',
          on: 'top',
        },
        buttons: [
          {
            text: 'Cancel',
            action: tour.complete,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Filter table',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-2',
        title: 'Filtering Response Templates',
        text: '<div><p>To view a subset of templates, use the filter toolbar above the table.</p><p>For example, selecting <strong>questions</strong> as a template type, will display templates for questions only.</p><p>Search by name to find a specific template.</p></div>',
        attachTo: {
          element: '.rb-response-templates-filters',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-cancel'
          },
          {
            text: 'Response table',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-3',
        title: 'Response Templates Table',
        text: '<p>Use the table to manage your existing response templates.</p>',
        attachTo: {
          element: '.rb-response-templates-table',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Table actions',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-4',
        title: 'Table Actions',
        text: '<p>If you want to update an existing template, click the <strong>edit button</strong>.</p><p>To delete an existing template, click the <strong>delete button</strong>.</p>',
        attachTo: {
          element: '.rb-response-templates-table-actions',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-5',
        title: 'Need More Help?',
        text: '<p>Checkout our <a href="https://helpcenter.getreviewbox.com/how-to-set-up-and-use-response-templates" target="_blank">Help Center</a> for more information about the Response Management solution.</p><p>Don\'t see what you need? Please <a href="https://helpcenter.getreviewbox.com/kb-tickets/new" target="_blank">contact us</a>. We are happy to help!',
        isCentered: true,
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Done',
            action: tour.complete,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
    ]);

    tour.start();
  },
  /**
   * Tour guide walkthrough for the review and question response panel.
   *
   * @param {Boolean} isReview flag whether it is for a review or question
   */
  onResponseManagementWalkthrough(_, isReview) {
    const label = isReview ? 'Review' : 'Question';
    const tour = Vue.prototype.$shepherd({
      useModalOverlay: true,
      classPrefix: 'rb-tour-',
      exitOnEsc: false,
    });

    const isButtonPresent = document.querySelector('.rb-response-management-more-actions');

    const walkthroughOptions = {
      cancelIcon: {
        enabled: true,
        label: 'Cancel walkthrough',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [10, 24] } }]
      },
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const footer = currentStepElement.querySelector('.shepherd-footer');
          const progress = document.createElement('div');
          progress.classList.add('rb-walkthrough-steps', 'mr-auto', 'd-flex', 'align-items-center', 'text-muted');

          progress.innerText = `${tour.steps.indexOf(tour.currentStep) + 1}/${tour.steps.length} Steps`;
          footer.prepend(progress);
        }
      }
    };

    const steps = [
      {
        id: 'step-1',
        title: `${label} Information`,
        text: `<p>You can read relevant information about a ${label.toLowerCase()}, such as <strong>title</strong>, <strong>${label.toLocaleLowerCase()} text</strong>, <strong>author</strong>, and more.</p>`,
        attachTo: {
          element: '.rb-response-management-content',
          on: 'top',
        },
        buttons: [
          {
            text: 'Cancel',
            action: tour.complete,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Adding notes',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-2',
        title: 'Adding notes',
        text: `<p>Write additional information or context for a ${label.toLowerCase()} to reference at a future date.</p>`,
        attachTo: {
          element: '.rb-response-management-notes',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-cancel'
          },
          {
            text: `${label} responses`,
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-3',
        title: `${label} Responses`,
        text: `<p>Read responses from the <strong>author</strong> of the ${label.toLowerCase()}, or from <strong>users</strong> who respond through Reviewbox.</p><p>This will be an <strong>active timeline</strong> of all responses.</p>`,
        attachTo: {
          element: '.rb-response-management-response-timeline',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: `Respond to a ${label.toLowerCase()}`,
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-4',
        title: `Respond to a ${label}`,
        text: `<p>If you have <strong>response integrations</strong> enabled, you can click in the text area and <strong>respond</strong> to the ${label.toLowerCase()} directly from the app.</p>`,
        attachTo: {
          element: '.rb-response-management-responding',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: `Edit ${label.toLowerCase()} values`,
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-5',
        title: `Update ${label} Values`,
        text: `<p><p>Update values associated with a ${label.toLowerCase()}. These can be the <strong>Status</strong>, <strong>Sentiment</strong>, <strong>Topic</strong>, and <strong>Custom</strong> values.</p><p>These values will be reflected in the <strong>${label.toLowerCase()} grid</strong> upon changing.</p>`,
        attachTo: {
          element: '.rb-response-management-input-values',
          on: 'left',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: isButtonPresent ? 'More actions' : 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
    ];

    if (isButtonPresent) {
      steps.push({
        id: 'step-5',
        title: 'More Actions',
        text: `<p>Perform additional actions associated with a ${label.toLowerCase()}, such as reporting abusive or helpful reviews on Amazon.</p>`,
        attachTo: {
          element: '.rb-response-management-more-actions',
          on: 'left',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      });
    }

    steps.push({
      id: 'step-7',
      title: 'Need More Help?',
      text: '<p>Checkout our <a href="https://helpcenter.getreviewbox.com/reviewbox-response-integration" target="_blank">Help Center</a> for more information about the Response Management solution.</p><p>Don\'t see what you need? Please <a href="https://helpcenter.getreviewbox.com/kb-tickets/new" target="_blank">contact us</a>. We are happy to help!',
      isCentered: true,
      buttons: [
        {
          text: 'Back',
          action: tour.back,
          classes: 'btn btn-link rb-btn-back'
        },
        {
          text: 'Done',
          action: tour.complete,
          classes: 'btn btn-primary rb-btn-next'
        },
      ],
      ...walkthroughOptions,
    });

    tour.addSteps(steps);

    tour.start();
  },
  onSelectReportsAndExportsWalkthrough() {
    const tour = Vue.prototype.$shepherd({
      useModalOverlay: true,
      classPrefix: 'rb-tour-',
    });

    const isButtonPresent = document.querySelector('.rb-report-select-button');

    const walkthroughOptions = {
      cancelIcon: {
        enabled: true,
        label: 'Cancel walkthrough',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [10, 24] } }]
      },
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const footer = currentStepElement.querySelector('.shepherd-footer');
          const progress = document.createElement('div');
          progress.classList.add('rb-walkthrough-steps', 'mr-auto', 'd-flex', 'align-items-center', 'text-muted');

          const stepIndex = tour.steps.indexOf(tour.currentStep) + 1;
          const stepLength = tour.steps.length;

          progress.innerText = `${stepIndex}/${stepLength} Steps`;
          footer.prepend(progress);
        }
      }
    };

    const steps = [
      {
        id: 'step-1',
        title: 'Filter by Search',
        text: '<p>Search on the <strong>name</strong>, <strong>service</strong>, or <strong>type</strong> for any given report or export.</p>',
        attachTo: {
          element: '.rb-report-search-input',
          on: 'top',
        },
        buttons: [
          {
            text: 'Cancel',
            action: tour.complete,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Filter service',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-2',
        title: 'Filter by Service',
        text: '<p>Filter reports and exports by the <strong>service</strong>.</p><p>For example, <strong>Pricebox</strong> will have <em>Buy Box Details</em>, <em>Sales Rank Details</em>, and more exports and reports available.</p>',
        attachTo: {
          element: '.rb-report-service-input',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-cancel'
          },
          {
            text: 'Filter type',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-3',
        title: 'Filter by Type',
        text: '<p>Filter by the type of option, such as <strong>Report</strong> or <strong>Export</strong>.</p><p>Want to see everything? Filter on <strong>All</strong>.</p>',
        attachTo: {
          element: '.rb-report-type-input',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: isButtonPresent ? 'Select option' : 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
    ];

    if (isButtonPresent) {
      steps.push({
        id: 'step-4',
        title: 'Select Report or Export',
        text: '<p>Once you\'ve filtered and found what you need, click the <strong>select</strong> button to create your chosen export or report.</p>',
        attachTo: {
          element: '.rb-report-select-button',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        showOn() {
          return isButtonPresent;
        },
        ...walkthroughOptions,
      });
    }

    steps.push({
      id: 'step-5',
      title: 'Need More Help?',
      text: '<p>Checkout our Help Center articles on <a href="https://helpcenter.getreviewbox.com/kb-search-results?term=export" target="_blank">Exports</a> or <a href="https://helpcenter.getreviewbox.com/kb-search-results?term=report" target="_blank">Reports</a> for more information.</p><p>Want to know what data is in each export? You can download our <a href="https://helpcenter.getreviewbox.com/reviewbox-data-dictionary" target="_blank">Data Dictionary</a> for a detailed overview.</p><p>Don\'t see what you need? Please <a href="https://helpcenter.getreviewbox.com/kb-tickets/new" target="_blank">contact us</a>. We are happy to help!',
      isCentered: true,
      buttons: [
        {
          text: 'Back',
          action: tour.back,
          classes: 'btn btn-link rb-btn-back'
        },
        {
          text: 'Done',
          action: tour.complete,
          classes: 'btn btn-primary rb-btn-next'
        },
      ],
      ...walkthroughOptions,
    });

    tour.addSteps(steps);
    tour.start();
  },
  onActiveReportsAndExportsWalkthrough() {
    const tour = Vue.prototype.$shepherd({
      useModalOverlay: true,
      classPrefix: 'rb-tour-',
    });

    const isReportCardPresent = document.querySelector('.rb-report-card');

    const walkthroughOptions = {
      cancelIcon: {
        enabled: true,
        label: 'Cancel walkthrough',
      },
      popperOptions: {
        modifiers: [{ name: 'offset', options: { offset: [10, 24] } }]
      },
      when: {
        show() {
          const currentStepElement = tour.currentStep.el;
          const footer = currentStepElement.querySelector('.shepherd-footer');
          const progress = document.createElement('div');
          progress.classList.add('rb-walkthrough-steps', 'mr-auto', 'd-flex', 'align-items-center', 'text-muted');

          const stepIndex = tour.steps.indexOf(tour.currentStep) + 1;
          const stepLength = tour.steps.length;

          progress.innerText = `${stepIndex}/${stepLength} Steps`;
          footer.prepend(progress);
        }
      }
    };

    let steps = [
      {
        id: 'step-1',
        title: 'Filter by Search',
        text: '<p>Search on the <strong>name</strong>, <strong>description</strong>, <strong>type</strong>, or <strong>notes</strong> for any given report or export.</p>',
        attachTo: {
          element: '.rb-report-search-input',
          on: 'top',
        },
        buttons: [
          {
            text: 'Cancel',
            action: tour.complete,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: 'Filter type',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-2',
        title: 'Filter by Type',
        text: '<p>Filter reports and exports by the <strong>type</strong>.</p><p>For example, selecting <strong>Exports</strong> will display all active exports you have saved.</p>',
        attachTo: {
          element: '.rb-report-types-input',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-cancel'
          },
          {
            text: 'Create report',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
      {
        id: 'step-3',
        title: 'Create Report or Export',
        text: '<p>Click to create a Report or Export of your choosing.</p><p>To learn about all the options available, check out our <a href="https://helpcenter.getreviewbox.com/data-exports-and-reports" target="_blank">Help Center</a> guides.</p>',
        attachTo: {
          element: '.rb-create-button-group',
          on: 'top',
        },
        buttons: [
          {
            text: 'Back',
            action: tour.back,
            classes: 'btn btn-link rb-btn-back'
          },
          {
            text: isReportCardPresent ? 'Active reports' : 'More help',
            action: tour.next,
            classes: 'btn btn-primary rb-btn-next'
          },
        ],
        ...walkthroughOptions,
      },
    ];

    if (isReportCardPresent) {
      steps = steps.concat([
        {
          id: 'step-4',
          title: 'Active Reports and Exports',
          text: `<div>
            <p>
              All active reports and exports are represented as cards with relevant information such as <strong>Name</strong>, <strong>Description</strong>, <strong>Filename</strong>, <strong>Report Range</strong>, and <strong>Notes</strong>.
            </p>
            <p>
              You also have the option to <strong>view</strong>, <strong>edit</strong>, <strong>download</strong>, and <strong>share</strong> any report or export.
            </p>
          </div>`,
          attachTo: {
            element: '.rb-report-card',
            on: 'top',
          },
          buttons: [
            {
              text: 'Back',
              action: tour.back,
              classes: 'btn btn-link rb-btn-back'
            },
            {
              text: 'Share and delete',
              action: tour.next,
              classes: 'btn btn-primary rb-btn-next'
            },
          ],
          showOn() {
            return isReportCardPresent;
          },
          ...walkthroughOptions,
        },
        {
          id: 'step-5',
          title: 'Sharing or Deleting',
          text: `<div>
            <p>
              You can click the dropdown to <strong>share</strong> or <strong>delete</strong> a report or export. Sharing lets external parties view an active report.
            </p>
            <p>
              Deleting will permanently remove a saved report or export. Deleting <strong>cannot</strong> be undone.
            </p>
          </div>`,
          attachTo: {
            element: '.rb-share-delete-button-group',
            on: 'top',
          },
          buttons: [
            {
              text: 'Back',
              action: tour.back,
              classes: 'btn btn-link rb-btn-back'
            },
            {
              text: 'View and edit',
              action: tour.next,
              classes: 'btn btn-primary rb-btn-next'
            },
          ],
          showOn() {
            return isReportCardPresent;
          },
          ...walkthroughOptions,
        },
        {
          id: 'step-6',
          title: 'Viewing and Editing',
          text: `<div>
            <p>
              You can click to <strong>view</strong> or <strong>edit</strong> a report or export.
            </p>
            <p>
              Viewing a report will direct you to simplified dashboard of charts and tables for a given dataset.
            </p>
            <p>
              Editing will let you update the current settings for a saved report or export.
            </p>
          </div>`,
          attachTo: {
            element: '.rb-view-edit-button-group',
            on: 'top',
          },
          buttons: [
            {
              text: 'Back',
              action: tour.back,
              classes: 'btn btn-link rb-btn-back'
            },
            {
              text: 'Download',
              action: tour.next,
              classes: 'btn btn-primary rb-btn-next'
            },
          ],
          showOn() {
            return isReportCardPresent;
          },
          ...walkthroughOptions,
        },
        {
          id: 'step-7',
          title: 'Downloading',
          text: `<div>
            <p>
              You can click the dropdown to trigger a <strong>download</strong> for any report or export you have saved.
            </p>
            <p>
              You will be provided with a list of file types. The most common will be <strong>CSV</strong> for exports and <strong>Excel</strong> for reports.
            </p>
          </div>`,
          attachTo: {
            element: '.rb-download-button-group',
            on: 'top',
          },
          buttons: [
            {
              text: 'Back',
              action: tour.back,
              classes: 'btn btn-link rb-btn-back'
            },
            {
              text: 'More help',
              action: tour.next,
              classes: 'btn btn-primary rb-btn-next'
            },
          ],
          showOn() {
            return isReportCardPresent;
          },
          ...walkthroughOptions,
        },
      ]);
    }

    steps.push({
      id: 'step-8',
      title: 'Need More Help?',
      text: '<p>Checkout our Help Center articles on <a href="https://helpcenter.getreviewbox.com/kb-search-results?term=export" target="_blank">Exports</a> or <a href="https://helpcenter.getreviewbox.com/kb-search-results?term=report" target="_blank">Reports</a> for more information.</p><p>Want to know what data is in each export? You can download our <a href="https://helpcenter.getreviewbox.com/reviewbox-data-dictionary" target="_blank">Data Dictionary</a> for a detailed overview.</p><p>Don\'t see what you need? Please <a href="https://helpcenter.getreviewbox.com/kb-tickets/new" target="_blank">contact us</a>. We are happy to help!',
      isCentered: true,
      buttons: [
        {
          text: 'Back',
          action: tour.back,
          classes: 'btn btn-link rb-btn-back'
        },
        {
          text: 'Done',
          action: tour.complete,
          classes: 'btn btn-primary rb-btn-next'
        },
      ],
      ...walkthroughOptions,
    });

    tour.addSteps(steps);
    tour.start();
  },
};
