import BaseHttp from './BaseHttp';

class CloudStorageHttp extends BaseHttp {
  /**
   * Fetch credentials for customer's cloud storage
   */
  async getStorage() {
    return this.get(`${this.PrependRoutes.reviewbox}/cloudstorage`);
  }

  /**
   * Add or edit storage credentials for customer's cloud storage
   *
   * @param {Object} payload HTTP post params object
   */
  async postStorage(payload) {
    /**
     * @todo secret_key on GET and 'secretKey' on POST
     */
    payload.secretKey = payload.secret_key;

    return this.post(`${this.PrependRoutes.reviewbox}/cloudstorage`, payload);
  }

  /**
   * Delete a user's cloud storage bucket
   *
   * @param {Object} payload HTTP delete params object
   */
  async deleteStorage(payload) {
    return this.delete(`${this.PrependRoutes.reviewbox}/cloudstorage`, { name: payload });
  }
}

export default new CloudStorageHttp();
