import { render, staticRenderFns } from "./SearchTermsSelect.vue?vue&type=template&id=dfc85264&scoped=true&"
import script from "./SearchTermsSelect.vue?vue&type=script&lang=js&"
export * from "./SearchTermsSelect.vue?vue&type=script&lang=js&"
import style0 from "./SearchTermsSelect.vue?vue&type=style&index=0&id=dfc85264&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfc85264",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/service/packages/reviewbox-web-app/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dfc85264')) {
      api.createRecord('dfc85264', component.options)
    } else {
      api.reload('dfc85264', component.options)
    }
    module.hot.accept("./SearchTermsSelect.vue?vue&type=template&id=dfc85264&scoped=true&", function () {
      api.rerender('dfc85264', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/Reports/Components/FormInputs/SearchTermsSelect.vue"
export default component.exports