/**
 * SplitCamelCase Mixin for formatting camelCase -> Camel Case
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  methods: {
    /**
     * Function to split camelCase -> Camel Case
     *
     * @param {String} value String value in camelCase
     * @returns {String} Returns camelCase split string
     */
    splitCamelCase(value) {
      return value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    },
  },
};
