var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("sidenav", { attrs: { links: _vm.sidebarLinks } }),
      _c(
        "div",
        { staticClass: "main-panel", attrs: { data: _vm.sidebarBackground } },
        [
          _c("dashboard-navbar"),
          _c(
            "div",
            {
              staticClass: "container-fluid content",
              class: { "top-spacer": !_vm.$route.meta.hideTopSpacer },
            },
            [_c("router-view")],
            1
          ),
          !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
        ],
        1
      ),
      _c("help-drawer"),
      _c("downloads-drawer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }