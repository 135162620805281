export default {
  tabsHistory: JSON.parse(localStorage.getItem('tabs/history')) || {
    reviewbox: {
      name: 'Reviews',
      path: '/reviewbox/reviews',
    },
    pricebox: {
      name: 'Pricebox Products',
      path: '/pricebox/products',
    },
    copybox: {
      name: 'Content Latest',
      path: '/copybox/content-latest',
    },
    searchbox: {
      name: 'Searchbox Products',
      path: '/searchbox/products',
    },
    adstation: {
      name: 'Campaigns',
      path: '/adstation/campaigns',
    },
    account: {
      name: 'Profile',
      path: '/account/profile',
    },
  },
};
