export default {
  addItemsLinks: [
    {
      title: 'Content',
      // TODO:
      // When we get more catalog actions, convert this to a
      // more actions dropdown list.
      // btnIcon: 'el-icon-more',
      // btnPlain: true,
      service: 'copybox',
      children: [
        {
          title: 'Update Content Guidelines',
          to: {
            path: '/catalog-management/update-content-guidelines',
          },
          icon: 'pencil',
          service: 'copybox',
        },
        {
          title: 'Bulk Upload Reference Content',
          to: {
            path: '/catalog-management/add-reference-content',
          },
          icon: 'cloud-upload',
          service: 'copybox',
        },
      ],
    },
    {
      title: 'Terms',
      service: 'searchbox',
      btnType: 'secondary',
      children: [
        {
          title: 'Add Single Term',
          to: {
            path: '/catalog-management/add-terms',
            query: { isBulk: false },
          },
          icon: 'plus',
          service: 'searchterms'
        },
        {
          title: 'Bulk Upload Terms',
          to: {
            path: '/catalog-management/add-terms',
            query: { isBulk: true },
          },
          icon: 'cloud-upload',
          service: 'searchterms'
        },
        {
          title: 'Delete Single Term',
          to: {
            path: '/catalog-management/delete-terms',
            query: { isBulk: false },
          },
          icon: 'delete',
          service: 'searchterms',
          divided: true
        },
        {
          title: 'Bulk Delete Terms',
          to: {
            path: '/catalog-management/delete-terms',
            query: { isBulk: true },
          },
          icon: 'delete',
          service: 'searchterms',
        },
      ],
    },
    {
      title: 'Listings',
      btnType: 'primary',
      children: [
        {
          title: 'Add Single Listing',
          to: {
            path: '/catalog-management/add-listings',
            query: { isBulk: false },
          },
          icon: 'plus',
        },
        {
          title: 'Bulk Upload Listings',
          to: {
            path: '/catalog-management/add-listings',
            query: { isBulk: true },
          },
          icon: 'cloud-upload',
        },
        {
          title: 'Delete Single Listing',
          to: {
            path: '/catalog-management/delete-listings',
            query: { isBulk: false },
          },
          icon: 'delete',
          divided: true
        },
        {
          title: 'Bulk Delete Listings',
          to: {
            path: '/catalog-management/delete-listings',
            query: { isBulk: true },
          },
          icon: 'delete',
        },
      ],
    },
  ],
  catalogCount: {}
};
