// Import Utils
import { dateRanges, dateTimeReviver, servicesMetadata } from '@/utils';

export default {
  sidebarLinks: [
    // Dashboards page
    {
      title: servicesMetadata.dashboard.label,
      icon: servicesMetadata.dashboard.icon,
      path: '/dashboard',
      isExternal: false,
      module: 'dashboards',
    },
    // Reports Page
    {
      title: servicesMetadata.reports.label,
      path: '/reports',
      icon: servicesMetadata.reports.icon,
      isExternal: false,
      module: 'reports',
    },
    // Products Pages
    {
      title: servicesMetadata.reviewbox.label,
      path: '/reviewbox',
      icon: servicesMetadata.reviewbox.icon,
      service: 'reviewbox',
      isExternal: false,
      module: 'reviewbox',
      children: [
        {
          title: servicesMetadata.reviews.label,
          path: '/reviewbox/reviews',
          icon: servicesMetadata.reviews.icon,
          service: 'reviewbox',
          isExternal: false,
          module: 'reviewbox',
        },
        {
          title: servicesMetadata.questions.label,
          path: '/reviewbox/questions',
          icon: servicesMetadata.questions.icon,
          service: 'reviewbox',
          isExternal: false,
          module: 'reviewbox',
        },
        {
          title: servicesMetadata.products.label,
          path: '/reviewbox/products',
          icon: servicesMetadata.products.icon,
          service: 'reviewbox',
          isExternal: false,
          module: 'reviewbox',
        },
      ],
    },
    {
      title: servicesMetadata.pricebox.label,
      path: '/pricebox',
      icon: servicesMetadata.pricebox.icon,
      service: 'pricebox',
      isExternal: false,
      module: 'pricebox',
      children: [
        {
          title: servicesMetadata.products.label,
          path: '/pricebox/products',
          icon: servicesMetadata.products.icon,
          service: 'pricebox',
          isExternal: false,
          module: 'pricebox',
        },
        {
          title: servicesMetadata.sellers.label,
          path: '/pricebox/sellers',
          icon: servicesMetadata.sellers.icon,
          service: 'pricebox',
          isExternal: false,
          module: 'pricebox',
        },
      ],
    },
    {
      title: servicesMetadata.copybox.label,
      path: '/copybox',
      icon: servicesMetadata.copybox.icon,
      service: 'copybox',
      isExternal: false,
      module: 'copybox',
      children: [
        {
          title: servicesMetadata.contentLatest.label,
          path: '/copybox/content-latest',
          icon: servicesMetadata.contentLatest.icon,
          service: 'copybox',
          isExternal: false,
          module: 'copybox',
        },
        {
          title: servicesMetadata.contentReference.label,
          path: '/copybox/content-reference',
          icon: servicesMetadata.contentReference.icon,
          service: 'copybox',
          isExternal: false,
          module: 'copybox',
        },
      ],
    },
    {
      title: servicesMetadata.searchbox.label,
      path: '/searchbox',
      icon: servicesMetadata.searchbox.icon,
      service: 'searchbox',
      isExternal: false,
      module: 'searchbox',
      children: [
        {
          title: servicesMetadata.products.label,
          path: '/searchbox/products',
          icon: servicesMetadata.products.icon,
          service: 'searchbox',
          isExternal: false,
          module: 'searchbox',
        },
        {
          title: servicesMetadata.terms.label,
          path: '/searchbox/terms',
          icon: servicesMetadata.terms.icon,
          service: 'searchbox',
          isExternal: false,
          module: 'searchbox',
        },
      ],
    },
    // Catalog Management Page
    {
      title: servicesMetadata.catalog.label,
      icon: servicesMetadata.catalog.icon,
      path: '/catalog-management',
      isExternal: false,
      module: 'catalog',
    },
    // Accounts Page
    {
      title: servicesMetadata.account.label,
      icon: servicesMetadata.account.icon,
      path: '/account',
      isExternal: false,
      module: 'account',
      children: [
        {
          title: servicesMetadata.profile.label,
          path: '/account/profile',
          icon: servicesMetadata.profile.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.plan.label,
          path: '/account/plan',
          icon: servicesMetadata.plan.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.adAccounts.label,
          path: '/account/ad-accounts',
          icon: servicesMetadata.adAccounts.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.exportSettings.label,
          path: '/account/export-settings',
          icon: servicesMetadata.exportSettings.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.notifications.label,
          path: '/account/notification-settings',
          icon: servicesMetadata.notifications.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.storageSettings.label,
          path: '/account/storage-settings',
          icon: servicesMetadata.storageSettings.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.responseTemplates.label,
          path: '/account/response-templates',
          icon: servicesMetadata.responseTemplates.icon,
          isExternal: false,
          module: 'account',
        },
        {
          title: servicesMetadata.reasonCodes.label,
          path: '/account/reason-codes',
          icon: servicesMetadata.reasonCodes.icon,
          isExternal: false,
          module: 'account',
        },
      ],
    },
  ],
  // Default app date range will be "today"
  dateRange: JSON.parse(localStorage.getItem('global/dateRange'), dateTimeReviver) || {
    startDate: dateRanges.options.last7Days[0],
    endDate: dateRanges.options.last7Days[1],
  },
  showHelp: false,
  defaultHelpSearch: '',
  showDownloads: false,
};
