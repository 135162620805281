import DefaultLayout from 'src/layouts/default.vue';

export default {
  path: '/account',
  component: DefaultLayout,
  meta: {
    requiresAuth: true,
    module: 'account',
  },
  children: [
    {
      path: '',
      redirect: '/',
      name: 'Account',
      meta: {
        requiresAuth: true,
        module: 'account',
      },
      components: {
        default: () => import('src/pages/Account/UserProfileLayout.vue'),
      },
      children: [
        {
          path: '',
          redirect: '/account/profile',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/Profile/Profile.vue'),
          },
        },
        {
          path: 'plan',
          name: 'Plan Summary',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/Plans/Payments.vue'),
          },
        },
        {
          path: 'ad-accounts',
          name: 'Ad Accounts',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/AdAccounts/AdAccounts.vue'),
          },
        },
        {
          path: 'export-settings',
          name: 'Export Settings',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/ExportSettings/ExportSettings.vue'),
          },
        },
        {
          path: 'notification-settings',
          name: 'Notification Settings',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/NotificationSettings/NotificationSettings.vue'),
          },
        },
        {
          path: 'storage-settings',
          name: 'Storage Settings',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/StorageSettings/StorageSettings.vue'),
          },
        },
        {
          path: 'response-templates',
          name: 'Response Templates',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/ResponseTemplates/ResponseTemplates.vue'),
          },
        },
        {
          path: 'reason-codes',
          name: 'Reason Codes',
          meta: {
            requiresAuth: true,
            module: 'account',
          },
          components: {
            default: () => import('src/pages/Account/ReviewAnalysis/Investigation/Investigation.vue'),
          },
        },
      ],
    },
  ],
};
