var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "card-body d-flex d-flex justify-content-center align-items-center",
          },
          [
            _c("div", { staticClass: "icon-wrapper skeleton rounded mr-3" }),
            _vm._m(0),
          ]
        )
      : _c("div", { staticClass: "card-body d-flex" }, [
          _vm.icon
            ? _c(
                "div",
                {
                  staticClass:
                    "icon-wrapper bg-secondary text-white mr-3 rounded d-flex justify-content-center align-items-center",
                },
                [
                  _c(_vm.icon, {
                    tag: "component",
                    staticClass: "rb-icon rb-icon-xl",
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "service-content" },
            [
              _c(
                "h6",
                {
                  staticClass:
                    "service-title text-capitalize mb-1 font-weight-700",
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
              _vm.listingLimit !== 0
                ? [
                    _c(
                      "p",
                      { staticClass: "listing-active small text-muted mb-1" },
                      [
                        _c("strong", [
                          _vm._v(
                            _vm._s(Number(_vm.listingActive).toLocaleString())
                          ),
                        ]),
                        _vm._v(
                          " active " +
                            _vm._s(
                              _vm.listingService === "searchterms"
                                ? "terms"
                                : "listings"
                            ) +
                            " "
                        ),
                        _vm.hasListingTotal && _vm.listingSelected
                          ? _c("span", [
                              _vm._v("in " + _vm._s(_vm.listingSelected)),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm.hasListingTotal
                      ? _c(
                          "p",
                          {
                            staticClass: "listing-active small text-muted mb-1",
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content:
                                    "Total active listings include active listings across all accounts within your license.",
                                  "open-delay": 700,
                                },
                              },
                              [
                                _c("span", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        Number(
                                          _vm.listingTotal
                                        ).toLocaleString()
                                      )
                                    ),
                                  ]),
                                  _vm._v(" total active listings "),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      { staticClass: "listing-limit small text-muted mb-0" },
                      [
                        _c("strong", [
                          _vm._v(
                            _vm._s(Number(_vm.listingLimit).toLocaleString())
                          ),
                        ]),
                        _vm._v(
                          " allowed " +
                            _vm._s(
                              _vm.listingService === "searchterms"
                                ? "terms"
                                : "listings"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "p",
                      { staticClass: "listing-active small text-muted mb-1" },
                      [_vm._v(" Not subscribed ")]
                    ),
                    _vm._m(1),
                  ],
              _vm.catalogUpdates
                ? [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content:
                            "It may take 12 hours before results are visible.",
                          "open-delay": 700,
                        },
                      },
                      [
                        _c(
                          "el-tag",
                          {
                            staticClass: "mt-2",
                            attrs: {
                              size: "small",
                              type: _vm.catalogUpdates.isDelete
                                ? "danger"
                                : "success",
                            },
                          },
                          [
                            _vm.catalogUpdates.total > _vm.listingLimit
                              ? _c("strong", [
                                  _vm._v(" " + _vm._s(_vm.listingLimit) + " "),
                                ])
                              : _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.catalogUpdates.total > 1000
                                          ? "1000+"
                                          : _vm.catalogUpdates.total
                                      ) +
                                      " "
                                  ),
                                ]),
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.catalogUpdates.isDelete
                                      ? "removed"
                                      : "added"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex-grow-1" }, [
      _c("div", { staticClass: "skeleton skeleton-text mb-2" }),
      _c("div", { staticClass: "skeleton skeleton-text" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "listing-active small text-muted mb-0" }, [
      _c(
        "a",
        {
          staticClass: "text-primary font-weight-bold",
          attrs: {
            href: "https://www.getreviewbox.com/contact-reviewbox",
            target: "_blank",
          },
        },
        [_vm._v(" Contact sales ")]
      ),
      _vm._v(" to upgrade "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }