export default {
  /**
   * Mutation to update the Catalog counts.
   *
   * @param {Object} state Account module state
   */
  updateCatalogCount(state, payload) {
    state.catalogCount = payload;
  }
};
