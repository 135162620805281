export default {
  /**
   * Getter to determine loading status of Account pages.
   *
   * @param {Object} state Account module state
   * @returns {Boolean} returns loading state
   */
  getAccountLoading: (state) => state.accountIsLoading,
  /**
   * Getter to extract username from user state.
   *
   * @param {Object} state Account module state
   * @returns {String} returns a user's name
   */
  userName: (state) => {
    if (state.user) {
      return state.user.name.toLowerCase();
    }

    return null;
  },
  /**
   * Name of active user
   */
  username(state) {
    if (state.user && state.user.name) {
      return state.user.name;
    }

    return null;
  },
  /**
   * Name of user's master account (company)
   */
  mastername(state) {
    if (state.user && state.user.masterUser) {
      return state.user.masterUser;
    }

    return null;
  },
  /**
   * Get the active user logged in
   */
  getUser: (state) => state.user,
  getMasterAccount: (state) => {
    if (state.user && Object.prototype.hasOwnProperty.call(state.user, 'masterUser')) {
      return state.user.masterUser;
    }

    return null;
  },

  /**
  * Getter to get the User Name
  *
  * @param {Object} state Account module state
  * @param {Object} getters Account module getters
  * @returns {String} returns the display name of the User
  * logged in.
  */
  displayName: (state, getters) => {
    let mUser = getters.userName;

    if (mUser !== null && mUser !== state.user.masterUser) {
      mUser = `${mUser} @ ${state.user.masterUser}`.toLowerCase();
    }

    return mUser;
  },

  /**
   * Getter to indicate whether use is logged in or not.
   *
   * @param {Object} state Account module state
   * @returns {Boolean} returns status of logged in or not
   */
  isUserLoggedIn: (state) => state.user !== null,

  /**
   * Getter to retrieve profile information
   * Modified to include title and to pop:
   * statusCode, type and apps
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's profile
   */
  getProfile: (state) => {
    const profile = {
      name: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
    };

    if (state.profile) {
      Object.keys(profile).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(state.profile, key)) {
          profile[key] = state.profile[key];
        }
      });
    }

    return { ...profile };
  },

  /**
   * Getter to retrieve profile information as object
   * Modified to include title and to pop:
   * statusCode, type and apps
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's profile
   */
  getProfileAsObject: (state) => {
    const profileKeys = [
      'name',
      'email',
      'phone',
      'address1',
      'address2',
    ];

    if (state.profile) {
      const profileAsObject = {};

      profileKeys.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(state.profile, key)) {
          profileAsObject[key] = state.profile[key];
        }
      });

      return profileAsObject;
    }

    return null;
  },

  /**
   * Getter to retrieve plan information
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's plans
   */
  getPlans: (state) => {
    if (state.plans) {
      return state.plans;
    }

    return null;
  },

  /**
   * Getter to retrieve plan information
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's plans
   */
  getUserPlanSummary: (state) => {
    if (state.userPlanSummary) {
      return state.userPlanSummary;
    }
    return null;
  },

  /**
   * Getter to retrieve active listings from userPlanSummary
   *
   * @param {Object} state Account module state
   * @param {Object} getters Account module getters
   * @returns {Object} returns active listings in services
   */
  getTotalActiveListings: (state, getters) => {
    if (getters.getUserPlanSummary) {
      return getters.getUserPlanSummary.totalActiveListings;
    }

    return null;
  },

  /**
   * Getter to retrieve user listing counts from userPlanSummary
   *
   * @param {Object} state Account module state
   * @param {Object} getters Account module getters
   * @returns {Object} returns user listings counts
   */
  getUserListingCounts: (state, getters) => {
    if (getters.getUserPlanSummary) {
      return getters.getUserPlanSummary.userListingCounts;
    }

    return null;
  },

  /**
   * Getter to retrieve userPlan from userPlanSummary
   *
   * @param {Object} state Account module state
   * @param {Object} getters Account module getters
   * @returns {Object} returns user's plan
   */
  getUserPlan: (state, getters) => {
    if (getters.getUserPlanSummary) {
      return getters.getUserPlanSummary.userPlan;
    }

    return null;
  },

  /**
   * Getter to retrieve Ad Accounts information
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's accounts
   */
  getAdAccounts: (state) => {
    if (state.adAccounts) {
      return state.adAccounts;
    }

    return null;
  },

  /**
   * Getter to parse active services
   *
   * @param {Object} state Account module state
   * @returns {Array} returns array of active services
   */
  getActiveServices: () => {
    /**
     * @todo Probably shouldn't default reports and adstation to true
     * We'll want to get a better user JSON that explicitly shows
     * what services they have access to
     */
    const services = {
      reviewbox: true,
      pricebox: true,
      searchbox: true,
      searchterms: true,
      copybox: true,
      reports: true,
      adstation: true,
      vendorcentral: true,
    };

    return services;
  },

  /**
   * Getter to retrieve Ad Accounts information
   *
   * @param {Object} state Account module state
   * @returns {Object} returns user's accounts
   */
  getServiceSourceList: (state) => state.serviceSourceList,
  /**
   * Getter to retrieve response templates
   *
   * @param {Object} state Account module state
   * @returns {Array} returns array of templates
   */
  getResponseTemplates: (state) => state.responseTemplates,
  /**
   * Getter to retrieve response templates by type
   *
   * @param {Object} state Account module state
   * @returns {Array} returns array of templates
   */
  getResponseTemplatesByType: (state) => (templateType) => (
    state.responseTemplates.filter((template) => template.type === templateType)
  ),
  /**
   * Get the profile filter.
   */
  getProfileFilter: (state) => state.profileFilters,
  /**
   * Getter to indicate if a user has maxed out report limit for plan.
   * If so, they shouldn't be able to create another report.
   */
  isReportsMaxed: (state, getters) => {
    const plan = getters.getUserPlanSummary;

    if (plan && plan.reports) {
      const { limit, active } = plan.reports;

      return active >= limit;
    }

    return false;
  },
};
