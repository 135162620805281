import LoadingOverlayComponent from './LoadingOverlayComponent';
import NoRowsOverlayComponent from './NoRowsOverlayComponent';
import CellRenderer from './CellRenderer';
import DateCellRenderer from './DateCellRenderer';
import ReviewReplyCellRenderer from './ReviewReplyCellRenderer';
import ReviewLinkCellRenderer from './ReviewLinkCellRenderer';
import QuestionReplyCellRenderer from './QuestionReplyCellRenderer';
import QuestionLinkCellRenderer from './QuestionLinkCellRenderer';
import BuyBoxCellRenderer from './BuyBoxCellRenderer';
import ProductInfoCellRenderer from './ProductInfoCellRenderer';
import ListingLinkCellRenderer from './ListingLinkCellRenderer';
import ListingImageCellRenderer from './ListingImageCellRenderer';
import SellerRatingCellRenderer from './SellerRatingCellRenderer';
import SellerLinkCellRenderer from './SellerLinkCellRenderer';
import SellerNameCellRenderer from './SellerNameCellRenderer';
import HeaderComponent from './HeaderComponent';
import SearchboxRankingCellRenderer from './SearchboxRankingCellRenderer';
import ContentComparisonCellRenderer from './ContentComparisonCellRenderer';
import ReviewboxProductNameCellRenderer from './ReviewboxProductNameCellRenderer';
import ReviewboxGroupedReviewsCellRenderer from './ReviewboxGroupedReviewsCellRenderer';
import ReviewboxGroupedQuestionsCellRenderer from './ReviewboxGroupedQuestionsCellRenderer';
import PriceCellRenderer from './PriceCellRenderer';
import AgRichSelectCellRenderer from './AgRichSelectCellRenderer';
import MapViolationInput from './MapViolationInput';
import ViewCellDataRenderer from './ViewCellDataRenderer';
import SelectTypeAheadCellEditor from './SelectTypeAheadCellEditor';
import SearchTermDialogCellRenderer from './SearchTermDialogCellRenderer';
import RedirectLinkCellRenderer from './RedirectLinkCellRenderer';
import SimpleTextInput from './SimpleTextInput';

export {
  LoadingOverlayComponent,
  NoRowsOverlayComponent,
  CellRenderer,
  DateCellRenderer,
  ReviewReplyCellRenderer,
  ReviewLinkCellRenderer,
  QuestionReplyCellRenderer,
  QuestionLinkCellRenderer,
  BuyBoxCellRenderer,
  ProductInfoCellRenderer,
  ListingLinkCellRenderer,
  RedirectLinkCellRenderer,
  ListingImageCellRenderer,
  SellerRatingCellRenderer,
  SellerLinkCellRenderer,
  SellerNameCellRenderer,
  HeaderComponent,
  SearchboxRankingCellRenderer,
  ContentComparisonCellRenderer,
  ReviewboxProductNameCellRenderer,
  ReviewboxGroupedReviewsCellRenderer,
  ReviewboxGroupedQuestionsCellRenderer,
  PriceCellRenderer,
  AgRichSelectCellRenderer,
  MapViolationInput,
  ViewCellDataRenderer,
  SelectTypeAheadCellEditor,
  SearchTermDialogCellRenderer,
  SimpleTextInput
};

/**
 * Object of ag-grid framework components used for cell rendering.
 *
 * @see {@link https://www.ag-grid.com/javascript-grid-cell-rendering-components/#vuejs-cell-rendering|Vue Ag-Grid Cell Rendering}
 */
export const GRID_FRAMEWORK_COMPONENTS = {
  loadingOverlayComponent: LoadingOverlayComponent,
  noRowsOverlayComponent: NoRowsOverlayComponent,
  agColumnHeader: HeaderComponent,
  dateCellRenderer: DateCellRenderer,
  reviewReplyCellRenderer: ReviewReplyCellRenderer,
  reviewLinkCellRenderer: ReviewLinkCellRenderer,
  questionReplyCellRenderer: QuestionReplyCellRenderer,
  questionLinkCellRenderer: QuestionLinkCellRenderer,
  buyBoxCellRenderer: BuyBoxCellRenderer,
  productInfoCellRenderer: ProductInfoCellRenderer,
  listingLinkCellRenderer: ListingLinkCellRenderer,
  redirectLinkCellRenderer: RedirectLinkCellRenderer,
  listingImageCellRenderer: ListingImageCellRenderer,
  sellerRatingCellRenderer: SellerRatingCellRenderer,
  sellerLinkCellRenderer: SellerLinkCellRenderer,
  sellerNameCellRenderer: SellerNameCellRenderer,
  searchboxRankingCellRenderer: SearchboxRankingCellRenderer,
  searchboxSearchTermDialogCellRenderer: SearchTermDialogCellRenderer,
  contentComparisonCellRenderer: ContentComparisonCellRenderer,
  reviewboxProductNameCellRenderer: ReviewboxProductNameCellRenderer,
  reviewboxGroupedReviewsCellRenderer: ReviewboxGroupedReviewsCellRenderer,
  reviewboxGroupedQuestionsCellRenderer: ReviewboxGroupedQuestionsCellRenderer,
  priceCellRenderer: PriceCellRenderer,
  agRichSelectCellRenderer: AgRichSelectCellRenderer,
  mapViolationInput: MapViolationInput,
  viewCellDataRenderer: ViewCellDataRenderer,
  selectTypeAheadCellEditor: SelectTypeAheadCellEditor,
  simpleTextInput: SimpleTextInput,
};

/**
 * Export a constant key map for referencing the grid components above.
 * This will be used in the grid store state. See /store/modules/grids/state.js
 */
const gridFrameworkComponentKeys = {};

Object.keys(GRID_FRAMEWORK_COMPONENTS).forEach((key) => {
  gridFrameworkComponentKeys[key] = key;
});

export const GRID_FRAMEWORK_COMPONENTS_KEYS = gridFrameworkComponentKeys;
