/**
 * Function to revive date values that have been JSON.stringified.
 * This is mostly used for the gridStates being stored with date values.
 *
 * Code is courtesy of https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates
 *
 * @param {String} value Value being parsed through JSON.parse
 */

export default (key, value) => {
  // eslint-disable-next-line
  const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
  // eslint-disable-next-line
  const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

  if (typeof value === 'string') {
    return new Date(value);
  }

  return value;
};
