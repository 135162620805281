var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "text-info",
      staticStyle: { cursor: "pointer" },
      on: { click: _vm.onProductIdClick },
    },
    [_vm._v(" " + _vm._s(_vm.params.value) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }