/**
 * Universal way to reference icons, labels, colors, etc. for services or common items in the app
 */
export default {
  adstation: {
    label: 'Ad Station',
    icon: 'tag-icon',
    color: 'success',
  },
  reviewbox: {
    label: 'Reviewbox',
    icon: 'comment-text-outline-icon',
    color: 'success',
  },
  pricebox: {
    label: 'Pricebox',
    icon: 'dollar-icon',
    color: 'success',
  },
  copybox: {
    label: 'Copybox',
    icon: 'pencil-icon',
    color: 'success',
  },
  searchbox: {
    label: 'Searchbox',
    icon: 'search-icon',
    color: 'success',
  },
  vendorcentral: {
    label: 'Vendor Central',
    icon: 'store-icon',
    color: 'success',
  },
  dashboard: {
    label: 'Dashboard',
    icon: 'dashboard-icon',
    color: 'info',
  },
  reports: {
    label: 'Reports',
    icon: 'format-list-bulleted-icon',
    color: 'info',
  },
  catalog: {
    label: 'Catalog',
    icon: 'archive-icon',
    color: 'info',
  },
  downloads: {
    label: 'Downloads',
    icon: 'cloud-download-icon',
    color: 'info',
  },
  account: {
    label: 'Account',
    icon: 'account-circle-icon',
    color: 'info',
  },
  reviews: {
    label: 'Reviews',
    icon: 'star-circle-icon',
    color: 'info',
  },
  questions: {
    label: 'Questions',
    icon: 'help-circle-icon',
    color: 'info',
  },
  products: {
    label: 'Products',
    icon: 'briefcase-icon',
    color: 'info',
  },
  sellers: {
    label: 'Sellers',
    icon: 'store-icon',
    color: 'info',
  },
  contentLatest: {
    label: 'Content Latest',
    icon: 'briefcase-icon',
    color: 'info',
  },
  contentReference: {
    label: 'Content Reference',
    icon: 'text-box-check-outline-icon',
    color: 'info',
  },
  terms: {
    label: 'Terms',
    icon: 'format-text-icon',
    color: 'info',
  },
  profile: {
    label: 'Profile',
    icon: 'account-circle-icon',
    color: 'info',
  },
  plan: {
    label: 'Plan Summary',
    icon: 'file-document-outline-icon',
    color: 'info',
  },
  notifications: {
    label: 'Notifications',
    icon: 'email-icon',
    color: 'info',
  },
  adAccounts: {
    label: 'Ad Accounts',
    icon: 'tag-icon',
    color: 'info',
  },
  exportSettings: {
    label: 'Export Settings',
    icon: 'table-cog-icon',
    color: 'info',
  },
  storageSettings: {
    label: 'Storage Settings',
    icon: 'cloud-circle-icon',
    color: 'info',
  },
  responseTemplates: {
    label: 'Response Templates',
    icon: 'forum-outline-icon',
    color: 'info',
  },
  reasonCodes: {
    label: 'Reason Codes',
    icon: 'code-json-icon',
    color: 'info',
  },
  export: {
    label: 'Export',
    icon: 'file-icon',
    color: 'warning',
  },
  report: {
    label: 'Report',
    icon: 'bar-chart-icon',
    color: 'info',
  },
  recurring: {
    label: 'Recurring Email',
    icon: 'email-icon',
    color: 'secondary',
  },
};
