var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-check", class: _vm.checkboxClass }, [
    _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: _vm.cbId } },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.model,
              expression: "model",
            },
          ],
          staticClass: "form-check-input",
          attrs: { id: _vm.cbId, type: "checkbox", disabled: _vm.disabled },
          domProps: {
            checked: Array.isArray(_vm.model)
              ? _vm._i(_vm.model, null) > -1
              : _vm.model,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.model,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.model = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.model = $$c
              }
            },
          },
        }),
        _c("span", { staticClass: "form-check-sign" }),
        _vm._t("default", function () {
          return [_vm.inline ? _c("span", [_vm._v(" ")]) : _vm._e()]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }