var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-tooltip",
    { attrs: { "open-delay": 500, placement: "top-start" } },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _vm._v(" Press "),
        _c("code", [_vm._v("cmd + click (Mac)")]),
        _vm._v(" or "),
        _c("code", [_vm._v("ctrl + click (Windows)")]),
        _vm._v(" to open in a new tab "),
      ]),
      _c(
        "el-button",
        {
          staticClass: "text-info p-0 font-weight-normal",
          staticStyle: { "font-size": "12px" },
          attrs: { type: "text" },
          on: { click: _vm.onShowComparison },
        },
        [_vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }