import Vue from 'vue';

/**
 * EventBus allows you to do cross communication (events) among components.
 *
 * Resources on the concept:
 * 1) https://vuejs.org/v2/guide/migration.html#dispatch-and-broadcast-replaced
 * 2) https://alligator.io/vuejs/global-event-bus/
 */
export default new Vue();
