export default {
  /**
   * Mutation to initialize the state.reports list.
   *
   * @param {Object} state Reports's module state variables
   * @param {Array} reports Array of active reports/exports.
   */
  setReports(state, reports) {
    state.reports = reports;
    sessionStorage.setItem('reports', JSON.stringify(reports));
  },
  /**
   * Mutation to initialize the state.reports list.
   *
   * @param {Object} state Reports's module state variables
   * @param {Array} reports Array of active reports/exports.
   */
  setSelectedReport(state, payload) {
    state.selectedReport = payload;
    sessionStorage.setItem('reports/selectedReport', JSON.stringify(payload));
  },
  /**
   * Mutation to remove a single report for the state.reports Array.
   *
   * @param {Object} state Reports's module state variables
   * @param {String} reportId Unique ID of report/export
   */
  removeReportById(state, reportId) {
    state.reports = state.reports.filter((report) => report.id !== reportId);
    sessionStorage.setItem('reports', JSON.stringify(state.reports));
  },
  /**
   * Mutation to remove the active array of reports.
   * Essentially resetting the report state.
   *
   * @param {Object} state Reports's module state variables
   */
  removeReports(state) {
    state.reports = null;
    sessionStorage.removeItem('reports');
  },
  /**
   * Sets loading status for reports page.
   *
   * @param {Object} state Reports's module state variables
   * @param {Boolean} status Flag for loading status of reports
   */
  setIsLoading(state, status) {
    state.isLoading = status;
  },
  /**
   * Sets the total report units total a user currently has available.
   *
   * @param {Object} state Reports's module state variables
   * @param {Number} total total allowable reports
   */
  setReportUnitsTotal(state, total) {
    state.reportUnitsTotal = total;
  },
  /**
   * Sets the total report count total a user actively has.
   *
   * @param {Object} state Reports's module state variables
   * @param {Number} count count allowable reports
   */
  setReportUnitsCount(state, count) {
    state.reportUnitsCount = count;
  },
  /**
   * Sets the report form options object. This contains metadata and form
   * data for reconstruct a form base on a report/export.
   *
   * @param {Object} state Reports's module state variables
   * @param {Object} options Object of report form options available to user
   */
  setReportFormOptions(state, options) {
    state.reportFormOptions = options;
  },
  /**
   * Sets the filters used for selecting reports or exports.
   *
   * @param {Object} state Reports's module state variables
   * @param {String} value Value of filters "reports" or "exports"
   */
  setReportSelectFilters(state, value) {
    state.reportSelectFilters = value;
  },
};
