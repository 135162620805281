import format from 'date-fns/format';

// Import Components
import { GridDownloadModal } from '@/components';

import { ReportsHttp } from '@/services/api';

/**
 * GridExportDownload Mixin for handling downloading an export from the grid.
 * This is used in conjuction with the GridDownloadModal in the components directory.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 *
 * @todo This needs to be refactored so it is more re-usable.
 * Or ideally, merge downloading exports from grid with reports builder,
 * so we don't make it redundant when downloading.
 */
export default {
  components: { GridDownloadModal },
  data() {
    return {
      gridDownloadModal: {
        show: false,
        export: {
          type: null,
          params: null,
          form: [],
          formError: null,
        },
      },
    };
  },
  methods: {
    /**
     * Method to return gridDownloadModal to a default state.
     * This will toggle off the mdoal from being displayed.
     */
    onResetGridDownloadModal() {
      this.gridDownloadModal = {
        show: false,
        export: {
          type: null,
          params: null,
          form: [],
          formError: null,
          isLoading: false,
        },
      };
    },
    /**
     * Intermediary method to call downloadExport.
     * This is used as a callback for gridDownloadModal when it emits
     * onSubmitDownload.
     *
     * @param {Object} params HTTP POST params for downloading an export
     */
    onSubmitGridDownloadModal(params) {
      this.downloadExport(params);
    },
    /**
     * Method to handle downloading an export/report.
     * This will display a modal to fill out download options.
     *
     * @param {Object} payload An object specifying which download type and rows selected
     * Each payload is structured as {label, value, form, rows}
     * Label is the display name of download
     * Value is type of export
     * Form is the inputs necessary to complete export download
     * Row is visible row data from ag-grid
     *
     * See the '/store/modules/grids/stat'e for the download options of each service
     */
    onSelectDownload(payload) {
      const {
        value,
        rows,
        form
      } = payload;

      try {
        // Check that row data is selected
        // If no data, display a warning modal
        if (rows.length < 1) {
          this.$notify({
            type: 'warning',
            title: 'No Data',
            message: 'There is no data to download.',
            position: 'bottom-right',
          });
          return;
        }

        // Handle downloading ag-grid data, this is just what is viewable on the grid
        if (value === 'download-grid-data') {
          const date = format(new Date(), 'MM_dd_yyyy');
          const fileName = `${this.service}_grid_data_${date}`;

          this.$refs[this.gridRef].gridApi.exportDataAsCsv({ fileName });
          // Handle non-ag-grid downloads, exports with additional data added to the grid
        } else {
          // Extract date range from grid, if it exists
          // Some export downloads need date range,
          // hence grabbing it from grid state
          const gridState = this.gridState(this.service);
          const params = { gridData: rows };

          if (Object.prototype.hasOwnProperty.call(gridState, 'gridDateRange')) {
            params.exportDateRange = this.formatCustomDateRange(
              gridState.gridDateRange.startDate,
              gridState.gridDateRange.endDate,
            );
          }

          const exportParams = this.createExportParams(value, params);

          // Handle downloading an export without additional form data
          if (form === null) {
            this.downloadExport(exportParams);
            // Handle download an export with additional form data
            // This will trigger a form modal for extra user input
          } else {
            this.gridDownloadModal = {
              show: true,
              export: {
                type: value,
                params: exportParams,
                form,
                formError: null,
                isLoading: false,
              },
            };
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    /**
     * Method to trigger an export download.
     *
     * @param {Object} exportParams HTTP POST params for downloading an export
     */
    downloadExport(exportParams) {
      this.gridDownloadModal.isLoading = true;

      ReportsHttp.downloadReport({
        reports: JSON.stringify([exportParams]),
        isReportBuilderDownload: 'no',
      })
        .then(() => {
          // Open downloads drawer
          this.$store.commit('setShowDownloads', true);

          // Close the downloads dialog
          this.onResetGridDownloadModal();
        })
        .catch((error) => {
          this.handleError(error);
          this.gridDownloadModal.export.formError = error.message;
        })
        .finally(() => {
          this.gridDownloadModal.isLoading = false;
        });
    },
    /**
     * Method to package export params download by the type of
     * export download.
     *
     * @param {String} exportType Type of export
     * @param {Object} options Object of export options for download
     * Will include the row data from ag-grid, date, and then any
     * additional parameters needed to create an export.
     *
     * @todo This needs to be refactored. Not really that re-usable and manually
     * has to adapt for each export type and their options.
     */
    createExportParams(exportType, options) {
      const params = {
        type: exportType,
        options: {},
        is_recurring: false,
        is_advanced_report: false,
      };

      // Add date range to param options if existing
      if (Object.prototype.hasOwnProperty.call(options, 'exportDateRange')) {
        params.options.exportDateRange = options.exportDateRange;
      }

      switch (exportType) {
        case 'ProductReviewsSummaryExport': {
          Object.assign(params.options, {
            exportProducts: options.gridData.map(
              (row) => ({ listing: row.listing, source: row.source }),
            ),
            exportFileFormat: 'csv',
            exportEscapeChar: 'quote',
            exportCsvOptions: null,
            exportFileType: 'csv',
          });

          break;
        }
        case 'BuyboxExport':
        case 'SalesRankExport':
        case 'SellerPricesExport': {
          const sellerPricesParams = {
            exportFileFormat: 'csv',
            exportFileType: 'csv',
            exportFilters: {},
            exportBFilters: {},
          };

          /**
           * Do not add products if the service is sellers page,
           * else add exportProducts. This is typically used for pricebox.
           *
           * @todo Can we refactor this? Seems brittle to hard-code for this case
           */
          if (this.service !== 'sellers') {
            sellerPricesParams.exportProducts = options.gridData.map(
              (row) => ({ listing: row.listing, source: row.source }),
            );
          }

          Object.assign(params.options, sellerPricesParams);

          break;
        }
        case 'SearchRankExport': {
          const searchRankOptions = {
            exportBFilters: {},
            listings: [],
            exportFileType: 'csv',
          };

          // Handle export for searchbox products
          if (this.service === 'searchbox') {
            searchRankOptions.exportService = 'catalog';
            searchRankOptions.exportProducts = options.gridData.map(
              (row) => ({ listing: row.listing, source: row.source }),
            );
            // Handle export for searchbox terms
          } else {
            searchRankOptions.exportService = 'searchterms';
            searchRankOptions.exportSearchTerms = options.gridData.map(
              (row) => ([row.term, row.source]),
            );
          }

          Object.assign(params.options, searchRankOptions);

          break;
        }
        case 'BulkReviewsExport': {
          Object.assign(params.options, {
            exportProducts: options.gridData.map(
              (row) => (
                {
                  listing: row.listing,
                  source: row.source,
                  product_id: row.retailer_id,
                  product_retailer: row.retailer_source,
                }
              ),
            ),
            exportLastId: true,
            exportFileFormat: 'csv',
            exportEscapeChar: 'quote',
          });

          break;
        }
        case 'BulkQuestionsExport': {
          Object.assign(params.options, {
            exportProducts: options.gridData.map(
              (row) => (
                {
                  listing: row.listing,
                  source: row.source,
                  product_id: row.retailer_id,
                  product_retailer: row.retailer_source,
                }
              ),
            ),
            exportLastId: true,
            exportFileFormat: 'csv',
            exportEscapeChar: 'quote',
          });

          break;
        }
        case 'SellerSummaryExport': {
          Object.assign(params.options, {
            exportIncludedSellers: options.gridData.map(
              (row) => (row.seller),
            ),
            exportFileType: 'csv',
          });

          break;
        }
        case 'CopyboxContentExport': {
          Object.assign(params.options, {
            exportProducts: options.gridData.map(
              (row) => ({ listing: row.listing, source: row.source }),
            ),
            exportFileType: 'csv',
          });

          break;
        }
        default: {
          break;
        }
      }

      return params;
    },
  },
};
