/**
 * Hide these messages from the user view.
 */
export const NO_RESPONSE_DICTIONARY = {
  ECONNREFUSED: true,
  'System error occurred': true,
  'Unable to update response tracking. Please try again.': true
};

/**
 * This is an example. We can fill this up with better as we go.
 */
export const RESPONSE_DICTIONARY = {
  ECONNREFUSED: 'Could not connect. Please try again.',
  'Bad request parameters': 'Could not handle request. Please try again.'
};
