export default {
  isLoading: false,
  reports: [],
  reportUnitsCount: 0,
  reportUnitsTotal: 0,
  reportSelectFilters: 'reports',
  reportFormOptions: null,
  filteredReportFormOptions: null,
  selectedReport: JSON.parse(sessionStorage.getItem('reports/selectedReport')) || null,
};
