export default {
  /**
   * Getter to return the active list of reports.
   *
   * @param {Object} state Reports's module state variables
   */
  getReports(state) {
    return state.reports;
  },
  /**
   * Getter to return a single report by its ID
   * @param {Object} state Reports's module state variables
   * @param {String} id Unique id for a report/export
   */
  getReportById: (state) => (id) => state.reports.find((report) => report.id === id),
  /**
   * Getter to return the loading status of reports.
   *
   * @param {Object} state Reports's module state variables
   */
  getIsLoading(state) {
    return state.isLoading;
  },
  /**
   * Getter to return reportSelectFilters
   *
   * @param {Object} state Reports's module state variables
   *
   * @return {Object} Object of select filters for report options
   */
  getReportSelectFilters(state) {
    return state.reportSelectFilters;
  },
  /**
   * Getter to filter report options based on reportSelectFilters
   *
   * @param {Object} state Reports's module state variables
   *
   * @return {Array} Array of filtered report form options by reports or exports
   */
  getFilteredReportFormOptions(state) {
    if (state.reportFormOptions === null) {
      return [];
    }

    return state.reportFormOptions[state.reportSelectFilters];
  },
  /**
   * Getter to return a status if a user has exceeded the
   * allowable amount of report units.
   *
   * @param {Object} state Reports's module state variables
   *
   * @return {Boolean} Status if report units is maxed out
   */
  getIsReportCountExceeded(state) {
    return state.reportUnitsCount >= state.reportUnitsTotal;
  },
  /**
   * Getter to return an object of report count and total allowed.
   *
   * @param {Object} state Reports's module state variables
   */
  getReportCount(state) {
    return {
      count: state.reportUnitsCount,
      total: state.reportUnitsTotal,
    };
  },
  selectedReport: (state) => state.selectedReport
};
