var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-tooltip",
    {
      attrs: {
        disabled: !_vm.params.tooltip,
        "open-delay": 500,
        placement: "right",
      },
    },
    [
      _c(
        "p",
        {
          staticClass: "mb-0 text-white",
          staticStyle: { "max-width": "200px" },
          attrs: { slot: "content" },
          slot: "content",
        },
        [_vm._v(" " + _vm._s(_vm.params.tooltip) + " ")]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-link small p-0 text-decoration-none",
          staticStyle: { "font-size": "12px" },
          on: { click: _vm.onViewDataClick },
        },
        [_vm._v(" " + _vm._s(_vm.params.label) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }