var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    { staticClass: "rb-map-input el-input el-input--mini rounded-0" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.value,
            expression: "value",
            modifiers: { number: true },
          },
        ],
        ref: "input",
        staticClass: "el-input__inner rounded-0",
        attrs: { type: "number", step: "0.01" },
        domProps: { value: _vm.value },
        on: {
          blur: [
            _vm.onBlur,
            function ($event) {
              return _vm.$forceUpdate()
            },
          ],
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = _vm._n($event.target.value)
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }