import debounce from 'lodash/debounce';

/**
 * Mixin to adjust modal width on laptop screens and below.
 */
export default {
  data() {
    return {
      width: '60%',
    };
  },
  mounted() {
    window.addEventListener('resize', this.windowSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.windowSize);
  },
  methods: {
    windowSize: debounce(function () {
      const width = window.innerWidth;

      if (width <= 1024) {
        this.width = '85%';
      } else {
        this.width = '60%';
      }
    }, 500),
  },
};
