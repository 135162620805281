import BaseHttp from './BaseHttp';

class DashboardHttp extends BaseHttp {
  /**
   * Fetch OAuth log in iframe
   */
  async getDashboard(params) {
    return this.get(`${this.PrependRoutes.reviewbox}/dashboard`, params)
      .then((response) => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'dashboard')) {
          return JSON.parse(response.data.dashboard);
        }

        throw Error('No Dashboard Data');
      });
  }
}

export default new DashboardHttp();
