var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "d-flex flex-column justify-content-center" },
      [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column align-items-center justify-content-center",
          },
          [
            _c("h4", { staticClass: "text-muted" }, [
              _vm._v(" No Data to Display "),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }