var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "empty-state-container" },
    [
      _vm.isLoading
        ? _c("div", { staticClass: "card" }, [_vm._m(0)])
        : [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "row no-gutters" }, [
                _vm.image && _vm.image.src
                  ? _c("div", { staticClass: "col-12 col-lg-5 img-wrapper" }, [
                      _c("div", { staticClass: "img-container card-body" }, [
                        _c("img", {
                          staticClass: "img-empty-state img-fluid",
                          attrs: {
                            src: _vm.image.src,
                            alt: _vm.image.alt,
                            title: _vm.image.title,
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "col-12 d-flex align-items-center",
                    class: { "col-lg-6 mx-auto": _vm.image && _vm.image.src },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _vm._t("card-titles", function () {
                          return [
                            _c("div", { staticClass: "card-titles mb-3" }, [
                              _vm.title
                                ? _c("h5", { staticClass: "card-title mb-2" }, [
                                    _vm._v(" " + _vm._s(_vm.title) + " "),
                                  ])
                                : _vm._e(),
                              _vm.subtitle
                                ? _c(
                                    "h6",
                                    { staticClass: "card-subtitle text-muted" },
                                    [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        }),
                        _vm._t("default", function () {
                          return [
                            _c("p", { staticClass: "card-text" }, [
                              _vm._v(" " + _vm._s(_vm.description) + " "),
                            ]),
                          ]
                        }),
                        _vm._t("card-actions"),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
            ]),
            _vm.helperText || _vm.helperButtonText || _vm.$slots["helper-text"]
              ? _vm._t("helper-text", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center mt-2",
                      },
                      [
                        _vm.helperText
                          ? _c("span", { staticClass: "text-muted mr-1" }, [
                              _vm._v(" " + _vm._s(_vm.helperText) + " "),
                            ])
                          : _vm._e(),
                        _vm.helperButtonText
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-link px-0",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("openModal")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.helperButtonText) + " ")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row no-gutters" }, [
      _c("div", {
        staticClass: "col-12 col-lg-5 skeleton skeleton-img img-empty-state",
      }),
      _c(
        "div",
        { staticClass: "col-12 col-lg-6 mx-auto d-flex align-items-center" },
        [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("div", { staticClass: "skeleton skeleton-title w-75 mb-1" }),
              _c("div", { staticClass: "skeleton skeleton-title" }),
            ]),
            _c("div", { staticClass: "mb-4" }, [
              _c("div", { staticClass: "skeleton skeleton-text w-50 mb-1" }),
              _c("div", { staticClass: "skeleton skeleton-text w-75 mb-1" }),
              _c("div", { staticClass: "skeleton skeleton-text w-25" }),
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("div", { staticClass: "skeleton skeleton-button mr-2" }),
              _c("div", { staticClass: "skeleton skeleton-button" }),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }