import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import sub from 'date-fns/sub';

// Week starts on Monday, this is to match Backend's arrow package that starts on Monday
const weekStartsOn = { weekStartsOn: 1 };

/**
 * Shared default custom date ranges that are commonly used throughout the app.
 */
export default {
  getDateRange(key) {
    switch (key) {
      case 'all':
        /**
         * @todo This is an arbitrary 'all' date range that reaches back to
         * start of 1990. We don't have data that extends beyond that, but
         * there is probably a better way to give an "all" date range
         */
        return {
          startDate: endOfYear(new Date('1989-1-1')),
          endDate: endOfDay(new Date()),
        };
      case 'thisWeek':
        return {
          startDate: startOfWeek(new Date(), weekStartsOn),
          endDate: endOfDay(new Date()),
        };
      case 'lastWeek':
        return {
          startDate: startOfWeek(sub(new Date(), { weeks: 1 }), weekStartsOn),
          endDate: endOfWeek(sub(new Date(), { weeks: 1 }), weekStartsOn),
        };
      case 'thisMonth':
        return {
          startDate: startOfMonth(new Date()),
          endDate: endOfMonth(new Date()),
        };
      case 'lastMonth':
        return {
          startDate: startOfMonth(sub(new Date(), { months: 1 })),
          endDate: endOfMonth(sub(new Date(), { months: 1 })),
        };
      case 'last7Days':
        return {
          startDate: startOfDay(sub(new Date(), { days: 6 })),
          endDate: endOfDay(new Date()),
        };
      case 'last30Days':
        return {
          startDate: startOfDay(sub(new Date(), { days: 29 })),
          endDate: endOfDay(new Date()),
        };
      case 'last60Days':
        return {
          startDate: startOfDay(sub(new Date(), { days: 59 })),
          endDate: endOfDay(new Date()),
        };
      case 'last90Days':
        return {
          startDate: startOfDay(sub(new Date(), { days: 89 })),
          endDate: endOfDay(new Date()),
        };
      case 'thisYear':
        return {
          startDate: startOfYear(sub(new Date(), { months: 1 })),
          endDate: endOfDay(new Date()),
        };
      case 'lastYear':
        return {
          startDate: startOfYear(sub(new Date(), { years: 1 })),
          endDate: endOfYear(sub(new Date(), { years: 1 })),
        };
      case 'yesterday':
        return {
          startDate: startOfYesterday(),
          endDate: endOfYesterday(),
        };
      case 'today':
      default:
        return {
          startDate: startOfToday(),
          endDate: endOfToday(),
        };
    }
  },
  options: {
    today: [
      startOfToday(),
      endOfToday(),
    ],
    yesterday: [
      startOfYesterday(),
      endOfYesterday(),
    ],
    thisWeek: [
      startOfWeek(new Date(), weekStartsOn),
      endOfDay(new Date()),
    ],
    lastWeek: [
      startOfWeek(sub(new Date(), { weeks: 1 }), weekStartsOn),
      endOfWeek(sub(new Date(), { weeks: 1 }), weekStartsOn),
    ],
    thisMonth: [
      startOfMonth(new Date()),
      endOfMonth(new Date()),
    ],
    lastMonth: [
      startOfMonth(sub(new Date(), { months: 1 })),
      endOfMonth(sub(new Date(), { months: 1 })),
    ],
    last7Days: [
      startOfDay(sub(new Date(), { days: 6 })),
      endOfDay(new Date()),
    ],
    last30Days: [
      startOfDay(sub(new Date(), { days: 29 })),
      endOfDay(new Date()),
    ],
    last60Days: [
      startOfDay(sub(new Date(), { days: 59 })),
      endOfDay(new Date()),
    ],
    last90Days: [
      startOfDay(sub(new Date(), { days: 89 })),
      endOfDay(new Date()),
    ],
    thisYear: [
      startOfYear(sub(new Date(), { months: 1 })),
      endOfDay(new Date()),
    ],
    lastYear: [
      startOfYear(sub(new Date(), { years: 1 })),
      endOfYear(sub(new Date(), { years: 1 })),
    ],
    /**
     * @todo This is an arbitrary 'all' date range that reaches back to
     * start of 1990. We don't have data that extends beyond that, but
     * there is probably a better way to give an "all" date range
     */
    all: [
      endOfYear(new Date('1989-1-1')),
      endOfDay(new Date()),
    ],
  },
  /**
   * Method to convert string date options to actual date ranges.
   * This is adopted from Angular.js as ['all', 'today',...]
   *
   * @param {String} value String value of date options
   * @returns {Object} Object of {startDate, endDate} ranges
   */
  convertStringDateToRange(value) {
    switch (value) {
      case 'today':
        return {
          startDate: this.options.today[0],
          endDate: this.options.today[1],
        };
      case 'yesterday':
        return {
          startDate: this.options.yesterday[0],
          endDate: this.options.yesterday[1],
        };
      // This Week
      case 'tweek':
        return {
          startDate: this.options.thisWeek[0],
          endDate: this.options.thisWeek[1],
        };
      // Last Week
      case 'week':
        return {
          startDate: this.options.lastWeek[0],
          endDate: this.options.lastWeek[1],
        };
      // This Month
      case 'tmonth':
        return {
          startDate: this.options.thisMonth[0],
          endDate: this.options.thisMonth[1],
        };
      // Last Month
      case 'month':
        return {
          startDate: this.options.lastMonth[0],
          endDate: this.options.lastMonth[1],
        };
      // This Year
      case 'tyear':
        return {
          startDate: this.options.thisYear[0],
          endDate: this.options.thisYear[1],
        };
      // Last Year
      case 'year':
        return {
          startDate: this.options.lastYear[0],
          endDate: this.options.lastYear[1],
        };
      // Last 7 Days
      case 'l7':
        return {
          startDate: this.options.last7Days[0],
          endDate: this.options.last7Days[1],
        };
      // Last 30 Days
      case 'l30':
        return {
          startDate: this.options.last30Days[0],
          endDate: this.options.last30Days[1],
        };
      // Last 60 Days
      case 'l60':
        return {
          startDate: this.options.last60Days[0],
          endDate: this.options.last60Days[1],
        };
      // Last 90 Days
      case 'l90':
        return {
          startDate: this.options.last90Days[0],
          endDate: this.options.last90Days[1],
        };
      case 'All':
        return {
          startDate: this.options.all[0],
          endDate: this.options.all[1],
        };
      default:
        return {
          startDate: this.options.today[0],
          endDate: this.options.today[1],
        };
    }
  },
  /**
   * Converts timestamp integers to date objects.
   *
   * @param {Number} startDate timestamp integer
   * @param {Number} endDate timestamp integer
   */
  convertCustomDateToStandardFormat(startDate, endDate) {
    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    };
  },
};
